import { Button, Card, Col, Row } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../_metronic/helpers";
import AuthService from '../../common/services/AuthService';

function Login() {
    try{
        // const select = new URL(window.location.href).searchParams.get('select');
        AuthService.signIn(true);   //바로 로그인 처리
    }catch(e){ console.log(e); }

    return (<div className="main d-flex w-100 justify-content-center" >
        <div className="d-flex flex-column container">
            <Row className="h-100">
                <Col className="mx-auto d-table h-100">
                    <div className="d-table-cell align-middle">

                        <div className="text-center mt-4">
                            <img src={toAbsoluteUrl('/images/adly_logo.png')} alt='adplo_logo' width='80px' />
                            <h2>Welcome to, {process.env.REACT_APP_CLIENT_NAME}</h2>
                            <p className="lead">Sign in to your account to continue</p>
                        </div>

                        <Card>
                            <div className="card-body m-sm-4">
                                <div className="text-center mt-3">통합인증서버로 로그인을 진행합니다.</div>
                                <div className="text-center mt-3"><Button color="primary" size="lg" onClick={()=>{AuthService.signIn(true);}}>Sign in</Button></div>
                            </div>
                        </Card>

                    </div>
                </Col>
            </Row>
        </div>
    </div>);
}

export default Login;