import { SortKeys } from "./table/Table";
import { HeaderColumn } from "./table/TableType";

export class StatFormatter{
    /** 기본값 정수 */
    public static comma    (value?:number, row?:any, index?:number, digit?:number):string { return (value?.truncRound(digit || 0) || 0).addComma(); }
    /** 소수 두째자리 */
    public static round    (value?:number, row?:any, index?:number, digit?:number):string { return (value?.truncRound(digit || 2) || 0).addComma(); }
    /** 소수 두째자리까지 */
    public static commaPer (value?:number, row?:any, index?:number, digit?:number):string { return (value?.truncRound(digit || 0) || 0).addComma()+'%'; }

    /** 소수 두째자리까지 */
    public static roundPer (value?:number, row?:any, index?:number, digit?:number):string { return (value?.truncRound(digit || 2) || 0).addComma()+'%'; }

    /**
     * 인자값 나눗셈을 합니다. - undefined 처리
     * @param value 값
     * @param by 나누기
     * @param times 값에 곱 - 퍼센트 처리 시 100을 입력합니다.
     * @returns 
     */
    public static divide(value?:number, by?:number, times?:number):number{
        if(value===undefined || !by){ return 0; }
        if(times){ value *= times; }
        return value/by;
    }
    
    /** 부가세를 더하고 콤마를 추가합니다. */
    public static addVatComma(value?:number):string{
        return StatFormatter.addVat(value).addComma();
    }
    
    /** 부가세를 추가합니다. */
    public static addVat = (value?:number):number => {
        return  Math.round((value || 0) * 1.1);
    }
}

const headerStyle:React.CSSProperties = {};//{backgroundColor:'rgba( var(--bs-warning-rgb), 0.1)'};
const colStyle:React.CSSProperties ={minWidth:'100px', textAlign: 'right'};
export const statDefaultProps:HeaderColumn = {
    accessor:'',
    colGroup:'성과 Data',
    style:colStyle,
    headerStyle:headerStyle,
    useSort:false,
    formatter: StatFormatter.comma,
    defaultSortDirection:SortKeys.Desc, //기본 정렬 내림차순 
}