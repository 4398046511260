const FamilySite = {
    list:[
        {label:"TBase", href:"https://tbase.co.kr"},
        {label:"eBase", href:"https://cjmall.tbase.co.kr/"},
        {label:"Tools", href:"https://tools.dbase.co.kr/login/oauth.php"},
        {label:"KeyBase", href:"https://keybase.tbase.co.kr/"},
        {label:"TBoard", href:"https://dbasetboard.tbase.co.kr/adv/"},
        {label:"PrBase", href:"https://prbase.tbase.co.kr/"},
        {label:"DXE Tableau", href:"https://tableau.dbase.co.kr/"},
        {label:"DBN 경비 정산(임시)", href:"https://closing.tbase.co.kr/"},
    ]
};

export default FamilySite;