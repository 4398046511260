import HelperComponent from './HelperComponent';

/** NAVER NOSP 키워드 Helper */
export default class KeywordHelper extends HelperComponent{
    static path="/keyword"; //기본 Path
    static stat='/keywords/stat';   //실적데이터
    static performances='/explorer/keywords';   //실적데이터 - Explorer용
    static downloadPath='/api/naversa/keywords';
}

