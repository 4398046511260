import { CSSProperties } from 'react';
import Select, { CSSObjectWithLabel, ControlProps, GroupBase, MenuProps, StylesConfig } from 'react-select';
import { OptionType } from '../../../../modules/types/Types';

export interface ReactSelectProps<T=OptionType|OptionType[]>{
    value?:T;
    options:OptionType[];
    onChange?:(value?:T)=>void;

    className?:string;
    placeholder?:string;
    isSearchable?:boolean;
    isMulti?: boolean;
    disabled?:boolean;
    valueStyle?: CSSProperties;
    closeMenuOnSelect?: boolean;
}
//https://react-select.com/props

const ReactSelect = <T extends OptionType|OptionType[] = OptionType>(props:ReactSelectProps<T>)=>{
    const customStyles:StylesConfig<OptionType> = {
        // Control 요소에 스타일 적용
        control: (base:CSSObjectWithLabel, props: ControlProps<OptionType<string>, boolean, GroupBase<OptionType<string>>>):CSSObjectWithLabel => ({
            ...base,
            minHeight:'35px',
            border: props.isFocused ? '1px solid var(--bs-primary)' : "1px solid var(--bs-gray-300)",
            backgroundColor:'var(--bs-body-bg)',
            color: 'var(--bs-body-color)',
            borderRadius: '0.475rem',
            boxShadow: 'none',
            fontSize: '1rem',
            // boxShadow: props.isFocused ? '0 0 0 1px #007bff' : null,
            '&:hover': {
                border: '1px solid var(--bs-primary) !important',
            },
        }),
        input:(base, props)=>({
            ...base,
            color:'var(--bs-dark)',
        }),
        dropdownIndicator:(base, props)=>({
            ...base,
            padding:'6px',
        }),
        // 메뉴박스 요소에 스타일 적용
        menu: (base:CSSObjectWithLabel, props: MenuProps<OptionType<string>, boolean, GroupBase<OptionType<string>>>):CSSObjectWithLabel => ({
            ...base,
            backgroundColor:'var(--bs-body-bg)',
        }),
        // Option 요소에 스타일 적용
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? 'var(--bs-gray-300)' : 'var(--bs-body-bg)',
            fontSize: '1rem',
            color: 'var(--bs-dark)',
            '&:hover': {
                backgroundColor: 'var(--bs-gray-200)',
                color: 'var(--bs-dark)',
            },
        }),
        // SingleValue 요소에 스타일 적용
        singleValue: (provided) => ({
            ...provided,
            color: 'var(--bs-dark)',
            ...props.valueStyle,
        }),
        // MultiValue 요소에 스타일 적용
        multiValue: (provided) => ({
            ...provided,
            color: 'var(--bs-dark)',
            ...props.valueStyle,
            fontSize: '1.175em',
        }),
    };

    const onChange = (value?:T)=>{
        props.onChange && props.onChange(value);
    }

    return <Select
        className={props.className}
        placeholder={props.placeholder || '선택'}
        value={ props.value || {value:"", label:props.placeholder || '선택'} }
        onChange={(v)=>onChange(v as T)}
        options={props.options as OptionType[]}
        isSearchable={props.isSearchable}
        isMulti={props.isMulti}
        styles={customStyles}
        isDisabled={props.disabled}
        closeMenuOnSelect={props.closeMenuOnSelect}
    />
}

export default ReactSelect;
