import Code from "./Code";

export default class KakaoMomentCode extends Code{

    public static readonly data = {
        options:{

            /*
            실적Level
            biz-channels
            campaigns
            ad-groups
            ads
            ad-extensions
            keywords
            creatives // 카카오
            creative-links // 카카오
            */
            ExplorerLevel:{
                campaign: {value:'campaigns',   label:'캠페인'},
                adGroup:    {value:'ad-groups', label:'광고그룹'},
                ad:         {value:'ads',       label:'소재'},
            },

            /** 지표 선택용 */
            dimensionType: {
                default:        {value:"-",                 label:"기본"},
                device:         {value:"device",            label:"디바이스"},
                placement:      {value:"placement",         label:"게재지면"},
                creative_format:{value:"creative_format",   label:"소재유형"},
                location:       {value:"location",          label:"지역"},
            },
        },

        type:{
            downlaodEntityType: {
                campaign        : {value:"KMET0002", label:"캠페인",     data:{ path:'campaign' }},
                adgroup         : {value:"KMET0003", label:"광고그룹",   data:{ path:'ad-group' }},
                ad              : {value:"KMET0004", label:"소재",      data:{ path:'ads' }},
                device          : {value:"KMET0005", label:"디바이스",   data:{ path:'device' }},
                location        : {value:"KMET0006", label:"지역",      data:{ path:'placement' }},
                creativeFormat  : {value:"KMET0007", label:"소재유형",   data:{ path:'creative-format' }},
                placement       : {value:"KMET0008", label:"게제지면",   data:{ path:'placement' }},
            },
        },

        Assets:{
            /** 캠페인 유형 */
            campaignType: {
                TALK_BIZ_BOARD:         {value:'TALK_BIZ_BOARD',            label:'카카오톡비즈보드'},
                DISPLAY:                {value:'DISPLAY',                   label:'디스플레이'},
                TALK_CHANNEL:           {value:'TALK_CHANNEL',              label:'카카오톡 채널'},
                DAUM_SHOPPING:          {value:'DAUM_SHOPPING',             label:'다음쇼핑'},
                VIDEO:                  {value:'VIDEO',                     label:'동영상'},
                PERSONAL_MESSAGE:       {value:'PERSONAL_MESSAGE',          label:'개인화 메시지'},
                PC_TALK_BOTTOM:         {value:'PC_TALK_BOTTOM',            label:'포커스보드'},
                SPONSORED_BOARD:        {value:'SPONSORED_BOARD',           label:'스폰서드보드'},
                PRODUCT_CATALOG:        {value:'PRODUCT_CATALOG',           label:'상품 카탈로그'},
                KAKAO_TV:               {value:'KAKAO_TV',                  label:'카카오TV'},
                PC_TALK_RICH_POP:       {value:'PC_TALK_RICH_POP',          label:'리치팝 올데이'},
                TALK_BIZ_BOARD_RESERVED:{value:'TALK_BIZ_BOARD_RESERVED',   label:'카카오 비즈보드 CPT'},
                FOCUS_FULL_VIEW:        {value:'FOCUS_FULL_VIEW',           label:'포커스 풀뷰'},
                PROFILE_FULL_VIEW:      {value:'PROFILE_FULL_VIEW',         label:'프로필 풀뷰'},
            },

            /** 캠페인 목표 */
            objective: {
                REACH:      {value:'REACH',         label:'도달'},
                VIEW:       {value:'VIEW',          label:'조회'},
                CONVERSION: {value:'CONVERSION',    label:'전환'},
                VISITING:   {value:'VISITING',      label:'방문'},
            },

            /** 광고 목표 대상 */
            objectiveType: {
                CATALOG:      {value:'CATALOG',         label:'카탈로그'},
                TALK_CHANNEL: {value:'TALK_CHANNEL',    label:'카카오톡채널'},
                PIXEL_AND_SDK:{value:'PIXEL_AND_SDK',   label:'픽셀&SDK'},
            },

            /** 최적화 전환 유형 */
            objectiveDetailType: {
                PURCHASE:               {value:'PURCHASE',                  label:'구매'},
                APP_INSTALL:            {value:'APP_INSTALL',               label:'카탈로그앱 설치'},
                COMPLETE_REGISTRATION:  {value:'COMPLETE_REGISTRATION',     label:'회원가입'},
                SIGN_UP:                {value:'SIGN_UP',                   label:'서비스 신청'},
                CART:                   {value:'CART',                      label:'장바구니'},
                PARTICIPATION:          {value:'PARTICIPATION',             label:'잠재고객'},
                ADD_FRIEND:             {value:'ADD_FRIEND',                label:'채널 친구 추가'},
                SEND_MESSAGE:           {value:'SEND_MESSAGE',              label:'메시지 발송'},
                CATALOG_PRODUCT_PURCHASE:{value:'CATALOG_PRODUCT_PURCHASE', label:'카탈로그 상품 구매'},
            },

            /** 과금 방식 */
            pricingType: {
                CPV:    {value:'CPV',   label:'CPV'},
                CPM:    {value:'CPM',   label:'CPM'},
                CPT:    {value:'CPT',   label:'CPT'},
                CPMS:   {value:'CPMS',  label:'CPMS'},
                CPA:    {value:'CPA',   label:'CPA'},
                CPC:    {value:'CPC',   label:'CPC'},
            },

            /** 입찰 방식 */
            strategy: {
                AUTOBID:        {value:'AUTOBID',       label:'자동입찰'},
                MANUAL:         {value:'MANUAL',        label:'수동'},
                OPTIMIZATION:   {value:'OPTIMIZATION',  label:'목적 최적화'},
            },

            /** 광고그룹 유형 */
            adGroupType: {
                DIRECT_MESSAGE: {value:'DIRECT_MESSAGE',    label:'다이렉트 메시지'},
                DISPLAY:        {value:'DISPLAY',           label:'디스플레이'},
                MESSAGE:        {value:'MESSAGE',           label:'메시지'},
            },

            adType: {
                IMAGE_BANNER:               {value:'IMAGE_BANNER',              label:'이미지 배너'},
                VIDEO_NATIVE:               {value:'VIDEO_NATIVE',              label:'비디오 네이티브'},
                IMAGE_NATIVE:               {value:'IMAGE_NATIVE',              label:'이미지 네이티브'},
                IMAGE_BOX:                  {value:'IMAGE_BOX',                 label:'이미지 박스'},
                CATALOG_MANUAL:             {value:'CATALOG_MANUAL',            label:'이미지 카탈로그'},
                CATALOG_DYNAMIC:            {value:'CATALOG_DYNAMIC',           label:'다이나믹 카탈로그'},
                SERVICE_CONTENT:            {value:'SERVICE_CONTENT',           label:'콘텐츠'},
                BASIC_TEXT_MESSAGE:         {value:'BASIC_TEXT_MESSAGE',        label:'기본 텍스트형'},
                WIDE_LIST_MESSAGE:          {value:'WIDE_LIST_MESSAGE',         label:'와이드 리스트형'},
                WIDE_MESSAGE:               {value:'WIDE_MESSAGE',              label:'와이드 이미지'},
                CAROUSEL_COMMERCE_MESSAGE:  {value:'CAROUSEL_COMMERCE_MESSAGE', label:'캐러셀 커머스'},
                CAROUSEL_FEED_MESSAGE:      {value:'CAROUSEL_FEED_MESSAGE',     label:'캐러셀 피드'},
                PREMIUM_VIDEO_MESSAGE:      {value:'PREMIUM_VIDEO_MESSAGE',     label:'프리미엄 동영상'},
                RICH_NATIVE:                {value:'RICH_NATIVE',               label:'RICH_NATIVE'},
                VIDEO_INSTREAM:             {value:'VIDEO_INSTREAM',            label:'VIDEO_INSTREAM'},
            },

            dimensionDeviceType:{
                PC:     { value:'PC',       label:'PC'},
                iOS:    { value:'iOS',      label:'iOS'},
                Android:{ value:'Android',  label:'Android'},
                NA:     { value:'기타',      label:'기타'}, //N/A
            },

            dimensionPlacementType:{
                KAKAO_TALK:     {value:'카카오톡',    label:'카카오톡'},
                DAUM:           {value:'다음',       label:'다음'},
                KAKAO_STORY:    {value:'카카오스토리',  label:'카카오스토리'},
                KAKAO_SERVICE:  {value:'카카오서비스',  label:'카카오서비스'},
                NETWORK:        {value:'네트워크',     label:'네트워크'},
            },
            dimensionLocationType:{
                L01: {value:'서울특별시',    label:'서울특별시'},
                L02: {value:'인천광역시',    label:'인천광역시'},
                L03: {value:'경기도',       label:'경기도'},
                L04: {value:'강원도',      label:'강원도'},
                L05: {value:'세종특별자치시', label:'세종특별자치시'},
                L06: {value:'대전광역시',    label:'대전광역시'},
                L07: {value:'충청북도',     label:'충청북도'},
                L08: {value:'충청남도',     label:'충청남도'},
                L09: {value:'광주광역시',    label:'광주광역시'},
                L10: {value:'전라북도',     label:'전라북도'},
                L11: {value:'전라남도',     label:'전라남도'},
                L12: {value:'대구광역시',    label:'대구광역시'},
                L13: {value:'울산광역시',    label:'울산광역시'},
                L14: {value:'경상북도',     label:'경상북도'},
                L15: {value:'경상남도',     label:'경상남도'},
                L16: {value:'부산광역시',    label:'부산광역시'},
                L17: {value:'제주특별자치도',  label:'제주특별자치도'},
                L18: {value:'해외',         label:'해외'},
                L19: {value:'알수없음',      label:'알수없음'},
            },
        }
    };

    
};

