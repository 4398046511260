/* eslint-disable jsx-a11y/anchor-is-valid */
import { ApexOptions } from 'apexcharts';
import { CSSProperties, useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ChartConfigType } from '../../modules/charts';

export interface CircleChartItemType{
    id: string;
    label: string;
    value: number;
    type?: string;
    color?: string;
    mediaType?: string;
}


export interface CircleChartProps extends Omit<ChartConfigType, 'data'>{
    className?:string;
    style?:CSSProperties;
    data?:CircleChartItemType[];
    selection?:(index:number)=>void;
    selectionIndex?: number;
}

const CircleChart:React.FC<CircleChartProps> = (props) => {
    const [selectIndex, setSelectIndex] = useState<number|undefined>();
    const options:ApexOptions = {
        labels:props.data?.map((v)=>v.label) || [],
        chart: {
            type: 'donut',
            events: {
                dataPointSelection: (e, chart, opts)=>{ setSelectIndex(opts.dataPointIndex); props.selection && props.selection(opts.dataPointIndex)},
            }
        },
        colors: props.data?.map((v)=>v.color || "") || [],
        stroke:{width:0, },

        plotOptions: {
            pie: {
                startAngle: 0,
                endAngle: 360,
                offsetY: 0,
                donut:{
                    size: '60%',
                    background: 'transparent',
                    labels:{
                        show:true,
                        name:{
                            offsetY:20,
                        },
                        value:{
                            color: 'var(--bs-body-color)',
                            fontWeight: 600,
                            fontSize: '1.35em',
                            offsetY: -12,
                            formatter: (v)=>v.addComma(),
                        },
                        total:{
                            show:true,
                            label: props.title,
                            color: 'var(--bs-body-color)',
                            fontWeight: 100,
                            fontSize: '0.835em',
                            formatter: (w)=>{
                                const num:number[] = w.globals.seriesTotals;
                                return num.sum().round(0).addComma();
                            },
                        }
                    },
                }
            },
        },
        grid: {
            padding: {
                bottom: 0,
                left:0,
                right:0,
                top:0,
            }
        },
        tooltip:{
            y:{
                formatter: (val, opts)=>{
                    return `${val.addComma()}원`;
                },
            }
        },
        dataLabels: {
            enabled: true,
        },
        legend: {
            show: false,
            formatter: (name:string, opts:any)=>{
                const series:number = opts.w.globals.series[opts.seriesIndex];
                const total = opts.w.config.series.sum();
                return `${name} : ${(series/total*100).toFixed(2)}%`;
            },
            onItemClick:{ toggleDataSeries:true }   // 클릭 연동
        },
    };

    useEffect(()=>{
        setSelectIndex(props.selectionIndex);
    },[props.selectionIndex]);

    return <ReactApexChart options={options} selection={selectIndex} series={props.data?.map((v)=>v.value) || []} type="donut" />;
}

export default CircleChart;

