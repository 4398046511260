/* eslint-disable jsx-a11y/anchor-is-valid */
import Banner from './Banner';
import BizMoneyList from './BizMoneyList';
import AppsDashboard from './appsDashboard/AppsDashboard';
import ReportDashboard from './reportDashboard/ReportDashboard';

const DashboardWrapper: React.FC = () => {
    return <>
        <div className='mb-3'>
            <div className='fw-bolder fs-3'>adly 현황</div>
        </div>

        <div className='row'>
            <div className='mb-6' style={{width:"calc( 520px + 0.75rem )"}} >
                <Banner />
            </div>
            <div className='col-xxl'>
                <div className='row'>
                    <div className='col-xl-6 mb-6'>
                        <AppsDashboard />
                    </div>
                    <div className='col-xl-6 mb-6'>
                        <BizMoneyList />
                    </div>
                    {/* <div className='col-xxl-3 mb-6'>
                        <CustomBaseCard style={{height:'320px'}} title='진행중인 작업'>
                            <HistoryDashboard />
                        </CustomBaseCard>
                    </div> */}
                </div>
            </div>
        </div>

        <ReportDashboard />
    </>;
}

export default DashboardWrapper;
