import clsx from 'clsx';
import { CSSProperties, useEffect, useRef } from 'react';
import { Dropdown } from 'react-bootstrap';
import { EstimateAccountGroupType } from '../../../common/services/models/admin/EstimateTypes';
import { useUserContext } from '../../../contexts/UserContext';
import Code from '../../../modules/code/Code';
import style_dropdown from '../../modules/dropdown/style.module.scss';
import { AccountIcon } from './AccountSelect';
import styles from './accountSelect.module.scss';


interface AccountGroupSelectProps{
    onChange?:(accountGroup:EstimateAccountGroupType)=>void;
    style?: CSSProperties;
    className?: string;
    value?: EstimateAccountGroupType;
}

const AccountGroupSelect = (props:AccountGroupSelectProps)=> {
    const userContext = useUserContext();
    const body = useRef<HTMLDivElement>(null);
    const onClick = (value:EstimateAccountGroupType)=>{
        body?.current?.click();
        props.onChange?.(value);
    }

    useEffect(()=>{
        body?.current?.click();
    },[props.value]);
    
    return <Dropdown ref={body} className={props.className} style={props.style}>
        <Dropdown.Toggle
            variant="light" id="Dropdown_Toggle_Custom" 
            className={clsx('border align-content-center align-items-center d-flex', style_dropdown.toggle) }
        >
            {props.value && <OptionItem value={props.value} />}
            {!props.value && <div className='w-200px text-gray-600 text-left'>광고주 선택</div>}
            <i className="bi bi-chevron-down ms-2" style={{marginRight:'-1em'}}/>
        </Dropdown.Toggle>
        <Dropdown.Menu className='scroll-y' >
            {userContext.userSelect.accountState?.list.map((v, index)=><div key={index} className='d-block p-2 ms-2 bg-hover-light' onClick={()=>onClick(v)}>
                <OptionItem value={v} />
            </div>)}
        </Dropdown.Menu>
    </Dropdown>
}

export default AccountGroupSelect;


const OptionItem:React.FC<{
    value?:EstimateAccountGroupType, 
    onClick?:(v?:EstimateAccountGroupType)=>void
    className?: string;
}> = (props)=> <span className={clsx(styles.header, props.className)} onClick={()=>props.onClick?.(props.value)}>
    <div className='d-flex text-nowrap'>
        <AccountIcon value={props.value} className='me-3' size='24px' />
        <div className='mt-1 me-4 text-nowrap w-150px text-left'>{props.value?.magName}</div>
        <div className={clsx("fs-8 m-1 fw-bold text-nowrap align-content-center", props.value?.magUseYn===Code.base.useYnExt.Y.value ? 'text-success' : 'text-danger')}>
            ● {Code.getLabel(Code.base.useYnExt, props.value?.magUseYn || "")}
        </div>
    </div>
</span>;