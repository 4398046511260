import UserSelect from "../../common/helpers/UserSelect";
import { NotificationQueryType, NotificationResType } from "../../common/services/models/NotificationTypes";

export interface NaloPageGoParamsType{
    mediaCode: string;
    entityType: string;
    productType?: string;
    entityKeys?: string[];
}

export const naloPageGo = (params:NaloPageGoParamsType|NotificationResType|NotificationQueryType, target?:string, path?:string, hash?:string)=>{
    const qry = UserSelect.storage.getQueryString(undefined, params);
    window.open( `${(path || '/popup/nalo/create')}?${qry}#${hash}`, target || '_blank'); 
}
