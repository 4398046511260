import clsx from "clsx";
import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { NaverService } from "../../../common/services";
import { BidBaseDashboardResType } from "../../../common/services/models/naver/BidBaseTypes";
import { useUserContext } from "../../../contexts/UserContext";
import MediaTypeIcon from "../../modules/symbol/MediaTypeIcon";
import styles from '../style.module.scss';


const BidBaseList:React.FC = ()=>{
    const userContext = useUserContext();
    const [useData, setUseData] = useState<BidBaseDashboardResType[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const dataLoad = ()=>{
        setLoading(true);
        NaverService.dashboard.getBidBase().then((res)=>{
            if(res.status===200){
                const tmp:BidBaseDashboardResType[] = res.data.result;
                setUseData(tmp.sort((a,b)=>a.errorYn===b.errorYn ? (a.keywordCnt>b.keywordCnt ? -1 : 1) : (a.errorYn>b.errorYn ? -1 : 1)));    //오류 우선 키워드 개수 내림차
            }
        })
        .catch((e)=>{ 
            userContext.alertMessage.add({
                variant:"danger",
                title:"자동입찰 운영현황 데이터 조회 오류",
                body:<>"자동입찰 운영현황 데이터 데이터 조회 중 오류가 발생했습니다.<br />{NaverService.ErrorMessage(e)}</>
            });
        })
        .finally(()=>{ setLoading(false) });
    }

    // eslint-disable-next-line
    useEffect(()=>{dataLoad(); },[]);

    return <div className="scroll" style={{height:'240px'}}>
        <table className={styles.table}>
            <thead>
                <tr className="bg-body">
                    <th className='text-left'>광고주</th>
                    <th className='text-left'>자산</th>
                    <th className='text-right'>키워드 수</th>
                    <th>입찰 상태</th>
                </tr>
            </thead>
            <tbody>
                {loading && <tr><td className="text-center h-200px" colSpan={6}><Spinner animation="border" variant={"secondary"} role='status' /></td></tr>}
                {!loading && !useData?.length && <tr><td className="text-center h-200px" colSpan={6}>운영중인 자동입찰 키워드가 없습니다.</td></tr>}
                {!loading && useData.map((v,index)=><tr key={index}>
                    <td>{v.magName}</td>
                    <td>
                        <Link
                            to={'/pages/naver/bidbase'}
                            onClick={()=>{userContext.userSelect.accountState?.select(v.magId, "NAVER", v.clientId.toString())}}
                            className="text-hover-primary text-dark"
                        >
                            <MediaTypeIcon code={"NAVER"} label={v.clientName} viewLabel={true} />
                        </Link>
                    </td>
                    <td className="text-right">{v.keywordCnt.addComma()}</td>
                    <td className={clsx("text-center", v.errorYn==='Y' ? "text-danger" : "text-success" )}>
                        <Link
                            to={'/pages/naver/bidbase'}
                            onClick={()=>{userContext.userSelect.accountState?.select(v.magId, "NAVER", v.clientId.toString())}}
                            className={clsx("text-hover-primary", v.errorYn==='Y' ? 'text-danger' : 'text-success')}
                        >{v.errorYn==='Y' ? '오류' : '정상'}</Link>
                    </td>
                </tr>)}
            </tbody>
        </table>
    </div>
}
export default BidBaseList;