import { useState } from "react";
import { EstimateAccountClientType, EstimateAccountGroupType } from "../services/models/admin/EstimateTypes";

const STORAGE_ID = 'iam.user.selected';

export interface AccountType{
    accountGroup?:EstimateAccountGroupType;
    accountClient?:EstimateAccountClientType;
}

export interface DateRangeType{
    startDate?:string;
    endDate?:string;
}

export interface ExplorerType{
    media?:string;
    level?:string;
    search?:string;
}
export interface UserSelectType{
    dateRange?: DateRangeType;
    deviceType?: string[];   //P,M
    account?:AccountType;
    create_time?: number;
    explorer?:ExplorerType;
    sidebarMode?: string;
}
const defaultValues:UserSelectType = (()=>{
    return {
        dateRange:{
            startDate:new Date().addDays(-14).format('yyyy-MM-dd'),
            endDate:new Date().addDays(-1).format('yyyy-MM-dd'),
        },
        deviceType:['P','M'],
        explorer:undefined,
    }
})();


export interface UserSelectStateType{
    set: (data:UserSelectType)=>void;
    load: ()=>void;
    data?: UserSelectType;
    accountState?:{
        account: AccountType;
        select: (magId?:number, mediaType?:string, clinetId?:string, data?:EstimateAccountGroupType[])=>void;
        list: EstimateAccountGroupType[];
        setList: React.Dispatch<React.SetStateAction<EstimateAccountGroupType[]>>;
    };
}


/**
 * 사용자 설정정보를 관리합니다.
 */
const UserSelect = class{
    static state = ():UserSelectStateType=>{
        const [accountGroup, setAccountGroup] = useState<EstimateAccountGroupType[]>([]);
        const [account, setAccount] = useState<AccountType>({});
        const [data, setData] = useState<UserSelectType>(defaultValues);
        const load = ()=>{ setData(UserSelect.storage.get()); }
        const set = (data:UserSelectType)=>{
            setData(data);
            UserSelect.storage.set(data);
        }
        return {set,load, data, accountState:{
            account: account,
            /** data 시간차에 의해 데이터가 정상 처리되기 전 작업될 수 있기에 직접 받을 수도 있게 처리함 */
            select: (magId?:number, mediaType?:string, clinetId?:string, data?:EstimateAccountGroupType[])=>{
                const tmp1 = (data || accountGroup).find((v)=>v.magId===magId);
                const tmp2 = tmp1?.clients.find((v)=>v.mediaType===mediaType && v.clientId===clinetId);
                // console.log({accountGroup, magId, mediaType, clinetId, tmp1, tmp2});
                setAccount({accountGroup:tmp1, accountClient:tmp2}); //계정선택 표시 제어됨
                UserSelect.account.set(tmp1, tmp2); //localstorage에 저장 - Axios에서 사용함(기본)
            },
            list: accountGroup,
            setList: setAccountGroup,
        }};
    }

    // static getData = ()=>{
    //     const storage = UserSelect.storage.get();
    //     const params =  UserSelect.getParamData()
    //     console.log({storage, params});
    //     return storage || params || defaultValues;
    // }

    static getParamData = ():UserSelectType|undefined => {
        const select = new URL(window.location.href).searchParams.get('select');
        return select && JSON.parse(select || '');
    }
    /**
     * explorer 설정값 관리
     */
    public static explorer = class{
        public static set = (level:string, search:string, media?:string) => {
            const info = UserSelect.storage.get();
            info.explorer = {level, search, media};
            UserSelect.storage.set(info);
        }

        /**
         * explorer 설정값 반환합니다.
         * @returns 
         */
        public static get = (media?:string):ExplorerType | undefined=>{
            const info = UserSelect.storage.get();
            return info?.explorer;
        }
    }

    /** 사이드바 메뉴 모드 설정 */
    public static sidebar = class{
        /** 사이드바 메뉴 모드 설정값 저장 */
        public static set = (value?:string) => {
            const info = UserSelect.storage.get();
            info.sidebarMode = value || "";
            UserSelect.storage.set(info);
        }

        /** 사이드바 메뉴 모드 설정값 읽기 */
        public static get = ():string | undefined=>{
            const info = UserSelect.storage.get();
            return info?.sidebarMode;
        }
    }
    /**
     * Table deviceType 설정값 관리
     */
    public static deviceType = class{
        public static set = (value?:string[]) => {
            const info = UserSelect.storage.get();
            info.deviceType = value || ['P','M'];
            UserSelect.storage.set(info);
        }

        /**
         * Table deviceType 설정값 반환합니다.
         * @returns 
         */
        public static get = ():string[] | undefined=>{
            const info = UserSelect.storage.get();
            return info?.deviceType;
        }
    }

    /**
     * 화면 우상단의 날짜기간 선택값을 저장합니다.
     */
    public static dateRange = class{
        public static set = (startDate?:string, endDate?:string) => {
            const info = UserSelect.storage.get();
            info.dateRange = {startDate, endDate};
            UserSelect.storage.set(info);
        }

        /**
         * 화면 우상단의 날짜기간 값을 반환합니다.
         * @returns 
         */
        public static get = ():{startDate?:string, endDate?:string} | undefined=>{
            const info = UserSelect.storage.get();
            return info?.dateRange;
        }
    }

    /**
     * 광고주 계정 선택 관리
     */
    public static account = class{
        /**
         * 값을 저장합니다.
         * @param props 
         */
        public static set = (accountGroup?:EstimateAccountGroupType, accountClient?:EstimateAccountClientType) => {
            const info = UserSelect.storage.get();
            info.account = {accountGroup, accountClient};
            UserSelect.storage.set(info);
        }

        /**
         * 값을 로딩합니다.
         * @returns 
         */
        public static get = ():AccountType | undefined=>{
            const info = UserSelect.storage.get();
            return info?.account;
        }

        /**
         * 광고주 선택정보를 삭제합니다.
         */
        public static clear = ():void=>{
            const info = UserSelect.storage.get();
            info.account = {};
            UserSelect.storage.set(info);
        }
    }

    public static storage = class {
        /**
         * 세션 스토리지에 저장합니다.
         * @param token 및 사용자정보
         */
        public static set(data:UserSelectType){
            data.create_time = new Date().getTime();
            sessionStorage.setItem(STORAGE_ID, JSON.stringify(data));
        }

        /**
         * 세션스토리지의 값을 반환됩니다.
         * @returns 
         */
        public static get():UserSelectType{
            const storage = UserSelect.storage.getStorage();
            const params =  UserSelect.getParamData();
            return storage || params || defaultValues;
        }
    
        /** 팝업링크나 새창열기 하는 경우, 현재 선택정보 전달용 쿼리 생성 */
        public static getQueryString(append?:object, appendParams?:object):string{
            const userselect = UserSelect.storage.get();
            if(userselect.account?.accountGroup?.clients){
                userselect.account.accountGroup.clients = [];   //불필요한 데이터 제거 - 너무길 경우, 413에러 발생함
            }
            const params:string[][] = [
                ['select', JSON.stringify({...userselect, ...append})],
                ...Object.entries(appendParams || {})
            ];
            return new URLSearchParams(params).toString();
        }

        static getStorage():UserSelectType|undefined{
            const data: string | null = sessionStorage.getItem(STORAGE_ID);
            if (!data) { return undefined; }
            try {
                const info:UserSelectType|undefined  = data ? JSON.parse(data) : undefined;
                if (!info) { return undefined; }
                return info;
            } catch (error) {
                console.error('AUTH LOCAL STORAGE PARSE ERROR', error);
                return undefined;
            }
        }

        /**
         * 로컬스토리지를 비웁니다.
         */
        public static clear(){
            sessionStorage.removeItem(STORAGE_ID);
        }
    }
}

export default UserSelect;