import { useState } from "react";
import { NospService } from "../services";
import { AdGroupSimpleResType } from "../services/models/nosp/AdGroupTypes";
import { CampaignSimpleResType } from "../services/models/nosp/CampaignTypes";
import { MediaResResultType, TreeResDataType } from "../services/models/ResponseTypes";

export type NospTreeResType = TreeResDataType<CampaignSimpleResType,AdGroupSimpleResType>;

export interface NospTreeStateType{
    set: (data:NospTreeResType[])=>void;
    load: ()=> Promise<NospTreeResType[]>;
    data: NospTreeResType[];
}

const NospTreeStorage = class {
    public state = ():NospTreeStateType=>{
        const [data, setData] = useState<NospTreeResType[]>([]);
        const load = async ()=>{ return this.load().then((data)=>{setData(data); return data;}); }
        const set = (data:NospTreeResType[])=>{ setData(data); }
        return {set, load, data};
    }

    /** 데이터 로딩 */
    private async load():Promise<NospTreeResType[]>{
        const campaign:CampaignSimpleResType[] = await NospService.campaign.getSimpleList<MediaResResultType<CampaignSimpleResType>>()
        .then(((res) => {
            const data:CampaignSimpleResType[] = res.data.result || [];
            return data.sort((a,b)=>a.campaignName < b.campaignName ? -1 : 1 );
        }));
    
        const adgroup:AdGroupSimpleResType[] = await NospService.adgroup.getSimpleList<MediaResResultType<AdGroupSimpleResType>>()
        .then(((res) => {
            const data:AdGroupSimpleResType[] = res.data.result || [];
            return data.sort((a,b)=>a.adGroupId < b.adGroupId ? -1 : 1 );
        }));

        const data:NospTreeResType[] = campaign
            .map((v)=>({
                data:v,
                children: adgroup.filter((v2)=>v.campaignId===v2.campaignId),
            }));
        return data;
    }
}

export default NospTreeStorage;