import { useState } from "react";
import CustomBaseCard from "../../modules/card/CustomBaseCard";
import BidBaseList from "./BidBaseList";

interface AppsDashboardProps{
}

const AppsDashboard:React.FC<AppsDashboardProps> = (props)=>{
    const [activeKey, setActiveKey] = useState<string>('bidbase');
    const onSelect = (arg:any)=>{
        setActiveKey(arg);
    }
    return <CustomBaseCard style={{height:'320px'}}  title='자동입찰 운영 현황'>
        <BidBaseList />
    </CustomBaseCard>
}
export default AppsDashboard;