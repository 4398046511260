import { useState } from "react";
import { KakaoMomentService } from "../services";
import { AdGroupSimpleResType } from "../services/models/kakaomoment/AdGroupTypes";
import { CampaignSimpleResType } from "../services/models/kakaomoment/CampaignTypes";
import { MediaResResultType, TreeResDataType } from "../services/models/ResponseTypes";

export type KakaoMomentTreeResType = TreeResDataType<CampaignSimpleResType,AdGroupSimpleResType>;

export interface KakaoMomentTreeStateType{
    set: (data:KakaoMomentTreeResType[])=>void;
    load: ()=> Promise<KakaoMomentTreeResType[]>;
    data: KakaoMomentTreeResType[];
}

const KakaoMomentTreeStorage = class {
    public state = ():KakaoMomentTreeStateType=>{
        const [data, setData] = useState<KakaoMomentTreeResType[]>([]);
        const load = async ()=>{ return this.load().then((data)=>{setData(data); return data;}); }
        const set = (data:KakaoMomentTreeResType[])=>{ setData(data); }
        return {set, load, data};
    }

    /** 데이터 로딩 */
    private async load():Promise<KakaoMomentTreeResType[]>{
        const campaign:CampaignSimpleResType[] = await KakaoMomentService.campaign.getSimpleList<MediaResResultType<CampaignSimpleResType>>()
        .then(((res) => {
            const data:CampaignSimpleResType[] = res.data.result || [];
            return data.sort((a,b)=>a.campaignName < b.campaignName ? -1 : 1 );
        }));
    
        const adgroup:AdGroupSimpleResType[] = await KakaoMomentService.adgroup.getSimpleList<MediaResResultType<AdGroupSimpleResType>>()
        .then(((res) => {
            const data:AdGroupSimpleResType[] = res.data.result || [];
            return data.sort((a,b)=>a.adGroupName < b.adGroupName ? -1 : 1 );
        }));

        const data:KakaoMomentTreeResType[] = campaign
            .map((v)=>({
                data:v,
                children: adgroup.filter((v2)=>v.campaignId===v2.campaignId),
            }));
        return data;
    }
}

export default KakaoMomentTreeStorage;