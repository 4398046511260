import { NospServer } from '../../axios';
import Utils from '../../utils/Utils';
import { DatshBoardStatQryType } from '../models/DashboardTypes';



/** NAVER NOSP 대시보드용 Helper */
export default class DashboardHelper{
    static path="/dashboard"; //기본 Path
    /** NAVER NOSP 대시보드 작업이력 정보 */
    public static getStatus(code:string|string[]){
        return Utils.apiRequestValidation({useAccount:false}) || NospServer.get(`${this.path}/status`,{params:{status:code}});
    }

    /** NAVER NOSP 대시보드 통계정보 */
    public static getStat(params:DatshBoardStatQryType){
        return Utils.apiRequestValidation({useAccount:false}) || NospServer.get(`${this.path}/stat`,{params});
    }

    /** NAVER NOSP 대시보드 자동입찰 현황 */
    public static getBidBase(){
        return Utils.apiRequestValidation({useAccount:false}) || NospServer.get(`${this.path}/auto-bidding`,);
    }

}

