import { AxiosResponse } from 'axios';
import { Dispatch, SetStateAction } from 'react';
import { MetaServer } from '../../axios';
import UserSelect from '../../helpers/UserSelect';
import Utils from '../../utils/Utils';
import { callBackDownload } from '../helpers/Download';
import { RequestFilterType, RequestSearchType } from '../models/RequestTypes';
import { MediaResponseType } from '../models/ResponseTypes';

export default class HelperComponent {
    static path = '';
    static stat = '';
    static performances = '';
    static simple = '';
    static downloadPath = '';
    static onOff = '';

    // 다운로드
    static getStat<T = any>(
        ids: string[],
        filters?: RequestFilterType[],
        setLoading?: Dispatch<SetStateAction<boolean>>,
        path?: string
    ) {
        const dateRange = UserSelect.dateRange.get();
        const deviceType = UserSelect.deviceType.get();
        // const deviceTypes = filters?.find((v)=>v.key==='diviceTypes')?.value || 'P,M';
        // const deviceTypes = deviceType?.join(',') || 'P,M';
        const params = {
            deviceTypes: (deviceType?.length || 0) > 0 ? deviceType : ['M', 'P'],
            startDate: dateRange?.startDate?.replaceAll('-', ''), // "20230501",
            endDate: dateRange?.endDate?.replaceAll('-', ''), //"20230530",
            ids: ids,
        };

        setLoading && setLoading(true);
        return MetaServer.get<MediaResponseType<T>>(path || this.stat, {
            params: params,
            headers: {useAccount: true},
        }).finally(() => {
            setLoading && setLoading(false);
        });
    }

    /** POST 방식 Stat 로딩 - 대량 아이디용 */
    static postStat<T = any>(
        ids: string[],
        filters?: RequestFilterType[],
        setLoading?: Dispatch<SetStateAction<boolean>>
    ) {
        const dateRange = UserSelect.dateRange.get();
        const deviceType = UserSelect.deviceType.get();
        // const deviceTypes = filters?.find((v)=>v.key==='diviceTypes')?.value || 'P,M';
        const body = {
            deviceTypes: (deviceType?.length || 0) > 0 ? deviceType : ['M', 'P'],
            startDate: dateRange?.startDate?.replaceAll('-', ''), // "20230501",
            endDate: dateRange?.endDate?.replaceAll('-', ''), //"20230530",
            ids: ids,
        };

        setLoading && setLoading(true);
        return MetaServer.post<MediaResponseType<T>>(this.stat, body, {
            headers: {useAccount: true},
        }).finally(() => {
            setLoading && setLoading(false);
        });
    }

    /** GET 방식 Owner 기준 하위 Stat 데이터 로딩 */
    static getPerformances<T = any>(ownerId?: string, path?: string) {
        const dateRange = UserSelect.dateRange.get();
        const deviceType = UserSelect.deviceType.get();
        const params = {
            deviceTypes: (deviceType?.length || 0) > 0 ? deviceType : ['M', 'P'],
            startDate: dateRange?.startDate?.replaceAll('-', ''), // "20230501",
            endDate: dateRange?.endDate?.replaceAll('-', ''), //"20230530",
            ownerId: ownerId,
        };

        return MetaServer.get<MediaResponseType<T>>(path || this.performances, {
            params: params,
            headers: {useAccount: true},
        });
    }

    /** POST 방식 대량 Id에 대한 Stat 데이터 로딩 */
    static postPerformances<T = any>(ids: string[], path?: string) {
        const dateRange = UserSelect.dateRange.get();
        const deviceType = UserSelect.deviceType.get();
        const body = {
            deviceTypes: (deviceType?.length || 0) > 0 ? deviceType : ['M', 'P'],
            startDate: dateRange?.startDate?.replaceAll('-', ''), // "20230501",
            endDate: dateRange?.endDate?.replaceAll('-', ''), //"20230530",
            ids: ids,
        };

        return MetaServer.post<MediaResponseType<T>>(path || this.performances, body, {
            headers: {useAccount: true},
        });
    }

    // 다운로드
    static downloadCall(
        url: string,
        filters: RequestFilterType[],
        filename?: string,
        setLoading?: Dispatch<SetStateAction<boolean>>
    ) {
        if (setLoading !== undefined) setLoading(true);
        MetaServer.get(url, {
            responseType: 'blob',
            params: {...filters},
            headers: {useAccount: true},
        })
            .then(async (res: AxiosResponse) => {
                console.log('kakao downloadCall : ', res, res.headers['content-disposition']);
                if (res.data.type === 'application/json') {
                    // const responseData = await (res.data?.text());
                    // const responseJson = (typeof responseData === "string") ? JSON.parse(responseData) : responseData;
                    // const link = `/kakao/keyword/filedownload?taskId=${responseJson.taskId}`;
                    // const message = ApiUtils.getLinkMessage({title:"대용량 다운로드 이력 페이지 이동", path:link, notice:"데이터가 많은 경우, 많은 시간 소요로 인해 별도 프로세스로 처리됩니다. 결과는 아래 링크(대용량 다운로드 이력)를 통해 확인 가능합니다."});
                    // userContext?.modalMessage.add({title:"대용량 다운로드",body:message,ok:{click:()=>{}}});
                    // console.log(res, responseData,responseJson);
                } else {
                    const account = UserSelect.account.get();
                    const magId = account?.accountGroup?.magId || 'NONE';
                    const name = url.split('/').pop();
                    const fn = filename || `[iBase-kakao]${magId}_${name}.xlsx`;
                    callBackDownload(res, fn);
                }
            })
            .finally(() => {
                if (setLoading !== undefined) {
                    setLoading(false);
                    setTimeout(() => {
                        document.body.style.overflow = '';
                        document.body.style.paddingRight = '';
                    }, 500);
                }
            });
    }

    //표준 목록조회
    public static getList<T = any>(params: RequestSearchType, path?: string) {
        return (
            Utils.apiRequestValidation({}) ||
            MetaServer.get<T>(path || this.path, {
                params: {...params},
                headers: {useAccount: true},
            })
        );
    }

    // 선택한 자산 정보로 목록 조회
    public static getListWithClientId<T = any>(
        params: RequestSearchType,
        magId: number | null | undefined,
        clientId: number | null | undefined
    ) {
        return (
            Utils.apiRequestValidation({}) ||
            MetaServer.get<T>(this.path, {
                params: {...params},
                headers: {useSelectedMagId: magId, useSelectedClientId: clientId},
            })
        );
    }

    //Simple 목록조회
    public static getSimpleList<T = any>() {
        return (
            Utils.apiRequestValidation({}) ||
            MetaServer.get<T>(this.simple, {headers: {useAccount: true}})
        );
    }

    //표준 상세조회
    public static get<T = any>(id: string) {
        return (
            Utils.apiRequestValidation({}) ||
            MetaServer.get<T>(`${this.path}/${id}`, {headers: {useAccount: true}})
        );
    }

    //표준 상세조회
    public static getLocked<T = any>(ids: string[]) {
        return (
            Utils.apiRequestValidation({}) ||
            MetaServer.get<T>(`${this.path}/locked-resource-entities`, {
                params: {targetIds: ids},
                headers: {useAccount: true},
            })
        );
    }

    // lockState 로딩
    static getLockState(ids: string[], path?: string) {
        const params = {targetIds: ids};
        return (
            Utils.apiRequestValidation({}) ||
            MetaServer.get<MediaResponseType<{ids?: string[]}>>(
                `${path || this.path}/locked-resource-entities`,
                {params: params, headers: {useAccount: true}}
            )
        );
    }

    //표준 수정
    public static put<S = any>(body: S) {
        return (
            Utils.apiRequestValidation({}) ||
            MetaServer.put(this.path, body, {headers: {useAccount: true}})
        );
    }

    //소재연결 관리
    public static post<S = any>(body: S, params?: any) {
        return (
            Utils.apiRequestValidation({}) ||
            MetaServer.post(this.path, body, {params: params, headers: {useAccount: true}})
        );
    }

    //on-off 수정
    public static putOnOff<S = any>(body: S) {
        return (
            Utils.apiRequestValidation({}) ||
            MetaServer.put(this.onOff, body, {headers: {useAccount: true}})
        );
    }

    //표준 삭제
    public static delete<S = any>(body: S) {
        return (
            Utils.apiRequestValidation({}) ||
            MetaServer.delete(this.path, {data: body, headers: {useAccount: true}})
        );
    }

    //표준 다운로드
    public static download(
        filters: RequestFilterType[],
        setLoading: Dispatch<SetStateAction<boolean>>
    ) {
        this.downloadCall(this.downloadPath, filters, undefined, setLoading);
    }
}
