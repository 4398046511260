/* eslint-disable react/jsx-no-target-blank */
import clsx from 'clsx';
import { CSSProperties, useEffect, useState } from 'react';
import Config from '../../../../../../app/common/conf/Config';
import { TreeResDataType } from '../../../../../../app/common/services/models/ResponseTypes';
import { AdGroupSimpleResType } from '../../../../../../app/common/services/models/naver/AdGroupTypes';
import { CampaignSimpleResType } from '../../../../../../app/common/services/models/naver/CampaignTypes';
import { useUserContext } from '../../../../../../app/contexts/UserContext';
import NaverCode from '../../../../../../app/modules/code/NaverCode';
import Ellipsis from '../../../../../../app/modules/layer/Ellipsis';
import AdPloSearch from '../../../../../../app/pages/components/input/AdPloSearch';
import MoreButton from '../MoreButton';
import { SidebarTreeItem } from '../SidebarTreeItem';
import { SidebarTreeTitle } from '../SidebarTreeTitle';
import styles from '../style.module.scss';

const paging:number = 100;
const SidebarMenuMainNaverTree = () => {
    const [kwdPowerlink,setKwdPowerlink] = useState<string>("");
    const [kwdShopping, setKwdShopping] = useState<string>("");
    const [pageP, setPageP] = useState<number>(0);
    const [pageS, setPageS] = useState<number>(0);
    const userContext =useUserContext();

    useEffect(()=>{
        if(Config.app.TreeMode.enable){
            userContext.tree.naver.set({powerlink:[], shopping:[]}); //잔상방지
            userContext.modalLoading.show();
            userContext.tree.naver.load()
            .finally(()=>{ userContext.modalLoading.hide();});
        }
    // eslint-disable-next-line
    }, [userContext.userSelect.accountState?.account]);

    
    const basePath:string = '/pages/naver/tree';
    const powerlink = userContext.tree.naver.data.powerlink.filter((v)=>!kwdPowerlink || v.data.campaignName.toLocaleLowerCase().indexOf(kwdPowerlink.toLocaleLowerCase()) > -1);
    const shopping = userContext.tree.naver.data.shopping.filter((v)=>!kwdShopping || v.data.campaignName.toLocaleLowerCase().indexOf(kwdShopping.toLocaleLowerCase()) > -1);
    const viewDataP = powerlink.slice(0, (pageP+1)*paging);
    const viewDataS = shopping.slice(0, (pageS+1)*paging);
    return <>
        <SidebarTreeTitle
            key={NaverCode.data.type.campaign.WEB_SITE.value}
            to={`${basePath}/powerlink`}
            icon='/media/icons/duotune/general/gen022.svg'
            title='파워링크'
        >
            <div className='my-2'><AdPloSearch placeholder='캠페인 검색' onChange={(v)=>{ setKwdPowerlink(v.target.value) } } theme='dark'/></div>
            <div className='fs-7 mt-2' style={{color:"#9D9DA6"}}>캠페인 : {powerlink.length.addComma()}</div>
            <div className='tree_expanded'>
                {viewDataP.map((v:TreeResDataType<CampaignSimpleResType, AdGroupSimpleResType>)=>
                    <SidebarMenuAdGroup key={v.data.campaignId} value={v.data} adgroup={v.children} to='/pages/naver/tree/powerlink/campaign'/>
                )}
                {viewDataP.length < powerlink.length && <MoreButton onClick={()=>setPageP(pageP+1)}/>}
            </div>
        </SidebarTreeTitle>
        
        <SidebarTreeTitle
            key={NaverCode.data.type.campaign.SHOPPING.value}
            to={`${basePath}/shopping`}
            icon='/media/icons/duotune/general/gen022.svg'
            title='쇼핑검색'
        >
            <div className='my-2'><AdPloSearch placeholder='캠페인 검색' onChange={(v)=>{ setKwdShopping(v.target.value) } } theme='dark'/></div>
            <div className='fs-7 mt-2' style={{color:"#9D9DA6"}}>캠페인 : {shopping.length.addComma()}</div>
            <div className='tree_expanded'>
                {viewDataS.map((v)=>
                    <SidebarMenuAdGroup key={v.data.campaignId} value={v.data} adgroup={v.children} to='/pages/naver/tree/shopping/campaign'/>
                )}
                {viewDataS.length < shopping.length && <MoreButton onClick={()=>setPageS(pageS+1)}/>}
            </div>
        </SidebarTreeTitle>
    </>
}

export default SidebarMenuMainNaverTree;


const SidebarMenuAdGroup:React.FC<{value:CampaignSimpleResType, adgroup:AdGroupSimpleResType[], to?:string}> = (props)=>{
    const [useData, setUseData] = useState<AdGroupSimpleResType[]>([]);
    useEffect(()=>{
        setUseData(props.adgroup);
    }, [props.adgroup])

    const border ='1px solid var(--bs-gray-600)';
    const to:string = `${props.to}/${props.value.campaignId}`;
    return <SidebarTreeTitle to={to}
        treeIcon={true}
        minimizeHidden={true}
        title={<>
            <IconUserLock userLock={props.value.userLock}/>
            <Ellipsis title={props.value.campaignName}>{props.value.campaignName}</Ellipsis>
        </>}
    >
        <div className={clsx(styles.tree_item)}>
            {useData.map((v,index)=><SidebarTreeItem
                key={v.adGroupId} 
                className='p-1'
                to={`${to}/adgroup/${v.adGroupId}`}
                title={<>
                    <div className={styles.tree_item_bar}></div>
                    <IconUserLock userLock={v.userLock} style={{fontSize:'1.2em'}}/>
                    <Ellipsis title={v.adGroupName}>{v.adGroupName}</Ellipsis>
                </>}
            />)}
        </div>
    </SidebarTreeTitle>
}

const IconUserLock:React.FC<{userLock?:boolean, style?:CSSProperties}> = (props)=><span 
    className={clsx('me-1', props.userLock ? 'text-danger' : 'text-success')}
    style={{fontSize:'1.5em', lineHeight:'100%', ...props.style}}
>●</span>;
