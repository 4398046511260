import HelperComponent from './HelperComponent';

/** NAVER GFA 소재 Helper */
export default class AdHelper extends HelperComponent{
    static path="/ad"; //기본 Path
    static simple="";
    static stat='/ads/stat';   //실적데이터
    static performances='/explorer/ads';   //실적데이터 - Explorer용
    static downloadPath='/api/naversa/ads';
}
