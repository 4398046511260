import clsx from "clsx";
import { formatDistanceToNow } from "date-fns";
import { ko } from "date-fns/locale";
import { useEffect, useRef, useState } from "react";
import { Button, Container, ModalProps, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { AdminService } from "../../common/services";
import NotificationService from "../../common/services/NotificationService";
import { NotificationResType } from "../../common/services/models/NotificationTypes";
import { RequestFilterType } from "../../common/services/models/RequestTypes";
import { EstimateAccountGroupType, EstimateMagType } from "../../common/services/models/admin/EstimateTypes";
import { useUserContext } from "../../contexts/UserContext";
import { Confirm } from "../../modules/message";
import MediaTypeIcon from "../modules/symbol/MediaTypeIcon";
import { LevelMarking, LinkPageHandle, getMagAccountFind } from "./Modules";
import { notiLoad } from "./NotificationDataManager";

interface NotificationPopupListProps extends ModalProps{
    refresh?: ()=>void;
    state: [NotificationResType[], React.Dispatch<React.SetStateAction<NotificationResType[]>>];
    total: number;
    variant?: string;
    filters?: RequestFilterType[];
}

const NotificationPopupList:React.FC<NotificationPopupListProps> = (props)=>{
    const userContext = useUserContext();
    const [useData, setUseData] = props.state;
    const refMore = useRef<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false); // 폴링 제어용 / 중복동작방지
    const [magList, setMagList] = useState<EstimateMagType[]>([]);
    const [confirm, setConfirm] = useState<boolean>(false);
    const [accountData, setAccountData] = useState<EstimateMagType|undefined>();

    useEffect(()=>{ magListLoad(); },[]);
    const magListLoad = ()=>{
        userContext.modalLoading.show();
        AdminService.resource.getMags().then((res)=>{
            const estimate:EstimateAccountGroupType[] = userContext.userSelect.accountState?.list || [];
            const result = (res.data.result as EstimateMagType[]).map((v)=>({
                ...v,
                roleAuth: estimate.find((v2)=>v2.magId===v.magId)?.roleAuth,    //권한정보만 읽어옴
            }));
            setMagList(result);
        })
        .catch((e)=>{})
        .finally(()=>{ userContext.modalLoading.hide(); })
    }

    const notificationRead = (id:number)=>{
        userContext.modalLoading.show();
        NotificationService.put([id]).then(()=>{
            props.refresh?.();
        })
        .catch((e)=>{ userContext?.alertMessage.add({
            variant:'danger', 
            title:'알림 상태 변경 실패',
            body: <>알림 상태 변경에 실패하였습니다.<br/>{NotificationService.ErrorMessage(e)}</>,
        }); })
        .finally(()=>{ userContext.modalLoading.hide(); });
    }

    const notificationAllRead = ()=>{
        userContext.modalLoading.show();
        NotificationService.readAll(props.filters?.find(v=>v.key=='notiType')?.value).then(()=>{
            props.refresh?.();
        })
        .catch((e)=>{ userContext?.alertMessage.add({
            variant:'danger', 
            title:'알림 상태(모두 읽음) 변경 실패',
            body: <>알림 상태(모두 읽음) 변경에 실패하였습니다.<br/>{NotificationService.ErrorMessage(e)}</>,
        }); })
        .finally(()=>{ userContext.modalLoading.hide(); });
    }

    /** 스크롤 이벤트 처리 */
    const onScroll = (e:React.UIEvent<HTMLDivElement, UIEvent>)=>{
        if(e.currentTarget.scrollTop > e.currentTarget.scrollHeight - 750 && refMore.current===false){ //하단여부
            moreLoad();
        }
    }

    /** 데이터 추가 로딩 */
    const moreLoad = async ()=>{
        if(refMore.current){ return }   // 중복동작 방지
        refMore.current = true;
        setLoading(true);
        const data = await notiLoad(useData, props.total);
        if(data!==undefined){
            // 현재 목록 갱신
            setUseData((prev)=>{
                const min:number = prev.length > 0 ? Math.min(...prev.map((v)=>v.notiId)) : 0;   // 최소값
                const newData = data.filter((v)=>min===0 || v.notiId < min); //추가 데이터만 - 데이터 꼬임 방지
                return newData.length > 0 ? [...prev, ...newData] : prev;
            });
        }
        setLoading(false);
        refMore.current = false;
    }

    const onClick = (data:NotificationResType)=>{
        const {account} = getMagAccountFind(data, magList);
        setAccountData(account);
        notificationRead(data.notiId);
        props.onClick?.();  //읽음 처리
        LinkPageHandle(userContext, data, ()=>setConfirm(true));
    }


    return <div className="p-0">
        <div className={clsx("px-5 d-flex text-white rounded-top", `bg-${props.variant}-active`)}>
            <div className="col-8 py-7">
                <span className="fw-bold">읽지 않은 알림</span>
                <span className="ms-3 bg-white text-black px-2" style={{borderRadius:"1em", fontSize:"0.835em"}}>{props.total.addComma()}</span>
            </div>
            <div className="col-4 text-right">
                <Button size="sm" variant={props.variant} className="py-2 my-5" disabled={props.total===0} onClick={notificationAllRead}>모두 읽음</Button>
            </div>
        </div>
        <div className="scroll" style={{maxHeight:"660px"}} onScroll={onScroll}>
            {useData.map((v)=><MessageItem key={v.notiId} data={v} onClick={()=>onClick(v)} magList={magList} />)}
            {useData.length===0 && <Container key="none" className="p-6 text-center">새로운 알림이 없습니다.</Container>}
            {loading && <Container key="loading" className="p-6 text-center">
                <Spinner animation="border" variant="light-dark" role='status' />
            </Container>}
        </div>
        <div className="w-100 p-5 text-center border-top">
            <Link to="/pages/settings/profile#notification">전체 알림 보기 &gt;</Link>
        </div>
        <Confirm ok={{title:'닫기', onClick:()=>{  }}} title='광고주 권한 확인 요청' state={[confirm, setConfirm]}>
            {accountData?.magName || '해당 광고주'} 권한이 없습니다.<br/>
            광고주 권한 획득 후, 다시 시도해 주세요.
        </Confirm>
    </div>
}

export default NotificationPopupList;

const MessageItem:React.FC<{data:NotificationResType, onClick:()=>void, magList:EstimateMagType[]}> = (props)=>{
    const data=props.data;
    // const estimate:EstimateAccountGroupType[] = userContext.userSelect.accountState?.list || [];
    const {account, client} = getMagAccountFind(props.data, props.magList);

    return <div className="p-5 border-bottom d-flex cursor-pointer bg-hover-light" onClick={props.onClick}>
        <div className="w-20px text-center">
            <LevelMarking value={props.data.importance} />
        </div>
        <div className="w-100">
            <div className="d-flex text-gray-700 w-100">
                <div className="col-9">{account?.magName}</div>
                <div className="col-3 text-right">{foramtDate(data.createdDatetime)}</div>
            </div>
            <div className={clsx("fs-5", {"fw-bold": !props.data.readStatus})}>{props.data.title}</div>
            <div className="text-gray-700">
                {client && <MediaTypeIcon code={client?.mediaType} label={<>{client?.clientName},&nbsp;</>} viewLabel={true} nowrap={false} />}
                {props.data.description}
            </div>
        </div>
    </div>;
}


const foramtDate = (date:string) => {
    const d = new Date(date);
    const now = Date.now();
    const diff = (now - d.getTime()) / 1000; // 현재 시간과의 차이(초)
    if (diff < 60 * 1) { // 1분 미만일땐 방금 전 표기
        return "방금 전";
    }
    if (diff < 60 * 60 * 24 * 3) { // 3일 미만일땐 시간차이 출력(몇시간 전, 몇일 전)
        return formatDistanceToNow(d, {addSuffix: true, locale: ko});
    }
    // if (diff < 60 * 60 * 24 * 1000) { // 3일 미만일땐 시간차이 출력(몇시간 전, 몇일 전)
        return formatDistanceToNow(d, {addSuffix: true, locale: ko});
    // }
    // return format(d, 'PPP', {locale: ko}); // 날짜 포맷
}