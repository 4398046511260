import { AdGroupResType } from "../../../common/services/models/kakaomoment/AdGroupTypes";
import { AdResType } from "../../../common/services/models/kakaomoment/AdTypes";
import { CampaignResType } from "../../../common/services/models/kakaomoment/CampaignTypes";
import { StatReportInfoType, StatReportResType } from "../../../common/services/models/kakaomoment/StatReportType";
import KakaoMomentCode from "../../../modules/code/KakaoMomentCode";
import { ModifyViewerType } from "../../modules/modify/Modify";
import { StatDataExtends } from "../modules/OxfordColumns";
import { getDimensionInfo } from "./ExplorerListDetail";


export interface ConvertStatInfoViewDataType extends Omit<ModifyViewerType,"title">{
    title: string|React.ReactNode;
}

export const convertStatInfoViewData = (level?:string, data?:StatReportInfoType):ConvertStatInfoViewDataType[]|undefined=>{
    const dimensionInfo = getDimensionInfo(undefined, data);   //디멘전정보 추출
    switch(level){
        case KakaoMomentCode.data.options.ExplorerLevel.campaign.value : {
            const tmp = [];
            if(dimensionInfo.dimentsion){ tmp.push({title:'캠페인명', value:data?.campaignName}); }
            return [
                ...tmp,
                {title:'캠페인 ID', value:data?.campaignId},
            ];
        }
        case KakaoMomentCode.data.options.ExplorerLevel.adGroup.value : {
            const tmp = [];
            if(dimensionInfo.dimentsion){ tmp.push({title:'광고그룹명', value:data?.adGroupName}); }
            return [
                ...tmp,
                {title:'캠페인명', value:data?.campaignName},
                {title:'캠페인 ID', value:data?.campaignId},
                {title:'광고그룹 ID', value:data?.adGroupId},
            ];
        }

        case KakaoMomentCode.data.options.ExplorerLevel.ad.value : {
            const tmp = [];
            if(dimensionInfo.dimentsion){ tmp.push({title:'소재명', value:data?.adName}); }
            return [
                ...tmp,
                {title:'캠페인명', value:data?.campaignName},
                {title:'캠페인ID', value:data?.campaignId},
                {title:'광고그룹명', value:data?.adGroupName},
                {title:'광고그룹ID', value:data?.adId},
                {title:'소재ID', value:data?.adId},
            ];
        }
       
    }
    return [];
}


export const ExplorerTitle:React.FC<{level?:string, data?:StatReportInfoType, search?:string}> = (props)=>{
    const dimensionInfo = getDimensionInfo(props.data?.campaignId, props.data, undefined, props.search);
    if(dimensionInfo.dimentsion){
        return <>{KakaoMomentCode.getLabel(KakaoMomentCode.data.options.dimensionType, dimensionInfo.dimentsion)} : {dimensionInfo.dimentsionWord}</>; 
    }
    switch(props.level){
        case KakaoMomentCode.data.options.ExplorerLevel.campaign.value : return <>캠페인명 : {props.data?.campaignName}</>;
        case KakaoMomentCode.data.options.ExplorerLevel.adGroup.value : return <>광고그룹명 : {props.data?.adGroupName}</>;
        case KakaoMomentCode.data.options.ExplorerLevel.ad.value : return <>소재명 : {props.data?.adName}</>;
    }
    return <>Unknown</>;
}

/**
 * 20230622 --> 2023-06-22
 * @param txt 
 * @returns 
 */
export const getStatDateFormatter = (txt:string)=>{
    const tmp:string = txt.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3');
    if(isNaN(new Date(tmp).getTime())){ return txt; }
    return new Date(tmp).format('yyyy-MM-dd');
}

/** Stat 컬럼만 Sum 처리 합니다. */
export const getStatReportTotal = (data:StatReportResType[]):StatReportResType => {
    const total:StatReportResType = {
        key:'합계',
        clicks: 0,
        impressions: 0,
        reach: 0,
        cost: 0,
        videoView: 0,
        videoView25: 0,
        videoView50: 0,
        videoView75: 0,
        videoView100: 0,
        videoPlay3s: 0,
        videoPlay10s: 0,
        videoPlay15s: 0,
        videoPlay30s: 0,
        videoPlay60s: 0,
        videoPlayAuto: 0,
        videoPlayTouch: 0,
        videoUnmute: 0,
        messageSend: 0,
        messageOpen: 0,
        messageClick: 0,
        messageClickShare: 0,
        messageClickMedia: 0,
        messageClickTitle: 0,
        messageClickText: 0,
        messageClickList1: 0,
        messageClickList2: 0,
        messageClickList3: 0,
        messageClickList4: 0,
        messageClickButton1: 0,
        messageClickButton2: 0,
        messageClickOthers: 0,
        convKfPfAdd1d: 0,
        convKfPfAdd7d: 0,
        convCmptReg1d: 0,
        convCmptReg7d: 0,
        convViewCart1d: 0,
        convViewCart7d: 0,
        convPurchase1d: 0,
        convPurchase7d: 0,
        convParticipation1d: 0,
        convParticipation7d: 0,
        convSignUp1d: 0,
        convSignUp7d: 0,
        convAppInstall1d: 0,
        convAppInstall7d: 0,
        convPurchaseP1d: 0,
        convPurchaseP7d: 0,
        ctr: 0,
        vtr: 0,
        cpc: 0,
        cpm: 0,
        cpv: 0,
        cpi: 0,
        cpr: 0,
        mor: 0,
        mcr: 0,
        cpms: 0,
        cpmo: 0,
        cpmc: 0,
        cpckpa: 0,
        msgOpenRate: 0,
        msgClickRate: 0,

        cvr: 0,
        roas: 0,
    };

    const entries = Object.entries(total);
    data.forEach((v)=>{
        const tmp:Map<string,number> = new Map(Object.entries(v));
        entries.forEach((v2)=>{
            if(v2[0]==='key'){return; }
            v2[1] += tmp.get(v2[0]) || 0;
        });
    });

    return StatDataExtends({...total, ...Object.fromEntries(entries)});
}

export const AppendStatReportTotal = (data:StatReportResType[]):StatReportResType[] => {
    const total:StatReportResType = getStatReportTotal(data);
    data.push(total);
    return data;
}


/** Explorer 키생성용 - campaign, adgroup*/
export const getExplorerValue = (value?: string, row?:AdGroupResType|CampaignResType|AdResType, dimensionType?:string):string|undefined=>{
    switch(dimensionType){
        case KakaoMomentCode.data.options.dimensionType.device.value :          return `${dimensionType}-${value}-${(row as CampaignResType)?.device}`;
        case KakaoMomentCode.data.options.dimensionType.placement.value :       return `${dimensionType}-${value}-${(row as CampaignResType)?.placement}`;
        case KakaoMomentCode.data.options.dimensionType.creative_format.value : return `${dimensionType}-${value}-${(row as CampaignResType)?.creativeFormat}`;
        case KakaoMomentCode.data.options.dimensionType.location.value :        return `${dimensionType}-${value}-${(row as CampaignResType)?.location}`;
    }
    return value;
}

/** Stat 키생성용 - campaign, adgroup */
export const getStatValue = (value?: string, row?:AdGroupResType|CampaignResType|AdResType, dimensionType?:string):string=>{
    switch(dimensionType){
        case KakaoMomentCode.data.options.dimensionType.device.value :          return `${value}|${(row as CampaignResType)?.device}`; //실적에 대문자로 쌓임
        case KakaoMomentCode.data.options.dimensionType.placement.value :       return `${value}|${(row as CampaignResType)?.placement}`;
        case KakaoMomentCode.data.options.dimensionType.creative_format.value : return `${value}|${(row as CampaignResType)?.creativeFormat}`;
        case KakaoMomentCode.data.options.dimensionType.location.value :        return `${value}|${(row as CampaignResType)?.location}`;
    }

    return value || "";
}