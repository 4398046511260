import React, { useEffect, useState } from 'react';
import { Carousel } from 'react-bootstrap';
import { AdminService } from '../../common/services';
import { BannerResType } from '../../common/services/models/admin/BannerTypes';
import { useUserContext } from '../../contexts/UserContext';
import AdminCode from '../../modules/code/AdminCode';


const Banner:React.FC<{}> = (props) => {
    const userContext = useUserContext();
    const [useData, setUseData] = useState<BannerResType[]>([]); //설정값

    useEffect(()=>{
        userContext.modalLoading.show();
        AdminService.banner.getBanner()
        .then((res)=>{
            setUseData(res.data.result || []);
        })
        .catch((e)=>{
            userContext.alertMessage.add({
                variant:"danger",
                title:"배너목록 조회 오류",
                body:`배너목록 조회 중 오류가 발생했습니다. ${AdminService.ErrorMessage(e)}`
            });
        })
        .finally(()=>{ userContext.modalLoading.hide(); });
    // eslint-disable-next-line
    },[]);

    const now:string = new Date().format('date');
    return (<Carousel slide={false} interval={5000} data-bs-theme='dark' style={{height:'320px'}}>
        {useData?.filter((v)=>v.isUse //사용여부
            && (v.viewType===AdminCode.data.Assets.ViewType.AUTOMATIC_PERIOD.value  // 게시기간 미사용
                || (v.startDate<=now && v.endDate>=now) // 게시기간 내 여부
            )
        )
        .map((v,index)=><Carousel.Item key={index}>
            {v.isLandingUrl && <a target="_blank" href={v.landingUrl} rel="noopener noreferrer">
                <img className='card cursor-pointer' src={v.bannerImage}/>
            </a>}
            {!v.isLandingUrl && <img className='card' src={v.bannerImage} />}
            {/* <Carousel.Caption>{v.bannerImageFileName}</Carousel.Caption> */}
      </Carousel.Item>)}
    </Carousel>);
}

export default Banner;