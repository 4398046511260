import clsx from "clsx";
import { CSSProperties, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { OptionType } from "../../../../modules/types/Types";
import Code from "../../../modules/code/Code";
import AdploButton from "../../components/buttons/AdploButton";
import ReactSelect from "../../modules/select/ReactSelect";

interface DimensionSettingProps{
    variant?: string;
    className?: string;
    style?: CSSProperties;
    onChange?: (value:string[])=>void;
}

const DimensionSetting:React.FC<DimensionSettingProps> = (props)=>{
    const [show, setShow] = useState<boolean>(false);
    const [dimension, setDimension] = useState<string[]>([]);

    // 기본 또는 저장된 정보 반영
    useEffect(()=>{ props.onChange?.(Dimension.get()); }, []);

    useEffect(()=>{
        if(show){
            reset();
            props.onChange?.(Dimension.get());
        }
    }, [show]);

    const reset = ()=>{
        setDimension(Dimension.get());
    }

    const submit = ()=>{
        props.onChange?.(dimension);
        Dimension.set(dimension);
        setShow(false);
    }

    return <div>
        <AdploButton onClick={()=>setShow(!show)} data-type='icon'>
            <i className="bi fs-4 bi-gear p-0"/>
        </AdploButton>
        {show && <div className={clsx("fade modal-backdrop", {'show':show})}></div>}
        <div className={clsx('fade dropdown-menu p-6', {'show':show})} style={{transform:'translate(-585px,2px)', zIndex: 1069}}>
            <h6>지표 설정</h6>
            <div className='mt-6 mb-6 d-flex row w-600px'>
                {dimension.map((v,index) => <div key={index} className="col-4 mb-6">
                    <ReactSelect
                        className='d-inline-block w-100'
                        placeholder='지표 선택'
                        value={Dimension.getOptions().find(v2=>v2.value===v)}
                        options={Dimension.getOptions()}
                        isSearchable={false}
                        onChange={(v)=>{setDimension((prev)=>{
                            prev[index]=v?.value || "";
                            return [...prev];
                        })}}
                    />
                </div>)}
            </div>

            <Container className="d-flex p-0">
                <Container className="justify-content-start p-0">
                    <AdploButton variant='secondary' onClick={()=>setShow(false)}>취소</AdploButton>
                </Container>
                <Container className="justify-content-end d-flex p-0">
                    <AdploButton variant='secondary' onClick={()=>reset()}>초기화</AdploButton>
                    <AdploButton variant="primary" className='ms-2' onClick={submit}>적용</AdploButton>
                </Container>
            </Container>
        </div>
    </div>
}
export default DimensionSetting;

/** 공통 지표 옵션 */
export class Dimension{
    private static _key="DIMENSION_SELECT";
    public static options = {
        impressions:    {value: "impressions",      label: "노출수"},
        clicks:         {value: "clicks",           label: "클릭수"},
        cost:           {value: "cost",             label: "비용"},
        purchase:       {value: "purchase",         label: "구매 전환수"},
        videoView:      {value: "videoView",        label: "조회수"},
        purchaseValue:  {value: "purchaseValue",    label: "구매 전환 매출"},
        ctr:            {value: "ctr",              label: "CTR"},
        cvr:            {value: "cvr",              label: "CVR(구매)"},
        vtr:            {value: "vtr",              label: "VTR"},
        roas:           {value: "ROAS",             label: "ROAS"},
        cpc:            {value: "cpc",              label: "CPC"},
        cpm:            {value: "cpm",              label: "CPM"},
        cpv:            {value: "cpv",              label: "CPV"},
    };
    public static getOptions = ():OptionType[] => Code.getOptions(this.options);

    public static get():string[]{
        const tmp = localStorage.getItem(this._key);
        if(!tmp){ return "impressions,clicks,cost,ctr,cpm,cpc".split(",")}
        return JSON.parse(tmp);
    }
    public static set(value:string[]){
        localStorage.setItem(this._key, JSON.stringify(value));
    }
}