import { AxiosResponse } from 'axios';
import { Dispatch, SetStateAction } from 'react';
import { KakaoMomentServer } from '../../axios';
import UserSelect from '../../helpers/UserSelect';
import Utils from '../../utils/Utils';
import { callBackDownload } from '../helpers/Download';
import { RequestFilterType, RequestSearchType } from '../models/RequestTypes';
import { MediaResponseType } from '../models/ResponseTypes';

/** NAVER NOSP - Helper */
export default class HelperComponent{
    static path="";
    static stat="";
    static performances="";
    static simple="";
    static downloadPath="";

    /** GET 방식 Stat 로딩 */
    static getStat<T=any>(ids:string[], params?:{[key:string]:any}, setLoading?:Dispatch<SetStateAction<boolean>>, path?:string){
        const dateRange = UserSelect.dateRange.get();
        const lists = {
            startDate: dateRange?.startDate?.replaceAll("-", ""),   // "20230501",
            endDate: dateRange?.endDate?.replaceAll("-", ""),      //"20230530",
            ids: ids,
            ...params,
        };

        setLoading && setLoading(true);
        return KakaoMomentServer.get<MediaResponseType<T>>(path || this.stat, { params:lists, headers:{useAccount:true} })
            .finally(() => { setLoading && setLoading(false); });
    }

    /** POST 방식 Stat 로딩 - 대량 아이디용 */
    static postStat<T=any>(ids:string[], filters?:RequestFilterType[], setLoading?:Dispatch<SetStateAction<boolean>>, path?:string){
        const dateRange = UserSelect.dateRange.get();
        const body = {
            startDate: dateRange?.startDate?.replaceAll("-", ""),   // "20230501",
            endDate: dateRange?.endDate?.replaceAll("-", ""),      //"20230530",
            ids: ids,
        };

        setLoading && setLoading(true);
        return KakaoMomentServer.post<MediaResponseType<T>>(path || this.stat, body, {headers:{useAccount:true} })
            .finally(() => { setLoading && setLoading(false); });
    }

    /** GET 방식 Owner 기준 하위 Stat 데이터 로딩 */
    static getPerformances<T=any>(ownerId?:string, path?:string, params?:{[key:string]:any}){
        const dateRange = UserSelect.dateRange.get();
        const lists = {
            startDate: dateRange?.startDate?.replaceAll("-", ""),   // "20230501",
            endDate: dateRange?.endDate?.replaceAll("-", ""),      //"20230530",
            ownerId: ownerId,
            ...params,
        };
        return KakaoMomentServer.get<MediaResponseType<T>>(path || this.performances, { params:lists, headers:{useAccount:true} });
    }

    /** POST 방식 대량 Id에 대한 Stat 데이터 로딩 */
    static postPerformances<T=any>(ids:string[], path?:string){
        const dateRange = UserSelect.dateRange.get();
        const body = {
            startDate: dateRange?.startDate?.replaceAll("-", ""),   // "20230501",
            endDate: dateRange?.endDate?.replaceAll("-", ""),      //"20230530",
            ids: ids,
        };

        return KakaoMomentServer.post<MediaResponseType<T>>(path || this.performances, body, {headers:{useAccount:true} });
    }
    

    // 다운로드
    static downloadCall(url:string, filters:RequestFilterType, filename?:string, setLoading?:Dispatch<SetStateAction<boolean>>){
        setLoading && setLoading(true);
        const account = UserSelect.account.get();
        const magId = account?.accountGroup?.magId || "NONE";
        const name = url.split("/").pop();
        const fn = filename || `[adly-kakaomoment]${magId}_${name}.xlsx`;
        KakaoMomentServer.get(url,{ 
            responseType: 'blob',
            params:{useAccount:true, ...filters}, 
            headers:{"Content-Type":"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"},
            data:{},    //data 제거하면 설정한 Header 안넘어감.
        })
        .then((res:AxiosResponse)=>{ callBackDownload(res, fn); })
        .finally(() => {
            setLoading && setLoading(false);
            setTimeout(()=>{
                document.body.style.overflow = "";
                document.body.style.paddingRight = "";
            },500);
        });
    }

    // lockState 로딩
    static getLockState(ids:string[]){
        const params = {ids: ids};
        return Utils.apiRequestValidation({}) || KakaoMomentServer.get<MediaResponseType<{ids?:string[]}>>('/media/locked-resource-entities', { params:params, headers:{useAccount:true} });
    }

    //표준 목록조회
    public static getList<T=any>(params:RequestSearchType, path?:string){
        return Utils.apiRequestValidation({}) || KakaoMomentServer.get<MediaResponseType<T>>(path || this.path,{params:params, headers:{useAccount:true}});
    }

    //표준 Simple
    public static getSimpleList<T=any>(params?:RequestSearchType){
        return Utils.apiRequestValidation({}) || KakaoMomentServer.get<MediaResponseType<T>>(this.simple,{params:params, headers:{useAccount:true}});
    }

    //표준 상세조회
    public static get<T=any>(id:string){
        return Utils.apiRequestValidation({}) || KakaoMomentServer.get<MediaResponseType<T>>(`${this.path}/${id}`,{headers:{useAccount:true,}});
    }

    //표준 수정
    public static put<S=any>(body:S){
        return Utils.apiRequestValidation({}) || KakaoMomentServer.put(this.path, body, {headers:{useAccount:true}});
    }

    //표준 삭제
    public static delete<S=any>(body:S){
        return Utils.apiRequestValidation({}) || KakaoMomentServer.delete(this.path, {data:body, headers:{useAccount:true}});
    }

    //표준 다운로드
    public static download(filters:RequestFilterType, setLoading:Dispatch<SetStateAction<boolean>>){
        this.downloadCall(this.downloadPath,filters, undefined, setLoading);
    }

    //표준 추가
    public static post<S=any>(body:S, params?:any){
        return Utils.apiRequestValidation({}) || KakaoMomentServer.post<S>(this.path, body, {params:params, headers:{useAccount:true}});
    }
}

