import { toAbsoluteUrl } from "../../../../_metronic/helpers";
import CustomCard from "../../modules/card/CustomCard";

const ReportNone:React.FC = ()=><CustomCard className="text-center fs-3 fw-bolder text-gray-400 p-10">
    <div>보유한 광고주 관리 권한이 없습니다.</div>
    <img src={toAbsoluteUrl('/media/illustrations/sketchy-1/17.png')} alt="" className="mw-100 h-200px h-sm-325px"/>   
</CustomCard>

export default ReportNone;



