
import { StatReportResKeys, StatReportResType } from '../../../common/services/models/kakaomoment/StatReportType';
import { CompareSelectOption } from '../../../modules/code/Options';
import { statDefaultProps, StatFormatter } from '../../modules/StatFormatter';
import { FilterType, HeaderColumn } from '../../modules/table/TableType';

/** 카카오모먼트 커스텀 - 소수 세째자리표시 */
export class CustomStatFormatter{
    /** 기본값 정수 */
    static comma    (value?:number, row?:any, index?:number):string { return StatFormatter.comma(value, row, index); }
    /** 소수 두째자리 */
    static round    (value?:number, row?:any, index?:number):string { return StatFormatter.round(value, row, index, 3);  }
    /** 소수 두째자리까지 */
    static roundPer (value?:number, row?:any, index?:number):string { return StatFormatter.roundPer(value, row, index, 3);}
}


/**
 * Stat리포트 데이터 필요컬럼 확장
 */
export const StatDataExtends = (row:StatReportResType):StatReportResType => {
    return {
        ...row,
        ctr:            StatFormatter.divide(row?.clicks,       row?.impressions,   100 )?.truncRound(3),
        vtr:            StatFormatter.divide(row?.videoView,    row?.impressions,   100 )?.truncRound(3),
        cpc:            StatFormatter.divide(row?.cost,         row?.clicks             )?.truncRound(3),
        cpi:            StatFormatter.divide(row?.cost,         row?.impressions        )?.truncRound(3),
        cpm:            StatFormatter.divide(row?.cost,         row?.impressions,   1000)?.truncRound(3),
        cpr:            StatFormatter.divide(row?.cost,         row?.reach              )?.truncRound(3),
        cpv:            StatFormatter.divide(row?.cost,         row?.videoView          )?.truncRound(3),
        msgOpenRate:    StatFormatter.divide(row?.messageOpen,  row?.messageSend,   100 )?.truncRound(3),
        msgClickRate:   StatFormatter.divide(row?.messageClick, row?.messageSend,   100 )?.truncRound(3),
        cpms:           StatFormatter.divide(row?.cost,         row?.messageSend        )?.truncRound(3),
        cpmo:           StatFormatter.divide(row?.cost,         row?.messageOpen        )?.truncRound(3),
        cpmc:           StatFormatter.divide(row?.cost,         row?.messageClick       )?.truncRound(3),
        cpckpa:         StatFormatter.divide(row?.cost,         row?.convKfPfAdd7d      )?.truncRound(3),

        // Explorer
        cvr :           StatFormatter.divide(row?.convPurchase7d, row?.clicks           ),
        roas :          StatFormatter.divide(row?.convPurchaseP7d,row?.clicks           ),
    }
}

export const OxfordColumns:HeaderColumn[] = [
    { ...statDefaultProps, accessor: StatReportResKeys.clicks,              header: '클릭수',       unit:'회', },
    { ...statDefaultProps, accessor: StatReportResKeys.impressions,         header: '노출수',       unit:'회', },
    { ...statDefaultProps, accessor: StatReportResKeys.reach,               header: '도달수',       unit:'회', },
    { ...statDefaultProps, accessor: StatReportResKeys.cost,                header: '비용',         unit:'원', },
    { ...statDefaultProps, accessor: StatReportResKeys.videoView,           header: '재생수',         unit:'회', },
    { ...statDefaultProps, group:{key:"videoPlay", header:"동영상 재생수"}, accessor: StatReportResKeys.videoPlay10s,  header: '10초', unit:'회', },
    { ...statDefaultProps, group:{key:"videoPlay", header:"동영상 재생수"}, accessor: StatReportResKeys.videoPlay15s,  header: '15초', unit:'회', },
    { ...statDefaultProps, group:{key:"videoPlay", header:"동영상 재생수"}, accessor: StatReportResKeys.videoPlay30s,  header: '30초', unit:'회', },
    { ...statDefaultProps, group:{key:"videoPlay", header:"동영상 재생수"}, accessor: StatReportResKeys.videoPlay60s,  header: '60초', unit:'회', },
    { ...statDefaultProps, group:{key:"videoView", header:"동영상 재생"}, accessor: StatReportResKeys.videoView25,   header: '25%', unit:'회', },
    { ...statDefaultProps, group:{key:"videoView", header:"동영상 재생"}, accessor: StatReportResKeys.videoView50,   header: '50%', unit:'회', },
    { ...statDefaultProps, group:{key:"videoView", header:"동영상 재생"}, accessor: StatReportResKeys.videoView75,   header: '75%', unit:'회', },
    { ...statDefaultProps, group:{key:"videoView", header:"동영상 재생"}, accessor: StatReportResKeys.videoView100,  header: '100%', unit:'회', },
    { ...statDefaultProps, accessor: StatReportResKeys.videoPlayAuto,       header: '자동 재생수',         unit:'회', },
    { ...statDefaultProps, accessor: StatReportResKeys.videoPlayTouch,      header: '터치 재생수',         unit:'회', },
    { ...statDefaultProps, accessor: StatReportResKeys.videoUnmute,         header: '사운드 ON수',         unit:'회', },

    { ...statDefaultProps, accessor: StatReportResKeys.ctr,                 header: '클릭률(CTR)',         unit:'%', formatter:CustomStatFormatter.roundPer },
    { ...statDefaultProps, accessor: StatReportResKeys.vtr,                 header: '재생율(VTR)',         unit:'%', formatter:CustomStatFormatter.roundPer},
    { ...statDefaultProps, accessor: StatReportResKeys.cpc,                 header: '클릭당 비용(CPC)',    unit:'원', formatter:CustomStatFormatter.round},
    { ...statDefaultProps, accessor: StatReportResKeys.cpi,                 header: '노출당 비용',         unit:'원', formatter:CustomStatFormatter.round},
    { ...statDefaultProps, accessor: StatReportResKeys.cpm,                 header: '1,000회 노출당 비용(CPM)',unit:'원', formatter:CustomStatFormatter.round},
    { ...statDefaultProps, accessor: StatReportResKeys.cpr,                 header: '도달당 비용',         unit:'원', formatter:CustomStatFormatter.round},
    { ...statDefaultProps, accessor: StatReportResKeys.cpv,                 header: '조회당 비용(CPV)',         unit:'원', formatter:CustomStatFormatter.round},

    { ...statDefaultProps, accessor: StatReportResKeys.messageSend,         header: '메시지 발송수',   unit:'회', },
    { ...statDefaultProps, accessor: StatReportResKeys.messageOpen,         header: '메시지 열람수',   unit:'회', },
    { ...statDefaultProps, accessor: StatReportResKeys.messageClick,        header: '메시지 클릭수',   unit:'회', },
    { ...statDefaultProps, accessor: StatReportResKeys.messageClickShare,   header: '공유 버튼 클릭수',   unit:'회', },
    { ...statDefaultProps, accessor: StatReportResKeys.messageClickMedia,   header: '이미지/동영상 클릭수',   unit:'회', },
    { ...statDefaultProps, accessor: StatReportResKeys.messageClickTitle,   header: '타이틀 클릭수',   unit:'회', },
    { ...statDefaultProps, accessor: StatReportResKeys.messageClickText,    header: '텍스트 클릭수',   unit:'회', },
    { ...statDefaultProps, accessor: StatReportResKeys.messageClickList1,   header: '리스트1 클릭수',   unit:'회', },
    { ...statDefaultProps, accessor: StatReportResKeys.messageClickList2,   header: '리스트2 클릭수',   unit:'회', },
    { ...statDefaultProps, accessor: StatReportResKeys.messageClickList3,   header: '리스트3 클릭수',   unit:'회', },
    { ...statDefaultProps, accessor: StatReportResKeys.messageClickList4,   header: '리스트4 클릭수',   unit:'회', },
    { ...statDefaultProps, accessor: StatReportResKeys.messageClickButton1, header: '버튼1 클릭수',   unit:'회', },
    { ...statDefaultProps, accessor: StatReportResKeys.messageClickButton2, header: '버튼2 클릭수',   unit:'회', },
    { ...statDefaultProps, accessor: StatReportResKeys.messageClickOthers,  header: '기타 클릭수',   unit:'회', },

    { ...statDefaultProps, accessor: StatReportResKeys.msgOpenRate,         header: '열람률',         unit:'%', formatter:CustomStatFormatter.roundPer},
    { ...statDefaultProps, accessor: StatReportResKeys.msgClickRate,        header: '메시지 클릭률',    unit:'%', formatter:CustomStatFormatter.roundPer},
    { ...statDefaultProps, accessor: StatReportResKeys.cpms,                header: '발송당 비용',     unit:'원', formatter:CustomStatFormatter.comma },
    { ...statDefaultProps, accessor: StatReportResKeys.cpmo,                header: '열람당 비용',     unit:'원', formatter:CustomStatFormatter.round},
    { ...statDefaultProps, accessor: StatReportResKeys.cpmc,                header: '전체 클릭당 비용', unit:'원', formatter:CustomStatFormatter.round},

    { ...statDefaultProps, accessor: StatReportResKeys.convKfPfAdd1d,       header: '친구 추가수(1일)',   unit:'회', },
    { ...statDefaultProps, accessor: StatReportResKeys.convKfPfAdd7d,       header: '친구 추가수(7일)',   unit:'회', },
    { ...statDefaultProps, accessor: StatReportResKeys.convCmptReg1d,       header: '가입 완료(1일)',   unit:'회', },
    { ...statDefaultProps, accessor: StatReportResKeys.convCmptReg7d,       header: '가입 완료(7일)',   unit:'회', },
    { ...statDefaultProps, accessor: StatReportResKeys.convViewCart1d,      header: '장바구니 보기(1일)',   unit:'회', },
    { ...statDefaultProps, accessor: StatReportResKeys.convViewCart7d,      header: '장바구니 보기(7일)',   unit:'회', },
    { ...statDefaultProps, accessor: StatReportResKeys.convPurchase1d,      header: '구매(1일)',   unit:'회', },
    { ...statDefaultProps, accessor: StatReportResKeys.convPurchase7d,      header: '구매(7일)',   unit:'회', },
    { ...statDefaultProps, accessor: StatReportResKeys.convParticipation1d, header: '잠재고객(1일)',   unit:'회', },
    { ...statDefaultProps, accessor: StatReportResKeys.convParticipation7d, header: '잠재고객(7일)',   unit:'회', },
    { ...statDefaultProps, accessor: StatReportResKeys.convSignUp1d,        header: '서비스 신청(1일)',   unit:'회', },
    { ...statDefaultProps, accessor: StatReportResKeys.convSignUp7d,        header: '서비스 신청(7일)',   unit:'회', },
    { ...statDefaultProps, accessor: StatReportResKeys.convAppInstall1d,    header: '앱 설치(1일)',   unit:'회', },
    { ...statDefaultProps, accessor: StatReportResKeys.convAppInstall7d,    header: '앱 설치(7일)',   unit:'회', },
    { ...statDefaultProps, accessor: StatReportResKeys.convPurchaseP1d,     header: '구매 금액(1일)',   unit:'원', },
    { ...statDefaultProps, accessor: StatReportResKeys.convPurchaseP7d,     header: '구매 금액(7일)',   unit:'원', },
    { ...statDefaultProps, accessor: StatReportResKeys.cpckpa,              header: '친구 추가 수당 비용(7일)',   unit:'원', },
];

export const ExplorerOxfordColumns:HeaderColumn[] = [
    { ...statDefaultProps, accessor: StatReportResKeys.cvr,                 header: '전환율(구매(7일))',       unit:'%', formatter: StatFormatter.roundPer},
    { ...statDefaultProps, accessor: StatReportResKeys.roas,                header: 'ROAS',                 unit:'%', formatter: StatFormatter.commaPer},
];


/** 네이버 로컬모드(Tree, Label)용 실적컬럼 */
export const LocalOxfordColumns:HeaderColumn[] = [
    ...OxfordColumns.map((v):HeaderColumn=>({...v, filter:{type:FilterType.number, options:CompareSelectOption }, useSort:true,}))
];

