import React from "react";
import { KTSVG } from "../../../_metronic/helpers";
// import { Button } from "react-bootstrap";
// import { ExplorerBackContext } from "../../contexts/ExplorerBackContext";

const ExplorerHeader:React.FC = ()=>{
    // const useExplorerBackContext = useContext(ExplorerBackContext);
    return <div className='card-header' id='kt_explore_header'>
        <h5 className='card-title fw-bold text-gray-600'>
            {/* <Button variant="light"
                onClick={()=>{useExplorerBackContext.onClick && useExplorerBackContext.onClick()}}
                className='p-1 fw-bold me-1'
            ><i className="bi bi-chevron-left" /></Button> */}
            adly Explorer
        </h5>

        <div className='card-toolbar'>
            <button
                type='button'
                id='kt_engage_explorer_close'
                className='btn btn-sm btn-icon explore-btn-dismiss me-n5'
            >
                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-2' />
            </button>
        </div>
    </div>
}

export default ExplorerHeader;