
import { StatReportResKeys, StatReportResType } from '../../../common/services/models/nosp/StatReportType';
import { CompareSelectOption } from '../../../modules/code/Options';
import { statDefaultProps, StatFormatter } from '../../modules/StatFormatter';
import { FilterType, HeaderColumn } from '../../modules/table/TableType';

// type : select, mSelect, selectList, selectText, selectDate, text

/**
 * Stat리포트 데이터 필요컬럼 확장
 */
export const StatDataExtends = (row:StatReportResType):StatReportResType => {
    return {
        ...row,
        // costMoney: (row.costMoney * 1.1)?.round(), Tree 모드만 왜 부가세 추가했는지 모름
        ctr: StatFormatter.divide(row?.clicks,    row?.impressions,     100 )?.truncRound(2),
        vtr: StatFormatter.divide(row?.videoView, row?.impressions,     100 )?.truncRound(2),
        cpc: StatFormatter.divide(row?.costMoney, row?.clicks               )?.truncRound(),
        cpm: StatFormatter.divide(row?.costMoney, row?.impressions,     1000)?.truncRound(),
        cpv: StatFormatter.divide(row?.costMoney, row?.videoView            )?.truncRound(),
    }
}

export const NospOxfordColumns:HeaderColumn[] = [
    { ...statDefaultProps, accessor: StatReportResKeys.costMoney,       header: '집행금액',         unit:'원', },
    // { ...statDefaultProps, accessor: StatReportResKeys.costMoney,       header: '광고비',           unit:'회', },
    { ...statDefaultProps, accessor: StatReportResKeys.impressions,     header: '노출수',           unit:'회', },
    { ...statDefaultProps, accessor: StatReportResKeys.clicks,          header: '클릭수',           unit:'회', },
    { ...statDefaultProps, accessor: StatReportResKeys.landingClicks,   header: '랜딩 클릭수',      unit:'회', },
    { ...statDefaultProps, accessor: StatReportResKeys.interactionsClicks,header: '반응 클릭수',    unit:'회', },
    
    { ...statDefaultProps, accessor: StatReportResKeys.ctr,             header: '클릭률(CTR)',      unit:'%', formatter: StatFormatter.roundPer },
    { ...statDefaultProps, accessor: StatReportResKeys.vtr,             header: '조회율(VTR)',      unit:'%', formatter: StatFormatter.roundPer },
    { ...statDefaultProps, accessor: StatReportResKeys.cpc,             header: '클릭당 비용(CPC)',   unit:'원', },
    { ...statDefaultProps, accessor: StatReportResKeys.cpm,             header: '1,000회 노출당 비용(CPM)',   unit:'원', },
    { ...statDefaultProps, accessor: StatReportResKeys.cpv,             header: '조회당 비용(CPV)',   unit:'원', },

    { ...statDefaultProps, accessor: StatReportResKeys.videoView,       header: '동영상 조회수',       unit:'회', },
    { ...statDefaultProps, group:{key:"videoPlay", header:"동영상 재생수"}, accessor: StatReportResKeys.videoView25,     header: '25%',    unit:'회', },
    { ...statDefaultProps, group:{key:"videoPlay", header:"동영상 재생수"}, accessor: StatReportResKeys.videoView50,     header: '50%',    unit:'회', },
    { ...statDefaultProps, group:{key:"videoPlay", header:"동영상 재생수"}, accessor: StatReportResKeys.videoView75,     header: '75%',    unit:'회', },
    { ...statDefaultProps, group:{key:"videoPlay", header:"동영상 재생수"}, accessor: StatReportResKeys.videoView100,    header: '100%',   unit:'회', },
    { ...statDefaultProps, accessor: StatReportResKeys.videoPlay3s,     header: '동영상 3초 재생수',    unit:'회', },
    { ...statDefaultProps, accessor: StatReportResKeys.videoPlay10s,    header: '동영상 10초 재생수',   unit:'회', },
    
];


/** 네이버 로컬모드(Tree, Label)용 실적컬럼 */
export const LocalNospOxfordColumns:HeaderColumn[] = [
    ...NospOxfordColumns.map((v):HeaderColumn=>{
        return v.accessor===StatReportResKeys.costMoney
        ? {...v, filter:{type:FilterType.number, options:CompareSelectOption }, useSort:true}  //비용 부가세 처리
        : {...v, filter:{type:FilterType.number, options:CompareSelectOption }, useSort:true,}
    })
];

