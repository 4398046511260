import {CSSProperties, useEffect, useRef, useState} from 'react';
import {Modal, Spinner} from 'react-bootstrap';
import style from './LoadingModal.module.scss';

interface LodingProps {
    isShow: boolean;
}

export interface LoadginModalHandler {
    count: number;
    /**
     * 로딩 표시
     * @returns
     */
    show: () => void;
    /**
     * 로딩 완료
     * @returns
     */
    hide: () => void;
    /**
     * 로딩 표시 모두 삭제
     * @returns
     */
    clear: () => void;
}
export const UseLoadingModal = (): LoadginModalHandler => {
    const [data, setData] = useState<number>(0);
    const show = () => {
        setData((prev) => ++prev);
    };
    const hide = () => {
        setData((prev) => {
            --prev;
            return prev < 0 ? 0 : prev;
        });
    };
    const clear = () => {
        setData(0);
    };
    return {count: data, show, hide, clear};
};

function LoadingModal(props: LodingProps) {
    return (
        <Modal show={props.isShow} className={style.loading} dialogClassName={style.loading_dialog}>
            <LoadingLayer className={style.overlay_box} />
        </Modal>
    );
}

export const LoadingLayer: React.FC<{
    className?: string;
    style?: CSSProperties;
    variant?: string;
}> = (props) => (
    <div className={props.className} style={props.style}>
        <Spinner animation='border' variant={props.variant || 'primary'} role='status' />
        <TextSlide
            width={100}
            style={{
                marginTop: '1em',
                marginLeft: '-18px',
                color: 'var(--bs-gray-600)',
                fontWeight: 'bold',
            }}
        >
            Loading......
        </TextSlide>
    </div>
);

function TextSlide(props: {
    width: number;
    children?: string | React.ReactNode;
    style?: React.CSSProperties;
}) {
    const [width, setWidth] = useState<string>('0px');
    const animationRef = useRef<number | null>(null);
    const progressRef = useRef<number>(0);

    useEffect(() => {
        const animate = () => {
            progressRef.current += 1; // 증가 속도 조절 가능 (현재는 1씩 증가)
            if (progressRef.current > props.width + 50) {
                progressRef.current = 0; // 초기화
            }
            setWidth(`${progressRef.current}px`);
            animationRef.current = requestAnimationFrame(animate);
        };

        animationRef.current = requestAnimationFrame(animate);

        return () => {
            if (animationRef.current !== null) {
                cancelAnimationFrame(animationRef.current);
            }
        };
    }, [props.width]);

    return (
        <p
            className='text-nowrap text-left'
            style={{
                overflow: 'hidden',
                width: width,
                maxWidth: `${props.width}px`,
                ...props.style,
            }}
        >
            {props.children}
        </p>
    );
}

// function LoadingContainer(props:LodingProps) {
//     return (
//         <div style={{display:props.isShow ? "" : "none"}} className={`text-center align-middle align-items-center ${style.loading_container}`}>
//             <div className="text-center align-middle align-items-center" style={{border:"1px solid red", marginTop:"200px"}}>
//                 <Spinner animation="border" variant="primary" role='status' size='sm' />
//                 <p style={{ marginTop: "40px", marginLeft: "-18px", color: "rgb(1B1D1D)", fontWeight: "bold" }}>Loading...</p>
//             </div>
//         </div>
//     );
// }

export default LoadingModal;
// export {
//     LoadingContainer,
// }
