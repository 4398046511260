
/** 카카오모먼트 리포트 결과 배열타입 */
export interface StatReportResultArrayType<T=StatReportResType, V=StatReportInfoType> {
    info: V;
    performance: T;
}

/** 카카오모먼트 리포트 데이터 결과 타입 */
export interface StatReportResultType<T=StatReportResType> {
    info?:StatReportInfoType;
    total?: T[];
    daily?:T[];
    performances?:T[];
}

export interface StatReportInfoType{
    adId?: string;
    adName?: string;
    adGroupId?: string;
    adGroupName?: string;
    adGroupType?: string;
    adGroupTypeName?: string;
    bidAmount?: number;
    bidStrategy?: string;
    bidStrategyName?: string;
    campaignId?: string;
    campaignName?: string;
    createdDatetime?: string;
    creativeFormat?: string;
    dailyBudget?: number;
    pricingName?: string;
    lifetimeBudget?: number;
    device?: string;
    location?: string;
    placement?: string;
}



export interface StatReportResType{
    key: string;
    clicks: number;
    impressions: number;
    reach: number;
    cost: number;
    videoView: number;
    videoView25: number;
    videoView50: number;
    videoView75: number;
    videoView100: number;
    videoPlay3s: number;
    videoPlay10s: number;
    videoPlay15s: number;
    videoPlay30s: number;
    videoPlay60s: number;
    videoPlayAuto: number;
    videoPlayTouch: number;
    videoUnmute: number;
    messageSend: number;
    messageOpen: number;
    messageClick: number;
    messageClickShare: number;
    messageClickMedia: number;
    messageClickTitle: number;
    messageClickText: number;
    messageClickList1: number;
    messageClickList2: number;
    messageClickList3: number;
    messageClickList4: number;
    messageClickButton1: number;
    messageClickButton2: number;
    messageClickOthers: number;
    convKfPfAdd1d: number;
    convKfPfAdd7d: number;
    convCmptReg1d: number;
    convCmptReg7d: number;
    convViewCart1d: number;
    convViewCart7d: number;
    convPurchase1d: number;
    convPurchase7d: number;
    convParticipation1d: number;
    convParticipation7d: number;
    convSignUp1d: number;
    convSignUp7d: number;
    convAppInstall1d: number;
    convAppInstall7d: number;
    convPurchaseP1d: number;
    convPurchaseP7d: number;
    ctr: number;
    vtr: number;
    cpc: number;
    cpm: number;
    cpv: number;
    cpi: number;
    cpr: number;
    mor: number;
    mcr: number;
    cpms: number;
    cpmo: number;
    cpmc: number;
    cpckpa: number; 

    msgOpenRate?: number;  
    msgClickRate?: number;

    cvr: number; 
    roas: number; 
}

export enum StatReportResKeys{
    key = 'key',
    clicks = 'clicks',
    impressions = 'impressions',
    reach = 'reach',
    cost = 'cost',
    videoView = 'videoView',
    videoView25 = 'videoView25',
    videoView50 = 'videoView50',
    videoView75 = 'videoView75',
    videoView100 = 'videoView100',
    videoPlay3s = 'videoPlay3s',
    videoPlay10s = 'videoPlay10s',
    videoPlay15s = 'videoPlay15s',
    videoPlay30s = 'videoPlay30s',
    videoPlay60s = 'videoPlay60s',
    videoPlayAuto = 'videoPlayAuto',
    videoPlayTouch = 'videoPlayTouch',
    videoUnmute = 'videoUnmute',
    messageSend = 'messageSend',
    messageOpen = 'messageOpen',
    messageClick = 'messageClick',
    messageClickShare = 'messageClickShare',
    messageClickMedia = 'messageClickMedia',
    messageClickTitle = 'messageClickTitle',
    messageClickText = 'messageClickText',
    messageClickList1 = 'messageClickList1',
    messageClickList2 = 'messageClickList2',
    messageClickList3 = 'messageClickList3',
    messageClickList4 = 'messageClickList4',
    messageClickButton1 = 'messageClickButton1',
    messageClickButton2 = 'messageClickButton2',
    messageClickOthers = 'messageClickOthers',
    convKfPfAdd1d = 'convKfPfAdd1d',
    convKfPfAdd7d = 'convKfPfAdd7d',
    convCmptReg1d = 'convCmptReg1d',
    convCmptReg7d = 'convCmptReg7d',
    convViewCart1d = 'convViewCart1d',
    convViewCart7d = 'convViewCart7d',
    convPurchase1d = 'convPurchase1d',
    convPurchase7d = 'convPurchase7d',
    convParticipation1d = 'convParticipation1d',
    convParticipation7d = 'convParticipation7d',
    convSignUp1d = 'convSignUp1d',
    convSignUp7d = 'convSignUp7d',
    convAppInstall1d = 'convAppInstall1d',
    convAppInstall7d = 'convAppInstall7d',
    convPurchaseP1d = 'convPurchaseP1d',
    convPurchaseP7d = 'convPurchaseP7d',
    ctr = 'ctr',
    vtr = 'vtr',
    cpc = 'cpc',
    cpm = 'cpm',
    cpv = 'cpv',
    cpi = 'cpi',
    cpr = 'cpr',
    mor = 'mor',
    mcr = 'mcr',
    cpms = 'cpms',
    cpmo = 'cpmo',
    cpmc = 'cpmc',
    cpckpa = 'cpckpa',
    
    msgOpenRate = "msgOpenRate",
    msgClickRate = "msgClickRate",

    explorer = 'explorer',

    cvr = 'cvr',
    roas = 'roas',
}


export interface ExplorerDetailReqType{
    startDate: string;
    endDate: string;
    dimension?: string;
    searchDimensionWord?: string;
}

/** 네이버 실적데이터 대량 아이디에 대한 리포트 호출 쿼리타입 */
export interface ExplorerPostReqType extends ExplorerDetailReqType{
    ids: string[];
}
export interface ExplorerReqType{
    from: number,
    size: number,
    searchWord: string,
    startDate: string,
    endDate: string
}