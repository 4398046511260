import clsx from 'clsx';
import styles from './style.module.scss';

const MoreButton:React.FC<{onClick?:()=>void}> = (props)=>{

    return <div className={clsx('w-100 text-center', styles.more_button)} onClick={props.onClick}>
        <i className="bi bi-chevron-down"/> 더보기
    </div>
}

export default MoreButton;