import { AdResourceType, KeywordResourceType } from "./CreativeTypes";


/** 리포트 결과 배열타입 */
export interface StatReportResultArrayType<T=StatReportResType, V=StatReportInfoType> {
    info: V;
    performance: T;
}

/**  리포트 결과 타입 */
export interface StatReportResultType<T=StatReportResType> {
    info?: StatReportInfoType;
    total?: T[];
    daily?: T[];
    performances?: T[];
}

/** Explorer 상세보기 타입 */
export interface StatReportInfoType{
    campaignId?: string;
    campaignName?: string;
    advertiserName?: string;
    brandName?: string;
    startTime?: string;
    stopTime?: string;
    /** 캠페인 구매 금액 */
    purchaseMoney?: number;
    /** 캠페인 계약 금액 */
    contractMoney?: number;
    /** 캠페인 서비스 금액 */
    serviceMoney?: number;
    totalCostMoney?: number;
    createdDatetime?: string;
    hour?: string;
    placement?: string;

    // AdGroup
    adGroupId?: string;
    productId?: string;
    productName?: string;
    productTypeId?: string;
    productTypeName?: string;
    pricingName?: string;
    calculPurchaseMoneyAvg?: number;
    commissionRate?: number;

    // Creative
    creativeId?: string;
    adCount?: number;
    adResources?: AdResourceType[];
    keywordCount?: number;
    keywordResources?: KeywordResourceType[];
    
    // Ad
    adId?: string;
    calculImpressions?: number;

    // Keyword
    keywordName?: string;
    keywordId?: string;
    celement?: string;
}

/** 리포트 데이터 타입, key - entry ID or Date */
export interface StatReportResType{
    key: string;
    costMoney: number;
    impressions: number;
    clicks: number;
    landingClicks: number;
    interactionsClicks: number;
    videoView: number;
    videoPlay3s: number;
    videoPlay10s: number;
    videoView25: number;
    videoView50: number;
    videoView75: number;
    videoView100: number;

    ctr: number;
    vtr: number;
    cpc: number;
    cpm: number;
    cpv: number;
}

/**
 * 리포트 컬럼 처리용 키
 */
export enum StatReportResKeys{
    key                 = "key",
    costMoney           = "costMoney",
    impressions         = "impressions",
    clicks              = "clicks",
    landingClicks       = "landingClicks",
    interactionsClicks  = "interactionsClicks",
    videoView           = "videoView",
    videoPlay3s         = "videoPlay3s",
    videoPlay10s        = "videoPlay10s",
    videoView25         = "videoView25",
    videoView50         = "videoView50",
    videoView75         = "videoView75",
    videoView100        = "videoView100",

    ctr                 = "ctr",
    vtr                 = "vtr",
    cpc                 = "cpc",
    cpm                 = "cpm",
    cpv                 = "cpv",

    explorer            = "explorer",
}


export interface ExplorerDetailReqType{
    startDate: string;
    endDate: string;
    dimension?: string;
    searchDimensionWord?: string;
}

/** 네이버 실적데이터 대량 아이디에 대한 리포트 호출 쿼리타입 */
export interface ExplorerPostReqType extends ExplorerDetailReqType{
    ids: string[];
}
export interface ExplorerReqType{
    from: number,
    size: number,
    searchWord: string,
    startDate: string,
    endDate: string
}