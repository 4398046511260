import Code from "./Code";

export default class GfaCode extends Code{
    public static readonly data = {

        ////옵션선택용 //
        options:{
            ExplorerLevel:{
                campaign:   {value:'campaigns',     label:'캠페인'},
                adGroup:    {value:'ad-groups',     label:'광고그룹'},
                ad:         {value:'ads',           label:'광고소재'},
            },
        },

        assets : {
        },

        type:{
            pricingType:{
                CPC: {value:"CPC",  label:"CPC" },
                CPM: {value:"CPM",  label:"CPM" },
                CPV: {value:"CPV",  label:"CPV" },
            },

            /** 캠페인 목적 */
            objectiveType : {
                conversion          : {value : 'CONVERSION',        label : '웹사이트 전환'},
                web_site_traffic    : {value : 'WEB_SITE_TRAFFIC',  label : '인지도 및 트래픽'},
                install_app         : {value : 'INSTALL_APP',       label : '앱 전환'},
                shopping            : {value : 'SHOPPING',          label : '쇼핑 프로모션'},
                watch_video         : {value : 'WATCH_VIDEO',       label : '동영상 조회'},
                catalog             : {value : 'CATALOG',           label : '카탈로그 판매'},
                lead                : {value : 'LEAD',              label : '참여 유도'},
                null                : {value : 'null',              label : '알 수 없음'},
            },

            /** 다운로드 Entity 타입 */
            downlaodEntityType: {
                // all: {value:"NGDT0001", label:"전체데이터"},
                // all: {value:"NGDT0002", label:"리포트"},
                // all: {value:"NGPT0001", label:"네이버 성과형 DA"},
                all         : {value:"NGET0001", label:"전체",      data:{ path:'all' }},
                campaign    : {value:"NGET0002", label:"캠페인",     data:{ path:'campaign' }},
                adgroup     : {value:"NGET0003", label:"광고그룹",  data:{ path:'ad-group' }},
                ad          : {value:"NGET0004", label:"광고소재",      data:{ path:'ad' }},
            },

            /** 게제 위치 */
            placementType: {
                BAND            : {value : 'BAND',              label : '네이버 패밀리 매체 > 피드 영역'},
                F_BANNER        : {value : 'F_BANNER',          label : '네이버 패밀리 매체 > 배너 영역'},
                F_SMARTCHANNEL  : {value : 'F_SMARTCHANNEL',    label : '네이버 패밀리 매체 > 스마트채널'},
                M_BANNER        : {value : 'M_BANNER',          label : '네이버 > 배너 영역 > 서비스 통합'},
                M_FEED          : {value : 'M_FEED',            label : '네이버 > 피드 영역'},
                M_MAIN          : {value : 'M_MAIN',            label : '네이버 > 배너 영역 > 네이버 메인'},
                M_SMARTCHANNEL  : {value : 'M_SMARTCHANNEL',    label : '네이버 > 스마트채널'},
                NW_BANNER       : {value : 'NW_BANNER',         label : '네이버 퍼포먼스 네트워크 > 배너 영역'},
                NW_SMARTCHANNEL : {value : 'NW_SMARTCHANNEL',   label : '네이버 퍼포먼스 네트워크 > 스마트채널'},
                N_COMMUNICATION : {value : 'N_COMMUNICATION',   label : '네이버 > 커뮤니케이션 영역'},
                N_INSTREAM      : {value : 'N_INSTREAM',        label : '네이버 > 인스트림 영역'},
                N_SHOPPING      : {value : 'N_SHOPPING',        label : '네이버 > 쇼핑 영역'},
            },
            /** 소재타입 */
            creativeType : {
                single_image    : {value : 'SINGLE_IMAGE',      label : '네이티브 이미지'},
                multiple_image  : {value : 'MULTIPLE_IMAGE',    label : '이미지 슬라이드'},
                single_video    : {value : 'SINGLE_VIDEO',      label : '동영상'},
                image_banner    : {value : 'IMAGE_BANNER',      label : '이미지 배너'},
                catalog         : {value : 'CATALOG',           label : '카탈로그'},
            },
        },

    }

};

