import Code from "./Code";

export default class NaverCode extends Code{
    public static readonly data = {
        explorer:{
            bizchannel:{
                SITE            : {value : '1', label : '웹사이트'},
                PHONE           : {value : '2', label : '전화번호'},
                ADDRESS         : {value : '3', label : '위치정보'},
                BOOKING         : {value : '4', label : '네이버 예약'},
                TALK            : {value : '5', label : '네이버 톡톡'},
                MALL            : {value : '6', label : '쇼핑몰'},
                CONTENTS        : {value : '7', label : '콘텐츠'},
                PLACE           : {value : '8', label : '플레이스'},
                CATALOG         : {value : '9', label : '쇼핑 제조사'},
                NAVER_TV        : {value : '10', label : '네이버 TV'},
                BRAND_STORE     : {value : '11', label : '쇼핑브랜드스토어'},
                SHOPPING_BRAND  : {value : '12', label : '네이버 쇼핑 브랜드'},
                Unknown         : {value : '-1', label : 'Unknown'},
            },

            campaign : {
                WEB_SITE        : {value : '1',         label : '파워링크'},
                SHOPPING        : {value : '2',         label : '쇼핑검색'},
                POWER_CONTENTS  : {value : '3',         label : '파워컨텐츠'},
                INFORMATION     : {value : 'INFORMATION',label : '정보'},
                PRODUCT         : {value : 'PRODUCT',   label : '상품'},
                BRAND_SEARCH    : {value : '4',         label : '브랜드검색'},
                PLACE           : {value : '6',         label : '플레이스'},
                CATALOG         : {value : 'CATALOG',   label : '카탈로그'},
                Unknown         : {value : '-1',        label : 'Unknown'},
            },
            adgroup : {
                WEB_SITE        : {value : '1', label : '사이트'},
                SHOPPING        : {value : '2', label : '쇼핑몰 상품형'},
                INFORMATION     : {value : '3', label : '정보'},
                PRODUCT         : {value : '4', label : '상품'},
                BRAND_SEARCH    : {value : '5', label : '브랜드검색'},
                PLACE           : {value : '6', label : '플레이스'},
                CATALOG         : {value : '7', label : '제품 카탈로그형'},
                SHOPPING_ZONE   : {value : '8', label : '브랜드존'},
                SHOPPING_BRAND  : {value : '9', label : '쇼핑 브랜드형'},
                BRAND_NEW       : {value : '11',label : '신제품검색'},
                LOCAL_AD        : {value : '10',label : '플레이스 검색'},
                Unknown         : {value : '-1',label : 'Unknown'},
            },
            ad : {
                TEXT_45                 : {value : '1', label : '파워링크'},
                SHOPPING_PRODUCT_AD     : {value : '2', label : '쇼핑검색-쇼핑몰 상품형'},
                CATALOG_AD              : {value : '3', label : '쇼핑검색-제품 카탈로그형'},
                SHOPPING_BRAND_AD       : {value : '4', label : '쇼핑검색-라이트형'},
                SHOPPING_BRAND_IMAGE_THUMBNAIL_AD : {value : '5', label : '썸네일형'},
                SHOPPING_BRAND_IMAGE_BANNER_AD : {value : '6', label : '이미지 배너형'},
                CONTENTS_AD_PRODUCT     : {value : '7', label : '파워컨텐츠'},
                BRAND_SEARCH_AD         : {value : '8', label : '브랜드검색'},

                //미검증
                BRAND_SEARCH_NEW_AD     : {value : '9', label : '신제품검색'},
                CONTENTS_AD_INFORMATION : {value : '10', label : '파워컨텐츠'},
                LOCAL_AD                : {value : '11', label : '플레이스-플레이스검색'},
                PLACE_AD                : {value : '12', label : '플레이스/지역소상공인'},
                Unknown                 : {value : '-1', label : 'Unknown'},


                // BRAND_SEARCH_AD         : {value : '1', label : '브랜드검색'},
                // BRAND_SEARCH_NEW_AD     : {value : '2', label : '신제품검색'},
                // CATALOG_AD              : {value : '3', label : '쇼핑검색-제품 카탈로그형'},
                // CONTENTS_AD_INFORMATION : {value : '4', label : '파워컨텐츠'},
                // CONTENTS_AD_PRODUCT     : {value : '5', label : '파워컨텐츠'},
                // LOCAL_AD                : {value : '6', label : '플레이스-플레이스검색'},
                // PLACE_AD                : {value : '7', label : '플레이스/지역소상공인'},
                // SHOPPING_BRAND_AD       : {value : '8', label : '쇼핑검색-라이트형'},
                // SHOPPING_BRAND_IMAGE_BANNER_AD : {value : '9', label : '이미지 배너형'},
                // SHOPPING_BRAND_IMAGE_THUMBNAIL_AD : {value : '10', label : '썸네일형'},
                // SHOPPING_PRODUCT_AD     : {value : '11', label : '쇼핑검색-쇼핑몰 상품형'},
                // TEXT_45                 : {value : '12', label : '파워링크'},
                // Unknown                 : {value : '-1', label : 'Unknown'},
            },

            adExtension : {
                PHONE                   : {value : '1', label : '전화번호'},
                LOCATION                : {value : '2', label : '위치정보'},
                BOOKING                 : {value : '3', label : '네이버 예약'},
                TALK                    : {value : '4', label : '네이버 톡톡'},
                CALCULATION             : {value : '5', label : '계산'},
                HEADLINE                : {value : '6', label : '추가제목'},
                DESCRIPTION             : {value : '7', label : '홍보문구'},
                SUB_LINKS               : {value : '8', label : '서브링크'},
                PRICE_LINKS             : {value : '9', label : '가격링크'},
                PROMOTION               : {value : '10', label : '추가홍보문구'},
                SHOPPING_EXTRA          : {value : '11', label : '쇼핑 상품 부가 정보'},
                POWER_LINK_IMAGE        : {value : '12', label : '파워링크 이미지'},
                IMAGE_SUB_LINKS         : {value : '13', label : '이미지형 서브링크'},
                PLACE                   : {value : '14', label : '플레이스 정보'},
                CATALOG_RELEASE_DATE    : {value : '15', label : '카탈로그 신제품'},
                CATALOG_EXTRA           : {value : '16', label : '카탈로그 부가정보'},
                CATALOG_DETAIL          : {value : '17', label : '카탈로그 상세정보'},
                CATALOG_IMAGE           : {value : '18', label : '카탈로그 제품이미지'},
                CATALOG_PROMOTION       : {value : '19', label : '카탈로그 홍보문구'},
                CATALOG_EVENT           : {value : '20', label : '카탈로그 이벤트'},
                CATALOG_MOVIE           : {value : '21', label : '카탈로그 제품영상'},
                CATALOG_PURCHASE_CONDITION:{value: '22', label : '카탈로그 구매조건'},
                CATALOG_BRAND_MESSAGE   : {value : '23', label : '카탈로그 브랜드메시지'},
                NAVER_TV_VIDEO          : {value : '24', label : '홍보 영상'},
                NAVER_BLOG_REVIEW       : {value : '25', label : '블로그 리뷰'},
                SHOPPING_BRAND_IMAGE    : {value : '26', label : '쇼핑 브랜드 이미지'},
                SHOPPING_BRAND_VIDEO    : {value : '27', label : '쇼핑 브랜드 동영상'},
                SHOPPING_BRAND_BROADCAST: {value : '28', label : '쇼핑 브랜드 라이브'},
                SHOPPING_BRAND_EVENT    : {value : '29', label : '쇼핑 브랜드 이벤트'},
                PLACE_SMART_ORDER       : {value : '30', label : '플레이스 네이버 주문'},
                PLACE_BEAUTY_STYLE      : {value : '31', label : '플레이스 뷰티스타일'},
                DESCRIPTION_EXTRA       : {value : '32', label : '추가설명'},
                Unknown                 : {value : '-1', label : 'Unknown'},
            },

            keyword:{
                NORMAL      : {value: '0', label:"일반 키워드"},
                BRAND       : {value: '1', label:"내 브랜드 키워드"},
                OTHER_BRAND : {value: '2', label:"다른 브랜드 키워드"},
                Unknown     : {value:'-1', label:"알 수 없음"},
            }
        },

        Dbid:{
            /** 결과조회 옵션 - 입찰가 조정 | 키워드 OFF */
            proposal:{
                /** 입찰가 제안 */
                YES:{value:'YES', label:"YES"},
                /** 키워드 OFF 제안 */
                NO:{value:'NO', label:"NO"},
            }
        },

        ////옵션선택용 //
        options:{
            criteriaMode:{
                ALL: {value: 'NCM0001', label:'전체 타겟팅'},
                TARGET: {value: 'NCM0002', label:'부분 타겟팅'},
            },
            targetType:{
                MEDIA_TARGET                : {value : 'MEDIA_TARGET', label : '매체'},
                PC_MOBILE_TARGET            : {value : 'PC_MOBILE_TARGET', label : 'PC/모바일'},
                RESTRICT_KEYWORD_TARGET     : {value : 'RESTRICT_KEYWORD_TARGET', label : '제외 키워드'},
                NON_SEARCH_KEYWORD_TARGET   : {value : 'NON_SEARCH_KEYWORD_TARGET', label : 'NON_SEARCH_KEYWORD_TARGET'},
            },
            campaignType : {
                WEB_SITE : {value : 'WEB_SITE', label : '파워링크 유형'},
                SHOPPING : {value : 'SHOPPING', label : '쇼핑검색 유형'},
            },

            mediaTarget : { // value값은 네이버 고정값 - 수정 불가
                ALL : {value : '1', label : '모든 매체'},
                TYPE : {value : '2', label : '노출 매체 유형 선택'},
                // INDVD : {value : '3', label : '노출 매체 개별 선택'},
            },
            
            mediaTargetDevice: {
                ALL: {value: 'ALL', label: '전체'},
                PC : {value: 'PC', label: 'PC '},
                MOBILE: {value: 'MOBILE', label: 'MO '},
            },
            
            mediaTargetContent:{
                naver: {value:"naver", label:"네이버 매체"},
                partner: {value:"partner", label:"파트너 매체"},
            },
            
            mediaTargetSearch:{
                naver: {value:"naver", label:"네이버 및 검색 포털 매체"},
                partner: {value:"partner", label:"파트너 매체 "}, // Contents '파트너 매체'와 동일한 이름 사용 오류로 공백 추가
            },

            useCntsNetworkBidAmt:{
                NONE: {value:"none", label:"미설정"},
                AMOUNT: {value:"amount", label:"콘텐츠 입찰가(금액)"},
            },

            useContentBidAmountType:{
                NONE: {value:"none", label:"미설정"},
                AMOUNT: {value:"amount", label:"콘텐츠 입찰가(금액)"},
                WEIGHT: {value:"weight", label:"콘텐츠 입찰가(비율)"},
            },

            AdExposureLocation:{
                ALL: {value:"0", label:"모든 위치에 노출 가능"},
                "1": {value:"1", label:"위치 1에만 노출 가능"},
                "2": {value:"2", label:"위치 2에만 노출 가능"},
            },

            useScheduleRank:{
                YES:    {value:"true",   label:"개별설정"},
                NO:     {value:"false",    label:"개별설정 안 함"},
            },
            useScheduleOnOff:{
                YES:    {value:"true",   label:"스케쥴링 사용"},
                NO:     {value:"false",    label:"스케쥴링 사용 안 함"},
            },

            /** 확장소재 Owner 타입 */
            OwnerType:{
                campaign:   {value:"cmp",   label:"캠페인"},
                adgroup:    {value:"grp",   label:"광고그룹"},
                ad:         {value:"nad",   label:"소재"},
            },
            
            /*
            실적Level
            biz-channels
            campaigns
            ad-groups
            ads
            ad-extensions
            keywords
            creatives // 카카오
            creative-links // 카카오
            */
            ExplorerLevel:{
                bizChannel: {value:'biz-channels',  label:'비즈채널'},
                campaign:   {value:'campaigns',     label:'캠페인'},
                adGroup:    {value:'ad-groups',     label:'광고그룹'},
                ad:         {value:'ads',           label:'소재'},
                adExtension:{value:'ad-extensions', label:'확장소재'},
                keyword:    {value:'keywords',      label:'키워드'},
                searchKwd:  {value:'search-keywords',label:'검색어'},
                criterion:  {value:'criteria',      label:'타겟팅'},
            },
        },

        assets : {
            userLock : {
                false : {value : 'false', label : 'ON'},
                true : {value : 'true', label : 'OFF'},
            },
            deliveryMethod : {  //확인 필요
                STANDARD : {value : 'STANDARD', label : '균등노출'},
                ACCELERATED : {value : 'ACCELERATED', label : '일반노출'},
            },
            
            rollingType : {
                PERFORMANCE : {value : 'PERFORMANCE', label : '성과 기반 노출'},
                ROUND_ROBIN : {value : 'ROUND_ROBIN', label : '동일 비중 노출'},
            },

            criterion : {
                /** 연령대 */
                age : {value : 'AG', label : '연령대',   description:'AGE'},
                /** 성별 */
                gender : {value : 'GN', label : '성별',   description:'GENDER'},
                /** 지역 */
                regional : {value : 'RL', label : '지역',   description:'REGIONAL'},
                /** 요일/시간 */
                schedule : {value : 'SD', label : '요일/시간',   description:'SCHEDULE'},
                /** 이용자 세그먼트 */
                audience : {value : 'AD', label : '이용자 세그먼트',   description:'AUDIENCE'},
            },
            /** 크리테리온 유형 */
            criterionOption:{
                /** 광고노출 지역 설정 - negative 기준 : true|false*/
                region:{
                    /** 광고 노출 허용 지역 설정 */
                    accept:{value:"false", label:"광고 노출 지역 설정 (기본)"},
                    /** 광고 노출 제외 지역 설정 */
                    reject:{value:"true", label:"광고 노출 제외 지역 설정"},
                },

                gender:{
                    all:{value:'all', label:"모든 성별 (기본)"},
                    detail:{value:'detail', label:"노출 성별 선택"},
                },

                age:{
                    all:{value:'all', label:"모든 연령대 (기본)"},
                    detail:{value:'detail', label:"노출 연령대 선택"},
                },

                audience:{
                    all:{value:'all', label:"모든 이용자 (기본)"},
                    detail:{value:'detail', label:"이용자 세그먼트 선택"},
                },
                negative:{
                    false:{value:"false", label:"광고 노출"},
                    true:{value:"true", label:"노출 제외"},
                }
            },

            target : {
                AD_TAG                  : {value : 'AD_TAG',                    label : '광고 태그'},
                AGE_TARGET              : {value : 'AGE_TARGET',                label : '연령'},
                GENDER_TARGET           : {value : 'GENDER_TARGET',             label : '성별'},
                GENDER_WEIGHT_TARGET    : {value : 'GENDER_WEIGHT_TARGET',      label : '성별 가중치'},
                MEDIA_TARGET            : {value : 'MEDIA_TARGET',              label : '매체'},
                NON_SEARCH_KEYWORD_TARGET:{value : 'NON_SEARCH_KEYWORD_TARGET', label : '검색어 없음 광고노출 제외'},
                PC_MOBILE_TARGET        : {value : 'PC_MOBILE_TARGET',          label : 'PC/모바일'},
                PERIOD_TARGET           : {value : 'PERIOD_TARGET',             label : '기간'},
                PLACE_ADGROUP_TAG       : {value : 'PLACE_ADGROUP_TAG',         label : '광고 태그'},
                REGIONAL_TARGET         : {value : 'REGIONAL_TARGET',           label : '지역'},
                RESTRICT_KEYWORD_TARGET : {value : 'RESTRICT_KEYWORD_TARGET',   label : '제외키워드'},
                TIME_WEEKLY_TARGET      : {value : 'TIME_WEEKLY_TARGET',        label : '요일/시간'},
            },

            //추적기능
            trackingMode : {
                TRACKING_DISABLED : {value : 'TRACKING_DISABLED', label : '사용 안 함'},
                AUTO_TRACKING_MODE : {value : 'AUTO_TRACKING_MODE', label : '자동 추적 URL'},
                PASS_THROUGH_SITE_MODE : {value : 'PASS_THROUGH_SITE_MODE', label : '추적 경유 사이트'},
            },

            region:{
                "RL01": {value:"RL01", label:"강원특별자치도", order:1},
                "RL02": {value:"RL02", label:"경기도", order:2},
                "RL03": {value:"RL03", label:"경상남도", order:3},
                "RL04": {value:"RL04", label:"경상북도", order:4},
                "RL05": {value:"RL05", label:"광주광역시", order:5},
                "RL06": {value:"RL06", label:"대구광역시", order:6},
                "RL07": {value:"RL07", label:"대전광역시", order:7},
                "RL08": {value:"RL08", label:"부산광역시", order:8},
                "RL09": {value:"RL09", label:"서울특별시", order:9},
                "RL17": {value:"RL17", label:"세종특별자치시", order:10},
                "RL10": {value:"RL10", label:"울산광역시", order:11},
                "RL11": {value:"RL11", label:"인천광역시", order:12},
                "RL12": {value:"RL12", label:"전라남도", order:13},
                "RL13": {value:"RL13", label:"전북특별자치도", order:14},
                "RL14": {value:"RL14", label:"제주특별자치도", order:15},
                "RL15": {value:"RL15", label:"충청남도", order:16},
                "RL16": {value:"RL16", label:"충정북도", order:17},
                "RL99": {value:"RL99", label:"국내-상세위치 확인 불가", order:18},
                "RL00": {value:"RL00", label:"대한민국 외", order:19},
            },
            gender:{
                "GNM": {value:"GNM", label:"남성", order:1},
                "GNF": {value:"GNF", label:"여성", order:2},
                "GNU": {value:"GNU", label:"알 수 없음", order:3},
            },
            age:{
                "AG0013": {value:"AG0013", label:"14세 미만", order:1, disabled:true, negative: true, },
                "AG1418": {value:"AG1418", label:"14세~18세", order:2},
                "AG1924": {value:"AG1924", label:"19세~24세", order:3},
                "AG2529": {value:"AG2529", label:"25세~29세", order:4},
                "AG3034": {value:"AG3034", label:"30세~34세", order:5},
                "AG3539": {value:"AG3539", label:"35세~39세", order:6},
                "AG4044": {value:"AG4044", label:"40세~44세", order:7},
                "AG4549": {value:"AG4549", label:"45세~49세", order:8},
                "AG5054": {value:"AG5054", label:"50세~54세", order:9},
                "AG5559": {value:"AG5559", label:"55세~59세", order:10},
                "AG6099": {value:"AG6099", label:"60세~99세", order:11},
                "AGXXXX": {value:"AGXXXX", label:"알 수 없음", order:12},
                "AGALL": {value:"AGALL", label:"모든 연령대", order:13, disabled:true, negative: true, },
            },

            /** Audience 유형 */
            audienceType:{
                "AD0101": {value:"AD0101", label:"관심사"},
                "AD0102": {value:"AD0102", label:"구매의도"},
                "AD0099": {value:"AD0099", label:"구분없음"},
            },

            audience:{
                "AD0101004":		{value:"AD0101004",		    label:"가정/생활",		        order:1},
                "AD0101004001":		{value:"AD0101004001",		label:"가정/생활 > 가구/인테리어",		order:2},
                "AD0101004001002":	{value:"AD0101004001002",	label:"가정/생활 > 가구/인테리어 > 인테리어",		order:3},
                "AD0101004001001":	{value:"AD0101004001001",	label:"가정/생활 > 가구/인테리어 > 가구",		order:4},
                "AD0101007":		{value:"AD0101007",		    label:"교육/취업",		        order:5},
                "AD0101007002":		{value:"AD0101007002",		label:"교육/취업 > 취업",		order:6},
                "AD0101007003":		{value:"AD0101007003",		label:"교육/취업 > 자격증",		order:7},
                "AD0101007001":		{value:"AD0101007001",		label:"교육/취업 > 외국어 공부",		order:8},
                "AD0101001":		{value:"AD0101001",		    label:"건강",		            order:9},
                "AD0101001001":		{value:"AD0101001001",		label:"건강 > 건강 용품",		order:10},
                "AD0101001002":		{value:"AD0101001002",		label:"건강 > 건강 식품",		order:11},
                "AD0101002":		{value:"AD0101002",		    label:"금융/보험",		        order:12},
                "AD0101002001":		{value:"AD0101002001",		label:"금융/보험 > 보험",		order:13},
                "AD0101002001001":	{value:"AD0101002001001",	label:"금융/보험 > 자동차 보험",		order:14},
                "AD0101003":		{value:"AD0101003",		    label:"전자/가전",		        order:15},
                "AD0101003001":		{value:"AD0101003001",		label:"전자/가전 > 가전제품",		order:16},
                "AD0101005":		{value:"AD0101005",		    label:"여행",		            order:17},
                "AD0101005002":		{value:"AD0101005002",		label:"여행 > 국내 여행",		order:18},
                "AD0101005004":		{value:"AD0101005004",		label:"여행 > 자유 여행",		order:19},
                "AD0101005004001":	{value:"AD0101005004001",	label:"여행 > 자유 여행 > 숙박",		order:20},
                "AD0101005004002":	{value:"AD0101005004002",	label:"여행 > 자유 여행 > 항공권",		order:21},
                "AD0101005001":		{value:"AD0101005001",		label:"여행 > 해외 여행",		order:22},
                "AD0101005003":		{value:"AD0101005003",		label:"여행 > 패키지 여행",		order:23},
                "AD0101006":		{value:"AD0101006",		    label:"식품",		            order:24},
                "AD0101006001":		{value:"AD0101006001",		label:"식품 > 배달음식/패스트푸드",		order:25},
                "AD0101008":		{value:"AD0101008",		    label:"비즈니스",		    order:26},
                "AD0101008001":		{value:"AD0101008001",		label:"비즈니스 > 창업",		order:27},
                "AD0102001":		{value:"AD0102001",		    label:"패션",		            order:28},
                "AD0102001003":		{value:"AD0102001003",		label:"패션 > 패션잡화",		order:29},
                "AD0102001003001":	{value:"AD0102001003001",	label:"패션 > 패션잡화 > 여행가방/가방",		order:30},
                "AD0102001003002":	{value:"AD0102001003002",	label:"패션 > 패션잡화 > 핸드백/지갑",		order:31},
                "AD0102001003003":	{value:"AD0102001003003",	label:"패션 > 패션잡화 > 선글라스 ",		order:32},
                "AD0102001001":		{value:"AD0102001001",		label:"의류",		            order:33},
                "AD0102001001002":	{value:"AD0102001001002",	label:"의류 > 여성 의류",		order:34},
                "AD0102001001001":	{value:"AD0102001001001",	label:"의류 > 남성 의류",		order:35},
                "AD0102001002":		{value:"AD0102001002",		label:"주얼리/시계",		    order:36},
                "AD0102001004":		{value:"AD0102001004",		label:"신발",		            order:37},
                "AD0102001004001":	{value:"AD0102001004001",	label:"신발 > 스니커즈",		order:38},
                "AD0102001004002":	{value:"AD0102001004002",	label:"신발 > 신발 액세서리",		order:39},
                "AD0099":		    {value:"AD0099",		    label:"그 외 세그먼트",		    order:40, disabled:true, },
            }
        },
        /** 노출 제어 */
        view:{
            adTypeShop:['SHOPPING_BRAND_IMAGE_BANNER_AD','SHOPPING_BRAND_IMAGE_THUMBNAIL_AD'],
            adgroupTypeShop:['SHOPPING','CATALOG','SHOPPING_BRAND'],
        },

        type:{
            criterion:{
                GENDER: {value:"GENDER", label:"성별"},
                REGIONAL: {value:"REGIONAL", label:"지역"},
                AGE: {value:"AGE", label:"연령"},
                AUDIENCE: {value:"AUDIENCE", label:"이용자 세그먼트"},
                SCHEDULE: {value:"SCHEDULE", label:"요일/시간"},
                PROXIMITY: {value:"PROXIMITY", label:"반경"},
            },
            bizchannel:{
                SITE : {value : 'SITE', label : '웹사이트'},
                PHONE : {value : 'PHONE', label : '전화번호'},
                ADDRESS : {value : 'ADDRESS', label : '위치정보'},
                BOOKING : {value : 'BOOKING', label : '네이버 예약'},
                TALK : {value : 'TALK', label : '네이버 톡톡'},
                MALL: {value : 'MALL', label : '쇼핑몰'},
                CONTENTS : {value : 'CONTENTS', label : '콘텐츠'},
                PLACE : {value : 'PLACE', label : '플레이스'},
                CATALOG : {value : 'CATALOG', label : '쇼핑 제조사'},
                NAVER_TV : {value : 'NAVER_TV', label : '네이버 TV'},
                BRAND_STORE : {value : 'BRAND_STORE', label : '쇼핑브랜드스토어'},
                SHOPPING_BRAND : {value : 'SHOPPING_BRAND', label : '네이버 쇼핑 브랜드'},
            },

            campaign : {
                WEB_SITE : {value : 'WEB_SITE', label : '파워링크', code:"NPT0001"},
                SHOPPING : {value : 'SHOPPING', label : '쇼핑검색', code:"NPT0002"},
                INFORMATION : {value : 'INFORMATION', label : '정보'},
                PRODUCT : {value : 'PRODUCT', label : '상품'},
                BRAND_SEARCH : {value : 'BRAND_SEARCH', label : '브랜드검색'},
                PLACE : {value : 'PLACE', label : '플레이스'},
                CATALOG : {value : 'CATALOG', label : '카탈로그'},
            },
            adgroup : {
                WEB_SITE : {value : 'WEB_SITE', label : '사이트'},
                SHOPPING : {value : 'SHOPPING', label : '쇼핑몰 상품형'},
                INFORMATION : {value : 'INFORMATION', label : '정보'},
                PRODUCT : {value : 'PRODUCT', label : '상품'},
                BRAND_SEARCH : {value : 'BRAND_SEARCH', label : '브랜드검색'},
                PLACE : {value : 'PLACE', label : '플레이스'},
                CATALOG : {value : 'CATALOG', label : '제품 카탈로그형'},
                SHOPPING_BRAND: {value :'SHOPPING_BRAND', label :'쇼핑 브랜드형'},
            },
            ad : { 
                BRAND_SEARCH_AD : {value : 'BRAND_SEARCH_AD', label : '브랜드검색'},
                BRAND_SEARCH_NEW_AD : {value : 'BRAND_SEARCH_NEW_AD', label : '신제품검색'},
                CATALOG_AD : {value : 'CATALOG_AD', label : '쇼핑검색-제품 카탈로그형'},
                CONTENTS_AD_INFORMATION : {value : 'CONTENTS_AD_INFORMATION', label : '파워컨텐츠'},
                CONTENTS_AD_PRODUCT : {value : 'CONTENTS_AD_PRODUCT', label : '파워컨텐츠'},
                LOCAL_AD : {value : 'LOCAL_AD', label : '플레이스-플레이스검색'},
                PLACE_AD : {value : 'PLACE_AD', label : '플레이스/지역소상공인'},
                SHOPPING_BRAND_AD : {value : 'SHOPPING_BRAND_AD', label : '쇼핑검색-라이트형'},
                SHOPPING_BRAND_IMAGE_BANNER_AD : {value : 'SHOPPING_BRAND_IMAGE_BANNER_AD', label : '이미지 배너형'},
                SHOPPING_BRAND_IMAGE_THUMBNAIL_AD : {value : 'SHOPPING_BRAND_IMAGE_THUMBNAIL_AD', label : '썸네일형'},
                SHOPPING_PRODUCT_AD : {value : 'SHOPPING_PRODUCT_AD', label : '쇼핑검색-쇼핑몰 상품형'},
                TEXT_45 : {value : 'TEXT_45', label : '파워링크'},
            },

            adExtension : {
                /** 네이버 예약 */
                BOOKING : {value : 'BOOKING', label : '네이버 예약'},
                /** 계산 */
                CALCULATION : {value : 'CALCULATION', label : '계산'},
                /** 카탈로그 브랜드메시지 */
                CATALOG_BRAND_MESSAGE : {value : 'CATALOG_BRAND_MESSAGE', label : '카탈로그 브랜드메시지'},
                /** 카탈로그 상세정보 */
                CATALOG_DETAIL : {value : 'CATALOG_DETAIL', label : '카탈로그 상세정보'},
                /** 카탈로그 이벤트 */
                CATALOG_EVENT : {value : 'CATALOG_EVENT', label : '카탈로그 이벤트'},
                /** 카탈로그 부가정보 */
                CATALOG_EXTRA : {value : 'CATALOG_EXTRA', label : '카탈로그 부가정보'},
                /** 카탈로그 제품이미지 */
                CATALOG_IMAGE : {value : 'CATALOG_IMAGE', label : '카탈로그 제품이미지'},
                /** 카탈로그 제품영상 */
                CATALOG_MOVIE : {value : 'CATALOG_MOVIE', label : '카탈로그 제품영상'},
                /** 카탈로그 홍보문구 */
                CATALOG_PROMOTION : {value : 'CATALOG_PROMOTION', label : '카탈로그 홍보문구'},
                /** 카탈로그 구매조건 */
                CATALOG_PURCHASE_CONDITION : {value : 'CATALOG_PURCHASE_CONDITION', label : '카탈로그 구매조건'},
                /** 카탈로그 신제품 */
                CATALOG_RELEASE_DATE : {value : 'CATALOG_RELEASE_DATE', label : '카탈로그 신제품'},
                /** 홍보문구 */
                DESCRIPTION : {value : 'DESCRIPTION', label : '홍보문구'},
                /** 추가설명 */
                DESCRIPTION_EXTRA : {value : 'DESCRIPTION_EXTRA', label : '추가설명'},
                /** 추가제목 */
                HEADLINE : {value : 'HEADLINE', label : '추가제목'},
                /** 이미지형 서브링크 */
                IMAGE_SUB_LINKS : {value : 'IMAGE_SUB_LINKS', label : '이미지형 서브링크'},
                /** 위치정보 */
                LOCATION : {value : 'LOCATION', label : '위치정보'},
                /** 블로그 리뷰 */
                NAVER_BLOG_REVIEW : {value : 'NAVER_BLOG_REVIEW', label : '블로그 리뷰'},
                /** 홍보 영상 */
                NAVER_TV_VIDEO : {value : 'NAVER_TV_VIDEO', label : '홍보 영상'},
                /** 전화번호 */
                PHONE : {value : 'PHONE', label : '전화번호'},
                /** 플레이스 정보 */
                PLACE : {value : 'PLACE', label : '플레이스 정보'},
                /** 플레이스 뷰티스타일 */
                PLACE_BEAUTY_STYLE : {value : 'PLACE_BEAUTY_STYLE', label : '플레이스 뷰티스타일'},
                /** 플레이스 네이버 주문 */
                PLACE_SMART_ORDER : {value : 'PLACE_SMART_ORDER', label : '플레이스 네이버 주문'},
                /** 파워링크 이미지 */
                POWER_LINK_IMAGE : {value : 'POWER_LINK_IMAGE', label : '파워링크 이미지'},
                /** 가격링크 */
                PRICE_LINKS : {value : 'PRICE_LINKS', label : '가격링크'},
                /** 추가홍보문구 */
                PROMOTION : {value : 'PROMOTION', label : '추가홍보문구'},
                /** 쇼핑 브랜드 라이브 */
                SHOPPING_BRAND_BROADCAST : {value : 'SHOPPING_BRAND_BROADCAST', label : '쇼핑 브랜드 라이브'},
                /** 쇼핑 브랜드 이벤트 */
                SHOPPING_BRAND_EVENT : {value : 'SHOPPING_BRAND_EVENT', label : '쇼핑 브랜드 이벤트'},
                /** 쇼핑 브랜드 이미지 */
                SHOPPING_BRAND_IMAGE : {value : 'SHOPPING_BRAND_IMAGE', label : '쇼핑 브랜드 이미지'},
                /** 쇼핑 브랜드 동영상 */
                SHOPPING_BRAND_VIDEO : {value : 'SHOPPING_BRAND_VIDEO', label : '쇼핑 브랜드 동영상'},
                /** 쇼핑 상품 부가 정보 */
                SHOPPING_EXTRA : {value : 'SHOPPING_EXTRA', label : '쇼핑 상품 부가 정보'},
                /** 서브링크 */
                SUB_LINKS : {value : 'SUB_LINKS', label : '서브링크'},
                /** 네이버 톡톡 */
                TALK : {value : 'TALK', label : '네이버 톡톡'},
            },
        },
        status : {
            default : { 
                ELIGIBLE : {value : 'ELIGIBLE', label : '노출 가능'},
                LIMITED_ELIGIBLE : {value : 'LIMITED_ELIGIBLE', label : '일부 노출 가능'},
                PAUSED : {value : 'PAUSED', label : '중지'},
            },

            campaign : { 
                ELIGIBLE : {value : 'ELIGIBLE', label : '노출 가능'},
                PAUSED : {value : 'PAUSED', label : '중지'},
                DELETED : {value : 'DELETED', label : '삭제'},
            },
            ad : { 
                ELIGIBLE : {value : 'ELIGIBLE', label : '노출 가능'},
                PAUSED : {value : 'PAUSED', label : '중지'},
                DELETED : {value : 'DELETED', label : '삭제'},
            },
            adExtension : { 
                ELIGIBLE : {value : 'ELIGIBLE', label : '노출 가능'},
                PAUSED : {value : 'PAUSED', label : '중지'},
                DELETED : {value : 'DELETED', label : '삭제'},
            },
            adgroup : { 
                ELIGIBLE : {value : 'ELIGIBLE', label : '노출 가능'},
                LIMITED_ELIGIBLE : {value : 'LIMITED_ELIGIBLE', label : '일부 노출 가능'},
                PAUSED : {value : 'PAUSED', label : '중지'},
                DELETED : {value : 'DELETED', label : '삭제'},
            },
            keyword : { 
                ELIGIBLE : {value : 'ELIGIBLE', label : '노출 가능'},
                PAUSED : {value : 'PAUSED', label : '중지'},
                DELETED : {value : 'DELETED', label : '삭제'},
            },

            inspect : { 
                UNDER_REVIEW : {value : 'UNDER_REVIEW', label : '검토중'},
                APPROVED : {value : 'APPROVED', label : '노출 가능'},
                LIMITED_APPROVED : {value : 'LIMITED_APPROVED', label : '보류'},
                PENDING : {value : 'PENDING', label : '노출 제한'},
            } ,
        },

        statusReason : {
            campaign:{
                CAMPAIGN_DELETED : {value : 'CAMPAIGN_DELETED', label : '캠페인 삭제'},
                CAMPAIGN_ENDED : {value : 'CAMPAIGN_ENDED', label : '캠페인 기간외(종료)'},
                CAMPAIGN_LIMITED_BY_BUDGET : {value : 'CAMPAIGN_LIMITED_BY_BUDGET', label : '캠페인 예산도달'},
                CAMPAIGN_PAUSED : {value : 'CAMPAIGN_PAUSED', label : '캠페인 OFF'},
                CAMPAIGN_PENDING : {value : 'CAMPAIGN_PENDING', label : '캠페인 기간외'},
            },
            channel : {
                BUSINESS_CHANNEL_ABNORMAL_INTERLOCK : {value : 'BUSINESS_CHANNEL_ABNORMAL_INTERLOCK', label : '비즈채널 노출제한(연결안됨)'},
                BUSINESS_CHANNEL_ABNORMAL_INTERLOCK_PLACE : {value : 'BUSINESS_CHANNEL_ABNORMAL_INTERLOCK_PLACE', label : '비즈채널 노출제한(연결안됨) - 플레이스'},
                BUSINESS_CHANNEL_ABNORMAL_INTERLOCK_SHOPPING : {value : 'BUSINESS_CHANNEL_ABNORMAL_INTERLOCK_SHOPPING', label : '비즈채널 노출제한(연결안됨) - 쇼핑몰'},
                BUSINESS_CHANNEL_DISAPPROVED : {value : 'BUSINESS_CHANNEL_DISAPPROVED', label : '비즈채널 노출 제한'},
                BUSINESS_CHANNEL_PAUSED : {value : 'BUSINESS_CHANNEL_PAUSED', label : '비즈채널 OFF'},
                BUSINESS_CHANNEL_UNDER_REVIEW : {value : 'BUSINESS_CHANNEL_UNDER_REVIEW', label : '비즈채널 검토 중'},
            },
            pc_channel : { 
                PC_BUSINESS_CHANNEL_ABNORMAL_INTERLOCK : {value : 'PC_BUSINESS_CHANNEL_ABNORMAL_INTERLOCK', label : 'PC 비즈채널 노출제한(연결안됨)'},
                PC_BUSINESS_CHANNEL_APPROVED : {value : 'PC_BUSINESS_CHANNEL_APPROVED', label : 'PC 비즈채널 노출가능'},
                PC_BUSINESS_CHANNEL_DISAPPROVED : {value : 'PC_BUSINESS_CHANNEL_DISAPPROVED', label : 'PC 비즈채널 노출제한'},
                PC_BUSINESS_CHANNEL_UNDER_REVIEW : {value : 'PC_BUSINESS_CHANNEL_UNDER_REVIEW', label : 'PC 비즈채널 검토 중'},
            },
            mo_channel : { 
                MOBILE_BUSINESS_CHANNEL_ABNORMAL_INTERLOCK : {value : 'MOBILE_BUSINESS_CHANNEL_ABNORMAL_INTERLOCK', label : 'MO 비즈채널 노출제한(연결안됨)'},
                MOBILE_BUSINESS_CHANNEL_APPROVED : {value :'MOBILE_BUSINESS_CHANNEL_APPROVED', label :'MO 비즈채널 노출가능'},
                MOBILE_BUSINESS_CHANNEL_DISAPPROVED : {value :'MOBILE_BUSINESS_CHANNEL_DISAPPROVED', label :'MO 비즈채널 노출제한'},
                MOBILE_BUSINESS_CHANNEL_UNDER_REVIEW : {value :'MOBILE_BUSINESS_CHANNEL_UNDER_REVIEW', label :'MO 비즈채널 검토 중'},
            },
            adgroup : {
                GROUP_DELETED : {value : 'GROUP_DELETED', label : '그룹 삭제'},
                GROUP_HAS_NO_ELIGIBLE_AD : {value : 'GROUP_HAS_NO_ELIGIBLE_AD', label : '노출 가능한 소재 없음'},
                GROUP_LIMITED_BY_BUDGET : {value : 'GROUP_LIMITED_BY_BUDGET', label : '그룹 예산도달'},
                GROUP_PAUSED : {value : 'GROUP_PAUSED', label : '그룹 OFF'},
                GROUP_PAUSED_BY_INVALID_TARGET : {value : 'GROUP_PAUSED_BY_INVALID_TARGET', label : '잘못된 타겟팅 유형'},
            },
            keyword : {
                KEYWORD_DELETED : {value : 'KEYWORD_DELETED', label : '키워드 삭제'},
                KEYWORD_DENIED : {value : 'KEYWORD_DENIED', label : '키워드 사용제한'},
                KEYWORD_DISAPPROVED : {value : 'KEYWORD_DISAPPROVED', label : '키워드 노출제한'},
                KEYWORD_PAUSED : {value : 'KEYWORD_PAUSED', label : '키워드 OFF'},
                KEYWORD_UNDER_REVIEW : {value : 'KEYWORD_UNDER_REVIEW', label : '키워드 검토 중'},
            },
            ad : { 
                AD_ABNORMAL_INTERLOCK : {value : 'AD_ABNORMAL_INTERLOCK', label : '소재 노출제한(연결안됨)'},
                AD_DELETED : {value : 'AD_DELETED', label : '소재 삭제'},
                AD_DISAPPROVED : {value : 'AD_DISAPPROVED', label : '소재 노출제한'},
                AD_PAUSED : {value : 'AD_PAUSED', label : '소재 OFF'},
                AD_UNDER_REVIEW : {value : 'AD_UNDER_REVIEW', label : '소재 검토 중'},
            },
            extension : {
                AD_EXTENSION_ABNORMAL_INTERLOCK : {value :'AD_EXTENSION_ABNORMAL_INTERLOCK', label :'확장소재 노출제한(연결안됨)'},
                AD_EXTENSION_DELETED : {value :'AD_EXTENSION_DELETED', label :'확장소재 삭제'},
                AD_EXTENSION_DISAPPROVED : {value :'AD_EXTENSION_DISAPPROVED', label :'확장소재 노출제한'},
                AD_EXTENSION_NOT_SCHEDULED : {value :'AD_EXTENSION_NOT_SCHEDULED', label :'확장소재 요일/기간 외 '},
                AD_EXTENSION_PAUSED : {value :'AD_EXTENSION_PAUSED', label :'확장소재 OFF'},
                AD_EXTENSION_PENDING : {value :'AD_EXTENSION_PENDING', label :'확장소재 기간외'},
                AD_EXTENSION_UNDER_REVIEW : {value :'AD_EXTENSION_UNDER_REVIEW', label :'확장소재 검토 중'},
            },
            account : {
                LACK_OF_ACCOUNT_BALANCE : {value : 'LACK_OF_ACCOUNT_BALANCE', label : '계정 잔액 부족'},
                REFUND_IN_PROGRESS : {value : 'REFUND_IN_PROGRESS', label : '환불 진행 중'},
            },
        },
    }




        /*
        Assets:{
            AssetTypes:{
                Name:"Description",
                THUMBNAIL:"썸네일",
                MULTI_THUMBNAIL:"멀티썸네일",
                ADDITIONAL_TITLE:"추가제목",
                ADDITIONAL_LINK:"부가링크",
                SUBJECT:"말머리",
                PRICE_TABLE:"가격테이블",
                CALCULATE:"계산하기",
                PHONE_NUMBER:"전화번호",
                TALK_CHANNEL:"톡채널",
            },
            Config:{
                ON:"활성화",
                OFF:"비활성화",
                DEL:"삭제",
            },
            DeviceTypes:{
                MOBILE:"모바일",
                PC:"PC",
            },
            DeliveryMethod : { 
                ACCELERATED:"기본", 
                STANDARD:"균등배분" 
            },
            EventStatus:{
                COLLECTION:"수집중",
                PRE_COLLECTION:"수집전",
            },
            MemberType:{
                MASTER:"마스터 권한",
                MEMBER:"멤버 권한",
            },
            Placement:{
                PREMIUM_LINK_SEARCH_KAKAO:"검색 매체 카카오",
                PREMIUM_LINK_SEARCH_PARTNER:"검색 매체 파트너",
                PREMIUM_LINK_CONTENT_KAKAO:"콘텐츠 매체 카카오",
                PREMIUM_LINK_CONTENT_PARTNER:"콘텐츠 매체 파트너",
            },
            ReviewStatus:{
                APPROVED:"심사승인",
                WAITING:"심사중",
                REJECTED:"심사보류",
            },
            Status:{
                Campaign:{
                    ON:"운영 가능",
                    OFF:"운영 불가(캠페인 OFF)",
                    OFF_BY_AD_ACCOUNT_ADMIN_STOP:"운영 불가(광고계정 관리자정지)",
                    OFF_BY_AD_ACCOUNT:"운영 불가(광고계정 OFF)",
                    OFF_BY_AD_ACCOUNT_USER_BALANCE:"운영 불가(잔액 부족)",
                    OFF_BY_BIZ_CHANNEL_ADMIN:"운영 불가(비즈채널 관리자정지)",
                    OFF_BY_BIZ_CHANNEL_WAITING:"운영 불가(비즈채널 심사 미승인)",
                    OFF_BY_CAMPAIGN_DAILY_BUDGET:"운영 불가(캠페인 일예산 초과)",
                    DELETED:"삭제",
                },
                Adgroup:{
                    ON:"운영 가능",
                    OFF:"운영 불가(광고그룹 OFF)",
                    OFF_BY_AD_ACCOUNT:"운영 불가(광고계정 OFF)",
                    OFF_BY_AD_ACCOUNT_ADMIN_STOP:"운영 불가(광고계정 관리자정지)",
                    OFF_BY_AD_ACCOUNT_USER_BALANCE:"운영 불가(잔액 부족)",
                    OFF_BY_BIZ_CHANNEL_ADMIN:"운영 불가(비즈채널 관리자정지)",
                    OFF_BY_BIZ_CHANNEL_WAITING:"운영 불가(비즈채널 심사 미승인)",
                    OFF_BY_CAMPAIGN_DAILY_BUDGET:"운영 불가(캠페인 일예산 초과)",
                    OFF_BY_CAMPAIGN:"운영 불가(캠페인 OFF)",
                    OFF_BY_AD_GROUP_DAILY_BUDGET:"운영 불가(광고그룹 일예산 초과)",
                    OFF_BY_AD_GROUP_DATE:"운영 불가(광고그룹 미노출 기간)",
                    OFF_BY_AD_GROUP_HOUR:"운영 불가(광고그룹 미노출 시간)",
                    OFF_BY_AD_GROUP_DAY:"운영 불가(광고그룹 미노출 요일)",
                    DELETED:"삭제",
                },
                Keyword:{
                    ON:"운영 가능",
                    OFF:"운영 불가(키워드 OFF)",
                    WAITING:"운영 불가(키워드 심사 미승인)",
                    OFF_BY_AD_ACCOUNT:"운영 불가(광고계정 OFF)",
                    OFF_BY_AD_ACCOUNT_ADMIN_STOP:"운영 불가(광고계정 관리자정지)",
                    OFF_BY_AD_ACCOUNT_USER_BALANCE:"운영 불가(잔액 부족)",
                    OFF_BY_BIZ_CHANNEL_ADMIN:"운영 불가(비즈채널 관리자정지)",
                    OFF_BY_BIZ_CHANNEL_WAITING:"운영 불가(비즈채널 심사 미승인)",
                    OFF_BY_CAMPAIGN:"운영 불가(캠페인 OFF)",
                    OFF_BY_CAMPAIGN_DAILY_BUDGET:"운영 불가(캠페인 일예산 초과)",
                    OFF_BY_AD_GROUP:"운영 불가(광고그룹 OFF)",
                    OFF_BY_AD_GROUP_DAILY_BUDGET:"운영 불가(광고그룹 일예산 초과)",
                    OFF_BY_AD_GROUP_DATE:"운영 불가(광고그룹 미노출 기간)",
                    OFF_BY_AD_GROUP_HOUR:"운영 불가(광고그룹 미노출 시간)",
                    OFF_BY_AD_GROUP_DAY:"운영 불가(광고그룹 미노출 요일)",
                    DELETED:"삭제",
                },
                Ad:{
                    ON:"운영 가능",
                    OFF:"운영 불가(소재 OFF)",
                    WAITING:"운영 불가(소재 심사 미승인)",
                    OFF_BY_AD_ACCOUNT:"운영 불가(광고계정 OFF)",
                    OFF_BY_AD_ACCOUNT_ADMIN_STOP:"운영 불가(광고계정 관리자정지)",
                    OFF_BY_AD_ACCOUNT_USER_BALANCE:"운영 불가(잔액 부족)",
                    OFF_BY_BIZ_CHANNEL_ADMIN:"운영 불가(비즈채널 관리자정지)",
                    OFF_BY_BIZ_CHANNEL_WAITING:"운영 불가(비즈채널 심사 미승인)",
                    OFF_BY_CAMPAIGN:"운영 불가(캠페인 OFF)",
                    OFF_BY_CAMPAIGN_DAILY_BUDGET:"운영 불가(캠페인 일예산 초과)",
                    OFF_BY_AD_GROUP:"운영 불가(광고그룹 OFF)",
                    OFF_BY_AD_GROUP_DAILY_BUDGET:"운영 불가(광고그룹 일예산 초과)",
                    OFF_BY_AD_GROUP_DATE:"운영 불가(광고그룹 미노출 기간)",
                    OFF_BY_AD_GROUP_HOUR:"운영 불가(광고그룹 미노출 시간)",
                    OFF_BY_AD_GROUP_DAY:"운영 불가(광고그룹 미노출 요일)",
                    DELETED:"삭제",
                },
            },
            Naver: {
                NaverAdType : { TEXT_45:"텍스트", SHOPPING_PRODUCT_AD:"쇼핑상품", CONTENTS_AD_INFORMATION:"컨텐츠 광고정보", CONTENTS_AD_PRODUCT:"컨텐츠 광고상품", BRAND_SEARCH_AD:"브랜드검색", PLACE_AD:"플레이스", CATALOG_AD:"카탈로그" },
                NaverAdExtensionType : {
                    BOOKING: "네이버 예약",
                    CALCULATION: "계산",
                    CATALOG_BRAND_MESSAGE: "카탈로그 브랜드메시지",
                    CATALOG_DETAIL: "카탈로그 상세정보",
                    CATALOG_EVENT: "카탈로그 이벤트",
                    CATALOG_EXTRA: "카탈로그 부가정보",
                    CATALOG_IMAGE: "카탈로그 제품이미지",
                    CATALOG_MOVIE: "카탈로그 제품영상",
                    CATALOG_PROMOTION: "카탈로그 홍보문구",
                    CATALOG_PURCHASE_CONDITION: "카탈로그 구매조건",
                    CATALOG_RELEASE_DATE: "카탈로그 신제품",
                    DESCRIPTION: "홍보문구",
                    DESCRIPTION_EXTRA: "추가설명",
                    HEADLINE: "추가제목",
                    IMAGE_SUB_LINKS: "이미지형 서브링크",
                    LOCATION: "위치정보",
                    NAVER_BLOG_REVIEW: "블로그 리뷰",
                    NAVER_TV_VIDEO: "홍보 영상",
                    PHONE: "전화번호",
                    PLACE: "플레이스 정보",
                    PLACE_BEAUTY_STYLE: "플레이스 뷰티스타일",
                    PLACE_SMART_ORDER: "플레이스 네이버 주문",
                    POWER_LINK_IMAGE: "파워링크 이미지",
                    PRICE_LINKS: "가격링크",
                    PROMOTION: "추가홍보문구",
                    SHOPPING_BRAND_BROADCAST: "쇼핑 브랜드 라이브",
                    SHOPPING_BRAND_EVENT: "쇼핑 브랜드 이벤트",
                    SHOPPING_BRAND_IMAGE: "쇼핑 브랜드 이미지",
                    SHOPPING_BRAND_VIDEO: "쇼핑 브랜드 동영상",
                    SHOPPING_EXTRA: "쇼핑 상품 부가 정보",
                    SUB_LINKS: "서브링크",
                    TALK: "네이버 톡톡",
                },
                InspectStatus : { 
                    UNDER_REVIEW:"검수중", 
                    APPROVED:"승인", 
                    ELIGIBLE:"가능", 
                    PENDING:"대기" 
                } ,
                StatusNaver : { 
                    "DENIED": "사용 제한",
                    "ELIGIBLE": "노출 가능",
                    "LIMITED_ELIGIBLE": "일부노출 가능",
                    "PAUSED": "중지",
                    "REASON": "상태값 상세",
                    "INELIGIBLE": "노출 불가"
                },
                StatusReason: {
                    base : { ELIGIBLE:"가능", ENABLED:"사용" },
                    campaign:{
                        "CAMPAIGN_DELETED": "삭제(캠페인)",
                        "CAMPAIGN_ENDED": "캠페인 기간외(종료)",
                        "CAMPAIGN_LIMITED_BY_BUDGET": "캠페인 예산도달",
                        "CAMPAIGN_PAUSED": "캠페인 OFF",
                        "CAMPAIGN_PENDING": "캠페인 기간외" 
                    },
                    channel : {
                        "BUSINESS_CHANNEL_ABNORMAL_INTERLOCK": "비즈채널 노출제한(연결안됨)",
                        "BUSINESS_CHANNEL_ABNORMAL_INTERLOCK_PLACE": "비즈채널 노출제한(연결안됨) - 플레이스",
                        "BUSINESS_CHANNEL_ABNORMAL_INTERLOCK_SHOPPING": "비즈채널 노출제한(연결안됨) - 쇼핑몰",
                        "BUSINESS_CHANNEL_DISAPPROVED": "비즈채널 노출 제한",
                        "BUSINESS_CHANNEL_PAUSED": "비즈채널 중지",
                        "BUSINESS_CHANNEL_UNDER_REVIEW": "비즈채널 검토중"
                    },
                    pc_channel : { 
                        "PC_BUSINESS_CHANNEL_ABNORMAL_INTERLOCK": "PC 연동 비정상",
                        "PC_BUSINESS_CHANNEL_APPROVED": "PC",
                        "PC_BUSINESS_CHANNEL_DISAPPROVED": "PC 노출제한",
                        "PC_BUSINESS_CHANNEL_UNDER_REVIEW": "PC 검토중"
                    },
                    mo_channel : { 
                        "MOBILE_BUSINESS_CHANNEL_ABNORMAL_INTERLOCK": "모바일 연동 비정상",
                        "MOBILE_BUSINESS_CHANNEL_APPROVED": "모바일",
                        "MOBILE_BUSINESS_CHANNEL_DISAPPROVED": "모바일 노출제한",
                        "MOBILE_BUSINESS_CHANNEL_UNDER_REVIEW": "모바일 검토중"
                    },
                    group : {
                        "GROUP_DELETED": "삭제(광고그룹)",
                        "GROUP_HAS_NO_ELIGIBLE_AD": "노출 가능한 소재 없음",
                        "GROUP_LIMITED_BY_BUDGET": "그룹 예산도달",
                        "GROUP_PAUSED": "그룹 OFF",
                        "GROUP_PAUSED_BY_INVALID_TARGET": "잘못된 타겟팅 유형"
                    },
                    keyword : {
                        "KEYWORD_DELETED": "삭제(키워드)",
                        "KEYWORD_DENIED": "키워드 사용제한",
                        "KEYWORD_DISAPPROVED": "키워드 노출제한",
                        "KEYWORD_PAUSED": "키워드 OFF",
                        "KEYWORD_UNDER_REVIEW": "키워드 검토중"
                    },
                    ad : { 
                        "AD_ABNORMAL_INTERLOCK": "소재 연동제한",
                        "AD_DELETED": "삭제(소재)",
                        "AD_DISAPPROVED": "소재 노출제한",
                        "AD_PAUSED": "소재 OFF",
                        "AD_UNDER_REVIEW": "소재 검토중",
                    },
                    extension : {
                        "AD_EXTENSION_ABNORMAL_INTERLOCK": "확장 소재 연동 제한",
                        "AD_EXTENSION_DELETED": "삭제(확장 소재)",
                        "AD_EXTENSION_DISAPPROVED": "확장 소재 노출제한",
                        "AD_EXTENSION_NOT_SCHEDULED": "확장 소재 요일/시간 외",
                        "AD_EXTENSION_PAUSED": "확장 소재 OFF",
                        "AD_EXTENSION_PENDING": "확장 소재 기간 외",
                        "AD_EXTENSION_UNDER_REVIEW": "확장 소재 검토중"
                    },
                    account : { LACK_OF_ACCOUNT_BALANCE:"계정 잔액 부족", REFUND_IN_PROGRESS:"환불 진행중" },
                },
                DataSync: {
                    type : {
                        "CATALOG": "카탈로그",
                        "SHOPPING": "쇼핑",
                        "WEB_SITE":"사이트"
                    }
                }
            },
            MetricsGroups:{
                Name:"Description",
                BASIC:"기본지표",
                ADDITION:"추가지표",
                PIXEL_SDK_CONVERSION:"픽셀&SDK 전환 지표",
            },
            Report:{
                DatePreset:{
                    TODAY:"없음",
                    YESTERDAY:"일단위",
                    THIS_WEEK:"주단위",
                    LAST_WEEK:"월단위",
                    LAST_7DAY:"지난 7일",
                    LAST_14DAY:"지난 14일",
                    LAST_30DAY:"지난 30일",
                    THIS_MONTH:"이번 달",
                    LAST_MONTH:"지난 달",
                },
                TimeUnit:{
                    NONE:"없음",
                    DAY:"일단위(조회 기간을 31일 내 제공)",
                    WEEK:"주단위(조회 기간을 62일 내 제공)",
                    MONTH:"월단위(조회 기간을 12개월 내 제공)",
                },
                Dimension:{
                    NONE:"없음",
                    HOUR:{//"시간대",
                        "00":"00:00 ~ 00:59",
                        "01":"01:00 ~ 01:59",
                        "02":"02:00 ~ 02:59",
                        "03":"03:00 ~ 03:59",
                        "04":"04:00 ~ 04:59",
                        "05":"05:00 ~ 05:59",
                        "06":"06:00 ~ 06:59",
                        "07":"07:00 ~ 07:59",
                        "08":"08:00 ~ 08:59",
                        "09":"09:00 ~ 09:59",
                        "10":"10:00 ~ 10:59",
                        "11":"11:00 ~ 11:59",
                        "12":"12:00 ~ 12:59",
                        "13":"13:00 ~ 13:59",
                        "14":"14:00 ~ 14:59",
                        "15":"15:00 ~ 15:59",
                        "16":"16:00 ~ 16:59",
                        "17":"17:00 ~ 17:59",
                        "18":"18:00 ~ 18:59",
                        "19":"19:00 ~ 19:59",
                        "20":"20:00 ~ 20:59",
                        "21":"21:00 ~ 21:59",
                        "22":"22:00 ~ 22:59",
                        "23":"23:00 ~ 23:59",
                    },
                    DEVICE:{//"디바이스",	
                        MOBILE:"모바일",
                        PC:"PC",
                    },
                    PLACEMENT:{//"매체유형",
                        PREMIUM_LINK_SEARCH_KAKAO:"검색 매체 카카오",
                        PREMIUM_LINK_SEARCH_PARTNER:"검색 매체 파트너",
                        PREMIUM_LINK_CONTENT_KAKAO:"콘텐츠 매체 카카오",
                        PREMIUM_LINK_CONTENT_PARTNER:"콘텐츠 매체 파트너",
                        KEYWORD_EX:"키워드확장",
                        ASSET_TYPE:"확장소재 타입",
                    },
                },
            },

            //업종데이터
            BusinessTypes:[
                {id:116,parentName:'건강_병원',name:'기타 병원',fullName:'기타 병원'},
                {id:117,parentName:'건강_병원',name:'내과',fullName:'내과'},
                {id:118,parentName:'건강_병원',name:'노인병원',fullName:'노인병원'},
                {id:119,parentName:'건강_병원',name:'대장항문과',fullName:'대장항문과'},
                {id:120,parentName:'건강_병원',name:'동물병원',fullName:'동물병원'},
                {id:121,parentName:'건강_병원',name:'물리치료과',fullName:'물리치료과'},
                {id:122,parentName:'건강_병원',name:'비뇨기과',fullName:'비뇨기과'},
                {id:123,parentName:'건강_병원',name:'산부인과',fullName:'산부인과'},
                {id:124,parentName:'건강_병원',name:'성형외과',fullName:'성형외과'},
                {id:125,parentName:'건강_병원',name:'소아과',fullName:'소아과'},
                {id:126,parentName:'건강_병원',name:'신경정신과',fullName:'신경정신과'},
                {id:127,parentName:'건강_병원',name:'안과',fullName:'안과'},
                {id:128,parentName:'건강_병원',name:'외과',fullName:'외과'},
                {id:129,parentName:'건강_병원',name:'요양병원',fullName:'요양병원'},
                {id:130,parentName:'건강_병원',name:'이비인후과',fullName:'이비인후과'},
                {id:131,parentName:'건강_병원',name:'재활의학과',fullName:'재활의학과'},
                {id:132,parentName:'건강_병원',name:'정신병원',fullName:'정신병원'},
                {id:133,parentName:'건강_병원',name:'정형외과',fullName:'정형외과'},
                {id:134,parentName:'건강_병원',name:'치과',fullName:'치과'},
                {id:135,parentName:'건강_병원',name:'피부과',fullName:'피부과'},
                {id:136,parentName:'건강_병원',name:'한의원',fullName:'한의원'},
                {id:137,parentName:'건강_건강식품',name:'건강기능식품',fullName:'건강기능식품'},
                {id:138,parentName:'건강_건강식품',name:'기타 건강식품',fullName:'기타 건강식품'},
                {id:139,parentName:'건강_건강식품',name:'다이어트기기',fullName:'다이어트기기'},
                {id:140,parentName:'건강_건강식품',name:'다이어트식품',fullName:'다이어트식품'},
                {id:141,parentName:'건강_건강식품',name:'단식원',fullName:'단식원'},
                {id:142,parentName:'건강_건강식품',name:'일반건강식품',fullName:'일반건강식품'},
                {id:143,parentName:'건강_건강식품',name:'해외직배송 건강기능식품',fullName:'해외직배송 건강기능식품'},
                {id:144,parentName:'건강_건강식품',name:'헬스보충제',fullName:'헬스보충제'},
                {id:145,parentName:'건강_의약품',name:'약국',fullName:'약국'},
                {id:146,parentName:'건강_의약품',name:'일반의약품',fullName:'일반의약품'},
                {id:147,parentName:'건강_의약품',name:'전문의약품',fullName:'전문의약품'},
                {id:148,parentName:'건강_의료기기',name:'기타 의료기기',fullName:'기타 의료기기'},
                {id:149,parentName:'건강_의료기기',name:'의료기기',fullName:'의료기기'},
                {id:150,parentName:'건강_대체의학',name:'기타 대체의학',fullName:'기타 대체의학'},
                {id:151,parentName:'건강_대체의학',name:'마사지',fullName:'마사지'},
                {id:152,parentName:'건강_대체의학',name:'명상/기공',fullName:'명상/기공'},
                {id:153,parentName:'건강_대체의학',name:'심리치료',fullName:'심리치료'},
                {id:154,parentName:'건강_대체의학',name:'최면',fullName:'최면'},
                {id:155,parentName:'건강_건강정보',name:'건강정보',fullName:'건강정보'},
                {id:156,parentName:'건강_기타건강사이트',name:'기타 건강사이트',fullName:'기타 건강사이트'},
                {id:157,parentName:'교육_오프라인학원',name:'검정고시',fullName:'검정고시'},
                {id:158,parentName:'교육_오프라인학원',name:'공무원학원',fullName:'공무원학원'},
                {id:159,parentName:'교육_오프라인학원',name:'기숙학원',fullName:'기숙학원'},
                {id:160,parentName:'교육_오프라인학원',name:'기술학원',fullName:'기술학원'},
                {id:161,parentName:'교육_오프라인학원',name:'기타 오프라인학원',fullName:'기타 오프라인학원'},
                {id:162,parentName:'교육_오프라인학원',name:'디자인학원',fullName:'디자인학원'},
                {id:163,parentName:'교육_오프라인학원',name:'미술학원',fullName:'미술학원'},
                {id:164,parentName:'교육_오프라인학원',name:'미용학원',fullName:'미용학원'},
                {id:165,parentName:'교육_오프라인학원',name:'부동산/주택',fullName:'부동산/주택'},
                {id:166,parentName:'교육_오프라인학원',name:'세무회계',fullName:'세무회계'},
                {id:167,parentName:'교육_오프라인학원',name:'스튜어디스학원',fullName:'스튜어디스학원'},
                {id:168,parentName:'교육_오프라인학원',name:'어학원',fullName:'어학원'},
                {id:169,parentName:'교육_오프라인학원',name:'연기학원',fullName:'연기학원'},
                {id:170,parentName:'교육_오프라인학원',name:'요리학원',fullName:'요리학원'},
                {id:171,parentName:'교육_오프라인학원',name:'운전전문학원',fullName:'운전전문학원'},
                {id:172,parentName:'교육_오프라인학원',name:'유학/어학연수',fullName:'유학/어학연수'},
                {id:173,parentName:'교육_오프라인학원',name:'음악학원',fullName:'음악학원'},
                {id:174,parentName:'교육_오프라인학원',name:'의료학원',fullName:'의료학원'},
                {id:175,parentName:'교육_오프라인학원',name:'일반운전학원',fullName:'일반운전학원'},
                {id:176,parentName:'교육_오프라인학원',name:'입시/편입',fullName:'입시/편입'},
                {id:177,parentName:'교육_오프라인학원',name:'체육학원',fullName:'체육학원'},
                {id:178,parentName:'교육_오프라인학원',name:'체험학습',fullName:'체험학습'},
                {id:179,parentName:'교육_오프라인학원',name:'컴퓨터학원',fullName:'컴퓨터학원'},
                {id:180,parentName:'교육_오프라인학원',name:'화술학원',fullName:'화술학원'},
                {id:181,parentName:'교육_온라인학원',name:'검정고시A',fullName:'검정고시A'},
                {id:182,parentName:'교육_온라인학원',name:'공무원학원A',fullName:'공무원학원A'},
                {id:183,parentName:'교육_온라인학원',name:'기타 온라인학원',fullName:'기타 온라인학원'},
                {id:184,parentName:'교육_온라인학원',name:'부동산/주택A',fullName:'부동산/주택A'},
                {id:185,parentName:'교육_온라인학원',name:'어학원A',fullName:'어학원A'},
                {id:186,parentName:'교육_온라인학원',name:'유아교육',fullName:'유아교육'},
                {id:187,parentName:'교육_온라인학원',name:'유학/어학연수A',fullName:'유학/어학연수A'},
                {id:188,parentName:'교육_온라인학원',name:'입시/편입A',fullName:'입시/편입A'},
                {id:189,parentName:'교육_온라인학원',name:'전화/화상교육',fullName:'전화/화상교육'},
                {id:190,parentName:'교육_온라인학원',name:'컴퓨터학원A',fullName:'컴퓨터학원A'},
                {id:191,parentName:'교육_온라인학원',name:'학점은행제',fullName:'학점은행제'},
                {id:192,parentName:'교육_학습지',name:'학습지',fullName:'학습지'},
                {id:193,parentName:'교육_학교',name:'고등학교',fullName:'고등학교'},
                {id:194,parentName:'교육_학교',name:'대안학교',fullName:'대안학교'},
                {id:195,parentName:'교육_학교',name:'대학',fullName:'대학'},
                {id:196,parentName:'교육_학교',name:'대학교',fullName:'대학교'},
                {id:197,parentName:'교육_학교',name:'대학원',fullName:'대학원'},
                {id:198,parentName:'교육_학교',name:'유치원',fullName:'유치원'},
                {id:199,parentName:'교육_학교',name:'중학교',fullName:'중학교'},
                {id:200,parentName:'교육_학교',name:'직업학교',fullName:'직업학교'},
                {id:201,parentName:'교육_학교',name:'초등학교',fullName:'초등학교'},
                {id:202,parentName:'교육_교육원',name:'문화센터',fullName:'문화센터'},
                {id:203,parentName:'교육_교육원',name:'어린이집',fullName:'어린이집'},
                {id:204,parentName:'교육_교육원',name:'평생교육원',fullName:'평생교육원'},
                {id:205,parentName:'교육_기타교육사이트',name:'기타 교육사이트',fullName:'기타 교육사이트'},
                {id:206,parentName:'금융_부동산',name:'부동산거래',fullName:'부동산거래'},
                {id:207,parentName:'금융_부동산',name:'부동산경매',fullName:'부동산경매'},
                {id:208,parentName:'금융_부동산',name:'부동산분양',fullName:'부동산분양'},
                {id:209,parentName:'금융_부동산',name:'부동산임대',fullName:'부동산임대'},
                {id:210,parentName:'금융_부동산',name:'부동산컨설팅',fullName:'부동산컨설팅'},
                {id:211,parentName:'금융_보험',name:'보험',fullName:'보험'},
                {id:212,parentName:'금융_대출',name:'1금융권',fullName:'1금융권'},
                {id:213,parentName:'금융_대출',name:'2금융 수탁사',fullName:'2금융 수탁사'},
                {id:214,parentName:'금융_대출',name:'2금융 은행권',fullName:'2금융 은행권'},
                {id:215,parentName:'금융_대출',name:'2금융 캐피탈',fullName:'2금융 캐피탈'},
                {id:216,parentName:'금융_대출',name:'보험사 대출',fullName:'보험사 대출'},
                {id:217,parentName:'금융_대출',name:'대부업',fullName:'대부업'},
                {id:218,parentName:'금융_대출',name:'전당포',fullName:'전당포'},
                {id:219,parentName:'금융_재테크',name:'자산관리',fullName:'자산관리'},
                {id:220,parentName:'금융_재테크',name:'증권',fullName:'증권'},
                {id:221,parentName:'금융_재테크',name:'투자정보서비스',fullName:'투자정보서비스'},
                {id:222,parentName:'금융_신용',name:'신용정보',fullName:'신용정보'},
                {id:223,parentName:'금융_신용',name:'신용조사/채권추심',fullName:'신용조사/채권추심'},
                {id:224,parentName:'금융_신용',name:'신용카드',fullName:'신용카드'},
                {id:225,parentName:'금융_기타금융서비스',name:'기타 금융서비스',fullName:'기타 금융서비스'},
                {id:226,parentName:'소매(쇼핑몰)_전자',name:'게임기기',fullName:'게임기기'},
                {id:227,parentName:'소매(쇼핑몰)_전자',name:'계절가전',fullName:'계절가전'},
                {id:228,parentName:'소매(쇼핑몰)_전자',name:'렌탈가전',fullName:'렌탈가전'},
                {id:229,parentName:'소매(쇼핑몰)_전자',name:'사무기기',fullName:'사무기기'},
                {id:230,parentName:'소매(쇼핑몰)_전자',name:'생활/소형가전',fullName:'생활/소형가전'},
                {id:231,parentName:'소매(쇼핑몰)_전자',name:'영상가전',fullName:'영상가전'},
                {id:232,parentName:'소매(쇼핑몰)_전자',name:'음향가전',fullName:'음향가전'},
                {id:233,parentName:'소매(쇼핑몰)_전자',name:'조명기기',fullName:'조명기기'},
                {id:234,parentName:'소매(쇼핑몰)_전자',name:'통신가전',fullName:'통신가전'},
                {id:235,parentName:'소매(쇼핑몰)_전자',name:'특수기기',fullName:'특수기기'},
                {id:236,parentName:'소매(쇼핑몰)_전자',name:'핸드폰',fullName:'핸드폰'},
                {id:237,parentName:'소매(쇼핑몰)_꽃배달/선물',name:'꽃배달',fullName:'꽃배달'},
                {id:238,parentName:'소매(쇼핑몰)_꽃배달/선물',name:'선물',fullName:'선물'},
                {id:239,parentName:'소매(쇼핑몰)_가구/인테리어',name:'사무/학원가구',fullName:'사무/학원가구'},
                {id:240,parentName:'소매(쇼핑몰)_가구/인테리어',name:'생활가구',fullName:'생활가구'},
                {id:241,parentName:'소매(쇼핑몰)_가구/인테리어',name:'인테리어소품',fullName:'인테리어소품'},
                {id:242,parentName:'소매(쇼핑몰)_가구/인테리어',name:'침구/홈패션',fullName:'침구/홈패션'},
                {id:243,parentName:'소매(쇼핑몰)_의류',name:'남성의류',fullName:'남성의류'},
                {id:244,parentName:'소매(쇼핑몰)_의류',name:'단체복/유니폼/이벤트의상',fullName:'단체복/유니폼/이벤트의상'},
                {id:245,parentName:'소매(쇼핑몰)_의류',name:'빅사이즈',fullName:'빅사이즈'},
                {id:246,parentName:'소매(쇼핑몰)_의류',name:'속옷',fullName:'속옷'},
                {id:247,parentName:'소매(쇼핑몰)_의류',name:'아웃도어',fullName:'아웃도어'},
                {id:248,parentName:'소매(쇼핑몰)_의류',name:'여성의류',fullName:'여성의류'},
                {id:249,parentName:'소매(쇼핑몰)_의류',name:'종합의류',fullName:'종합의류'},
                {id:250,parentName:'소매(쇼핑몰)_의류',name:'중년의류',fullName:'중년의류'},
                {id:251,parentName:'소매(쇼핑몰)_의류',name:'한복',fullName:'한복'},
                {id:252,parentName:'소매(쇼핑몰)_패션잡화',name:'가방/지갑',fullName:'가방/지갑'},
                {id:253,parentName:'소매(쇼핑몰)_패션잡화',name:'기타 패션잡화',fullName:'기타 패션잡화'},
                {id:254,parentName:'소매(쇼핑몰)_패션잡화',name:'시계',fullName:'시계'},
                {id:255,parentName:'소매(쇼핑몰)_패션잡화',name:'신발',fullName:'신발'},
                {id:256,parentName:'소매(쇼핑몰)_패션잡화',name:'안경/선글라스',fullName:'안경/선글라스'},
                {id:257,parentName:'소매(쇼핑몰)_패션잡화',name:'액세서리',fullName:'액세서리'},
                {id:258,parentName:'소매(쇼핑몰)_생활/주방/문구',name:'문구',fullName:'문구'},
                {id:259,parentName:'소매(쇼핑몰)_생활/주방/문구',name:'사무용품',fullName:'사무용품'},
                {id:260,parentName:'소매(쇼핑몰)_생활/주방/문구',name:'생활용품',fullName:'생활용품'},
                {id:261,parentName:'소매(쇼핑몰)_생활/주방/문구',name:'주방용품',fullName:'주방용품'},
                {id:262,parentName:'소매(쇼핑몰)_취미',name:'진검',fullName:'진검'},
                {id:263,parentName:'소매(쇼핑몰)_취미',name:'총포사',fullName:'총포사'},
                {id:264,parentName:'소매(쇼핑몰)_취미',name:'공예',fullName:'공예'},
                {id:265,parentName:'소매(쇼핑몰)_취미',name:'기타 취미',fullName:'기타 취미'},
                {id:266,parentName:'소매(쇼핑몰)_취미',name:'모형',fullName:'모형'},
                {id:267,parentName:'소매(쇼핑몰)_취미',name:'악기',fullName:'악기'},
                {id:268,parentName:'소매(쇼핑몰)_취미',name:'애완동물',fullName:'애완동물'},
                {id:269,parentName:'소매(쇼핑몰)_취미',name:'원예',fullName:'원예'},
                {id:270,parentName:'소매(쇼핑몰)_취미',name:'종교',fullName:'종교'},
                {id:271,parentName:'소매(쇼핑몰)_식품',name:'담배',fullName:'담배'},
                {id:272,parentName:'소매(쇼핑몰)_식품',name:'주류저도수',fullName:'주류저도수'},
                {id:273,parentName:'소매(쇼핑몰)_식품',name:'가공식품',fullName:'가공식품'},
                {id:274,parentName:'소매(쇼핑몰)_식품',name:'음료',fullName:'음료'},
                {id:275,parentName:'소매(쇼핑몰)_식품',name:'일반식품',fullName:'일반식품'},
                {id:276,parentName:'소매(쇼핑몰)_식품',name:'홈베이킹',fullName:'홈베이킹'},
                {id:277,parentName:'소매(쇼핑몰)_구매대행',name:'구매대행',fullName:'구매대행'},
                {id:278,parentName:'소매(쇼핑몰)_종합쇼핑몰',name:'종합쇼핑몰',fullName:'종합쇼핑몰'},
                {id:279,parentName:'소매(쇼핑몰)_기타소매',name:'기타 소매',fullName:'기타 소매'},
                {id:280,parentName:'일반서비스_생활서비스',name:'민원서비스',fullName:'민원서비스'},
                {id:281,parentName:'일반서비스_생활서비스',name:'방제서비스',fullName:'방제서비스'},
                {id:282,parentName:'일반서비스_생활서비스',name:'보안서비스',fullName:'보안서비스'},
                {id:283,parentName:'일반서비스_생활서비스',name:'사진/인화',fullName:'사진/인화'},
                {id:284,parentName:'일반서비스_생활서비스',name:'심부름센터',fullName:'심부름센터'},
                {id:285,parentName:'일반서비스_생활서비스',name:'장례서비스',fullName:'장례서비스'},
                {id:286,parentName:'일반서비스_생활서비스',name:'조경서비스',fullName:'조경서비스'},
                {id:287,parentName:'일반서비스_생활서비스',name:'청소서비스',fullName:'청소서비스'},
                {id:288,parentName:'일반서비스_결혼서비스',name:'국내 및 국제결혼',fullName:'국내 및 국제결혼'},
                {id:289,parentName:'일반서비스_결혼서비스',name:'국내결혼',fullName:'국내결혼'},
                {id:290,parentName:'일반서비스_결혼서비스',name:'국제결혼',fullName:'국제결혼'},
                {id:291,parentName:'일반서비스_결혼서비스',name:'소개팅',fullName:'소개팅'},
                {id:292,parentName:'일반서비스_결혼서비스',name:'예물/혼수',fullName:'예물/혼수'},
                {id:293,parentName:'일반서비스_결혼서비스',name:'예식장',fullName:'예식장'},
                {id:294,parentName:'일반서비스_결혼서비스',name:'웨딩스튜디오',fullName:'웨딩스튜디오'},
                {id:295,parentName:'일반서비스_결혼서비스',name:'웨딩커뮤니티',fullName:'웨딩커뮤니티'},
                {id:296,parentName:'일반서비스_결혼서비스',name:'웨딩컨설팅',fullName:'웨딩컨설팅'},
                {id:297,parentName:'일반서비스_결혼서비스',name:'청첩장',fullName:'청첩장'},
                {id:298,parentName:'일반서비스_결혼서비스',name:'폐백/이바지',fullName:'폐백/이바지'},
                {id:299,parentName:'일반서비스_결혼서비스',name:'하객대행',fullName:'하객대행'},
                {id:300,parentName:'일반서비스_취업',name:'과외',fullName:'과외'},
                {id:301,parentName:'일반서비스_취업',name:'국내외 취업',fullName:'국내외 취업'},
                {id:302,parentName:'일반서비스_취업',name:'국내취업',fullName:'국내취업'},
                {id:303,parentName:'일반서비스_취업',name:'기타 취업서비스',fullName:'기타 취업서비스'},
                {id:304,parentName:'일반서비스_취업',name:'아르바이트',fullName:'아르바이트'},
                {id:305,parentName:'일반서비스_취업',name:'해외취업',fullName:'해외취업'},
                {id:306,parentName:'일반서비스_인력파견',name:'가사도우미',fullName:'가사도우미'},
                {id:307,parentName:'일반서비스_인력파견',name:'건축인력',fullName:'건축인력'},
                {id:308,parentName:'일반서비스_인력파견',name:'기타 인력파견',fullName:'기타 인력파견'},
                {id:309,parentName:'일반서비스_인력파견',name:'모델파견',fullName:'모델파견'},
                {id:310,parentName:'일반서비스_인력파견',name:'베이비시터',fullName:'베이비시터'},
                {id:311,parentName:'일반서비스_인력파견',name:'산모도우미',fullName:'산모도우미'},
                {id:312,parentName:'일반서비스_기타일반서비스',name:'기타 일반서비스',fullName:'기타 일반서비스'},
                {id:313,parentName:'전문서비스_법률서비스',name:'기타 법률서비스',fullName:'기타 법률서비스'},
                {id:314,parentName:'전문서비스_법률서비스',name:'노무사',fullName:'노무사'},
                {id:315,parentName:'전문서비스_법률서비스',name:'법무사',fullName:'법무사'},
                {id:316,parentName:'전문서비스_법률서비스',name:'변리사',fullName:'변리사'},
                {id:317,parentName:'전문서비스_법률서비스',name:'변호사',fullName:'변호사'},
                {id:318,parentName:'전문서비스_법률서비스',name:'세무/회계',fullName:'세무/회계'},
                {id:319,parentName:'전문서비스_법률서비스',name:'신용회복',fullName:'신용회복'},
                {id:320,parentName:'전문서비스_법률서비스',name:'이민',fullName:'이민'},
                {id:321,parentName:'전문서비스_법률서비스',name:'행정사',fullName:'행정사'},
                {id:322,parentName:'전문서비스_통/번역',name:'통/번역',fullName:'통/번역'},
                {id:323,parentName:'전문서비스_경영컨설팅',name:'경영컨설팅',fullName:'경영컨설팅'},
                {id:324,parentName:'전문서비스_경영컨설팅',name:'창업컨설팅',fullName:'창업컨설팅'},
                {id:325,parentName:'전문서비스_경영컨설팅',name:'프랜차이즈',fullName:'프랜차이즈'},
                {id:326,parentName:'전문서비스_서식',name:'서식',fullName:'서식'},
                {id:327,parentName:'전문서비스_건축',name:'건축사무소',fullName:'건축사무소'},
                {id:328,parentName:'전문서비스_건축',name:'건축자재',fullName:'건축자재'},
                {id:329,parentName:'전문서비스_건축',name:'설비수리/공사',fullName:'설비수리/공사'},
                {id:330,parentName:'전문서비스_건축',name:'인테리어시공',fullName:'인테리어시공'},
                {id:331,parentName:'전문서비스_운송서비스',name:'국내외 이사',fullName:'국내외 이사'},
                {id:332,parentName:'전문서비스_운송서비스',name:'국내이사',fullName:'국내이사'},
                {id:333,parentName:'전문서비스_운송서비스',name:'지입',fullName:'지입'},
                {id:334,parentName:'전문서비스_운송서비스',name:'창고/컨테이너',fullName:'창고/컨테이너'},
                {id:335,parentName:'전문서비스_운송서비스',name:'콜밴',fullName:'콜밴'},
                {id:336,parentName:'전문서비스_운송서비스',name:'퀵서비스',fullName:'퀵서비스'},
                {id:337,parentName:'전문서비스_운송서비스',name:'택배',fullName:'택배'},
                {id:338,parentName:'전문서비스_운송서비스',name:'해외이사',fullName:'해외이사'},
                {id:339,parentName:'전문서비스_기타전문서비스',name:'기타 전문서비스',fullName:'기타 전문서비스'},
                {id:340,parentName:'여가_레포츠',name:'골프',fullName:'골프'},
                {id:341,parentName:'여가_레포츠',name:'기타레포츠',fullName:'기타레포츠'},
                {id:342,parentName:'여가_레포츠',name:'낚시',fullName:'낚시'},
                {id:343,parentName:'여가_레포츠',name:'등산',fullName:'등산'},
                {id:344,parentName:'여가_레포츠',name:'라켓스포츠',fullName:'라켓스포츠'},
                {id:345,parentName:'여가_레포츠',name:'래프팅/서바이벌',fullName:'래프팅/서바이벌'},
                {id:346,parentName:'여가_레포츠',name:'수상스포츠',fullName:'수상스포츠'},
                {id:347,parentName:'여가_레포츠',name:'스노보드/스키',fullName:'스노보드/스키'},
                {id:348,parentName:'여가_레포츠',name:'축구/야구/농구',fullName:'축구/야구/농구'},
                {id:349,parentName:'여가_레포츠용품',name:'골프A',fullName:'골프A'},
                {id:350,parentName:'여가_레포츠용품',name:'기타 레포츠용품',fullName:'기타 레포츠용품'},
                {id:351,parentName:'여가_레포츠용품',name:'낚시A',fullName:'낚시A'},
                {id:352,parentName:'여가_레포츠용품',name:'등산A',fullName:'등산A'},
                {id:353,parentName:'여가_레포츠용품',name:'라켓스포츠A',fullName:'라켓스포츠A'},
                {id:354,parentName:'여가_레포츠용품',name:'래프팅/서바이벌A',fullName:'래프팅/서바이벌A'},
                {id:355,parentName:'여가_레포츠용품',name:'수상스포츠A',fullName:'수상스포츠A'},
                {id:356,parentName:'여가_레포츠용품',name:'스노보드/스키A',fullName:'스노보드/스키A'},
                {id:357,parentName:'여가_레포츠용품',name:'축구/야구/농구A',fullName:'축구/야구/농구A'},
                {id:358,parentName:'여가_국내여행',name:'국내여행',fullName:'국내여행'},
                {id:359,parentName:'여가_해외여행',name:'해외여행',fullName:'해외여행'},
                {id:360,parentName:'여가_종합여행사',name:'종합여행사',fullName:'종합여행사'},
                {id:361,parentName:'여가_여권/비자',name:'여권/비자',fullName:'여권/비자'},
                {id:362,parentName:'여가_항공권',name:'항공권',fullName:'항공권'},
                {id:363,parentName:'여가_국내숙박',name:'리조트/콘도',fullName:'리조트/콘도'},
                {id:364,parentName:'여가_국내숙박',name:'민박/펜션',fullName:'민박/펜션'},
                {id:365,parentName:'여가_국내숙박',name:'호텔',fullName:'호텔'},
                {id:366,parentName:'여가_해외숙박',name:'리조트/콘도A',fullName:'리조트/콘도A'},
                {id:367,parentName:'여가_해외숙박',name:'민박/펜션A',fullName:'민박/펜션A'},
                {id:368,parentName:'여가_해외숙박',name:'종합숙박',fullName:'종합숙박'},
                {id:369,parentName:'여가_해외숙박',name:'호텔A',fullName:'호텔A'},
                {id:370,parentName:'여가_기타여가서비스',name:'기타 여가서비스',fullName:'기타 여가서비스'},
                {id:371,parentName:'엔터테인먼트_운세서비스',name:'무속인',fullName:'무속인'},
                {id:372,parentName:'엔터테인먼트_운세서비스',name:'운세',fullName:'운세'},
                {id:373,parentName:'엔터테인먼트_운세서비스',name:'작명',fullName:'작명'},
                {id:374,parentName:'엔터테인먼트_다운로드서비스',name:'P2P/웹하드',fullName:'P2P/웹하드'},
                {id:375,parentName:'엔터테인먼트_다운로드서비스',name:'영상',fullName:'영상'},
                {id:376,parentName:'엔터테인먼트_다운로드서비스',name:'음원',fullName:'음원'},
                {id:377,parentName:'엔터테인먼트_다운로드서비스',name:'출판',fullName:'출판'},
                {id:378,parentName:'엔터테인먼트_스트리밍서비스',name:'영상A',fullName:'영상A'},
                {id:379,parentName:'엔터테인먼트_스트리밍서비스',name:'음원A',fullName:'음원A'},
                {id:380,parentName:'엔터테인먼트_스트리밍서비스',name:'출판A',fullName:'출판A'},
                {id:381,parentName:'엔터테인먼트_제작서비스',name:'영상B',fullName:'영상B'},
                {id:382,parentName:'엔터테인먼트_제작서비스',name:'음원B',fullName:'음원B'},
                {id:383,parentName:'엔터테인먼트_제작서비스',name:'출판B',fullName:'출판B'},
                {id:384,parentName:'엔터테인먼트_공연',name:'공연',fullName:'공연'},
                {id:385,parentName:'엔터테인먼트_티켓예매',name:'티켓예매',fullName:'티켓예매'},
                {id:386,parentName:'엔터테인먼트_복권',name:'복권',fullName:'복권'},
                {id:387,parentName:'엔터테인먼트_게임',name:'게임',fullName:'게임'},
                {id:388,parentName:'엔터테인먼트_도서',name:'도서',fullName:'도서'},
                {id:389,parentName:'엔터테인먼트_기획사',name:'기획사',fullName:'기획사'},
                {id:390,parentName:'엔터테인먼트_이벤트',name:'이벤트대행',fullName:'이벤트대행'},
                {id:391,parentName:'엔터테인먼트_이벤트',name:'이벤트용품',fullName:'이벤트용품'},
                {id:392,parentName:'엔터테인먼트_채팅',name:'채팅',fullName:'채팅'},
                {id:393,parentName:'엔터테인먼트_기타엔터테인먼트',name:'기타 엔터테인먼트',fullName:'기타 엔터테인먼트'},
                {id:394,parentName:'자동차_신차판매',name:'기아차',fullName:'기아차'},
                {id:395,parentName:'자동차_신차판매',name:'대우차',fullName:'대우차'},
                {id:396,parentName:'자동차_신차판매',name:'삼성차',fullName:'삼성차'},
                {id:397,parentName:'자동차_신차판매',name:'수입차',fullName:'수입차'},
                {id:398,parentName:'자동차_신차판매',name:'쌍용차',fullName:'쌍용차'},
                {id:399,parentName:'자동차_신차판매',name:'현대차',fullName:'현대차'},
                {id:400,parentName:'자동차_차량렌트',name:'차량렌트',fullName:'차량렌트'},
                {id:401,parentName:'자동차_중고차',name:'중고차',fullName:'중고차'},
                {id:402,parentName:'자동차_자동차용품',name:'자동차용품',fullName:'자동차용품'},
                {id:403,parentName:'자동차_자동차관리',name:'자동차관리',fullName:'자동차관리'},
                {id:404,parentName:'자동차_폐차장',name:'폐차장',fullName:'폐차장'},
                {id:405,parentName:'자동차_기타자동차서비스',name:'기타 자동차서비스',fullName:'기타 자동차서비스'},
                {id:406,parentName:'광고_광고물',name:'옥외광고',fullName:'옥외광고'},
                {id:407,parentName:'광고_광고물',name:'인쇄/광고디자인',fullName:'인쇄/광고디자인'},
                {id:408,parentName:'광고_광고물',name:'판촉물',fullName:'판촉물'},
                {id:409,parentName:'광고_광고컨설팅',name:'광고컨설팅',fullName:'광고컨설팅'},
                {id:410,parentName:'광고_광고컨설팅',name:'선거',fullName:'선거'},
                {id:411,parentName:'광고_기타광고서비스',name:'기타 광고서비스',fullName:'기타 광고서비스'},
                {id:412,parentName:'출산/육아_출산',name:'산후조리원',fullName:'산후조리원'},
                {id:413,parentName:'출산/육아_출산',name:'임부복',fullName:'임부복'},
                {id:414,parentName:'출산/육아_출산',name:'임신/육아 정보',fullName:'임신/육아 정보'},
                {id:415,parentName:'출산/육아_출산',name:'제대혈',fullName:'제대혈'},
                {id:416,parentName:'출산/육아_육아',name:'돌잔치',fullName:'돌잔치'},
                {id:417,parentName:'출산/육아_육아',name:'아기사진',fullName:'아기사진'},
                {id:418,parentName:'출산/육아_육아',name:'유아동복',fullName:'유아동복'},
                {id:419,parentName:'출산/육아_육아',name:'유아동용품',fullName:'유아동용품'},
                {id:420,parentName:'출산/육아_육아',name:'한복/돌복',fullName:'한복/돌복'},
                {id:421,parentName:'출산/육아_기타출산/육아',name:'기타 출산/육아',fullName:'기타 출산/육아'},
                {id:422,parentName:'미용_미용',name:'메이크업',fullName:'메이크업'},
                {id:423,parentName:'미용_미용',name:'문신',fullName:'문신'},
                {id:424,parentName:'미용_미용',name:'미용용품/기기',fullName:'미용용품/기기'},
                {id:425,parentName:'미용_미용',name:'반영구화장',fullName:'반영구화장'},
                {id:426,parentName:'미용_미용',name:'뷰티케어/체형클리닉',fullName:'뷰티케어/체형클리닉'},
                {id:427,parentName:'미용_미용',name:'헤어클리닉',fullName:'헤어클리닉'},
                {id:428,parentName:'미용_화장품',name:'화장품',fullName:'화장품'},
                {id:429,parentName:'컴퓨터_하드웨어',name:'PC/노트북',fullName:'PC/노트북'},
                {id:430,parentName:'컴퓨터_하드웨어',name:'주변기기',fullName:'주변기기'},
                {id:431,parentName:'컴퓨터_하드웨어',name:'컴퓨터부품',fullName:'컴퓨터부품'},
                {id:432,parentName:'컴퓨터_소프트웨어',name:'기업솔루션',fullName:'기업솔루션'},
                {id:433,parentName:'컴퓨터_소프트웨어',name:'상용소프트웨어',fullName:'상용소프트웨어'},
                {id:434,parentName:'컴퓨터_컴퓨터수리/보수',name:'컴퓨터 수리/보수',fullName:'컴퓨터 수리/보수'},
                {id:435,parentName:'컴퓨터_기타컴퓨터서비스',name:'기타 컴퓨터 서비스',fullName:'기타 컴퓨터 서비스'},
                {id:436,parentName:'인터넷/통신_인터넷서비스',name:'방송',fullName:'방송'},
                {id:437,parentName:'인터넷/통신_인터넷서비스',name:'인터넷폰',fullName:'인터넷폰'},
                {id:438,parentName:'인터넷/통신_인터넷서비스',name:'전용선',fullName:'전용선'},
                {id:439,parentName:'인터넷/통신_웹서비스',name:'기타 웹서비스',fullName:'기타 웹서비스'},
                {id:440,parentName:'인터넷/통신_웹서비스',name:'웹호스팅/도메인',fullName:'웹호스팅/도메인'},
                {id:441,parentName:'인터넷/통신_웹서비스',name:'홈페이지제작',fullName:'홈페이지제작'},
                {id:442,parentName:'인터넷/통신_통신서비스',name:'국제전화',fullName:'국제전화'},
                {id:443,parentName:'인터넷/통신_통신서비스',name:'네트워크/키폰',fullName:'네트워크/키폰'},
                {id:444,parentName:'인터넷/통신_통신서비스',name:'모바일서비스',fullName:'모바일서비스'},
                {id:445,parentName:'인터넷/통신_포털서비스',name:'포털서비스',fullName:'포털서비스'},
                {id:446,parentName:'인터넷/통신_기타인터넷/통신',name:'기타 인터넷/통신',fullName:'기타 인터넷/통신'},
                {id:447,parentName:'산업_공구/산업용품',name:'공구/산업용품',fullName:'공구/산업용품'},
                {id:448,parentName:'산업_기계',name:'기계',fullName:'기계'},
                {id:449,parentName:'산업_화공약품',name:'화공약품',fullName:'화공약품'},
                {id:450,parentName:'성인_성인용품',name:'성인용품',fullName:'성인용품'},
                {id:451,parentName:'성인_성인구인구직',name:'성인구인구직',fullName:'성인구인구직'},
                {id:452,parentName:'성인_성인컨텐츠',name:'성인만화',fullName:'성인만화'},
                {id:453,parentName:'성인_성인컨텐츠',name:'성인영상물',fullName:'성인영상물'},
                {id:454,parentName:'성인_성인컨텐츠',name:'성인폰팅/채팅',fullName:'성인폰팅/채팅'},
                {id:455,parentName:'성인_기타성인서비스',name:'기타 성인서비스',fullName:'기타 성인서비스'},
                {id:456,parentName:'기타',name:'기타',fullName:'기타'},
                {id:457,parentName:'금융_할부금융',name:'할부금융',fullName:'할부금융'},
                {id:458,parentName:'소매(쇼핑몰)_명품',name:'명품/중고명품 쇼핑',fullName:'명품/중고명품 쇼핑'},
                {id:459,parentName:'소매(쇼핑몰)_경매',name:'경매',fullName:'경매'},
                {id:460,parentName:'소매(쇼핑몰)_네트워크마케팅',name:'네트워크마케팅쇼핑몰',fullName:'네트워크마케팅쇼핑몰'},
                {id:461,parentName:'여가_카지노',name:'카지노',fullName:'카지노'},
                {id:462,parentName:'자동차_대리운전',name:'대리운전',fullName:'대리운전'},
                {id:463,parentName:'광고_네트워크마케팅',name:'네트워크마케팅',fullName:'네트워크마케팅'},
                {id:464,parentName:'미용_기타 미용',name:'기타 미용',fullName:'기타 미용'},
                {id:465,parentName:'산업_기타 산업',name:'기타 산업',fullName:'기타 산업'},
                {id:466,parentName:'소매(쇼핑몰)_소셜네트워크',name:'소셜네트워크',fullName:'소셜네트워크'},
                {id:467,parentName:'자동차_주차장',name:'주차장',fullName:'주차장'},
                {id:468,parentName:'쇼핑몰(소매)',name:'쇼핑몰입점셀러',fullName:'쇼핑몰입점셀러'},
                {id:469,parentName:'엔터테인먼트_영상홍보',name:'영상홍보',fullName:'영상홍보'},
                {id:470,parentName:'기관_단체',name:'일반단체',fullName:'일반단체'},
                {id:471,parentName:'기관_단체',name:'정당/정치후보자',fullName:'정당/정치후보자'},
                {id:472,parentName:'기관_단체',name:'정부기관',fullName:'정부기관'},
                {id:473,parentName:'게임_PC게임',name:'기타',fullName:'기타'},
                {id:474,parentName:'게임_PC게임',name:'레이싱',fullName:'레이싱'},
                {id:475,parentName:'게임_PC게임',name:'롤플레잉',fullName:'롤플레잉'},
                {id:476,parentName:'게임_PC게임',name:'보드/퍼즐',fullName:'보드/퍼즐'},
                {id:477,parentName:'게임_PC게임',name:'스포츠',fullName:'스포츠'},
                {id:478,parentName:'게임_PC게임',name:'시뮬레이션',fullName:'시뮬레이션'},
                {id:479,parentName:'게임_PC게임',name:'액션/슈팅',fullName:'액션/슈팅'},
                {id:480,parentName:'게임_PC게임',name:'전략',fullName:'전략'},
                {id:481,parentName:'게임_모바일게임',name:'기타',fullName:'기타'},
                {id:482,parentName:'게임_모바일게임',name:'레이싱/런',fullName:'레이싱/런'},
                {id:483,parentName:'게임_모바일게임',name:'롤플레잉',fullName:'롤플레잉'},
                {id:484,parentName:'게임_모바일게임',name:'보드/퍼즐',fullName:'보드/퍼즐'},
                {id:485,parentName:'게임_모바일게임',name:'스포츠',fullName:'스포츠'},
                {id:486,parentName:'게임_모바일게임',name:'시뮬레이션',fullName:'시뮬레이션'},
                {id:487,parentName:'게임_모바일게임',name:'액션/슈팅',fullName:'액션/슈팅'},
                {id:488,parentName:'게임_모바일게임',name:'전략',fullName:'전략'},
                {id:489,parentName:'음식점',name:'기타',fullName:'기타'},
                {id:490,parentName:'음식점',name:'배달음식',fullName:'배달음식'},
                {id:491,parentName:'음식점',name:'양식',fullName:'양식'},
                {id:492,parentName:'음식점',name:'일식',fullName:'일식'},
                {id:493,parentName:'음식점',name:'출장부페',fullName:'출장부페'},
                {id:494,parentName:'음식점',name:'한식',fullName:'한식'},
                {id:495,parentName:'엔터테인먼트_영화',name:'영화',fullName:'영화'},
                {id:497,parentName:'소매(쇼핑몰)_명품',name:'명품브랜드',fullName:'명품브랜드'},
                {id:502,parentName:'비카카오게임_PC비카카오게임',name:'레이싱',fullName:'레이싱'},
                {id:503,parentName:'비카카오게임_PC비카카오게임',name:'롤플레잉',fullName:'롤플레잉'},
                {id:504,parentName:'비카카오게임_PC비카카오게임',name:'보드/퍼즐',fullName:'보드/퍼즐'},
                {id:505,parentName:'비카카오게임_PC비카카오게임',name:'스포츠',fullName:'스포츠'},
                {id:506,parentName:'비카카오게임_PC비카카오게임',name:'시뮬레이션',fullName:'시뮬레이션'},
                {id:507,parentName:'비카카오게임_PC비카카오게임',name:'액션/슈팅',fullName:'액션/슈팅'},
                {id:508,parentName:'비카카오게임_PC비카카오게임',name:'전략',fullName:'전략'},
                {id:509,parentName:'비카카오게임_PC비카카오게임',name:'기타',fullName:'기타'},
                {id:510,parentName:'비카카오게임_모바일비카카오게임',name:'레이싱/런',fullName:'레이싱/런'},
                {id:511,parentName:'비카카오게임_모바일비카카오게임',name:'롤플레잉',fullName:'롤플레잉'},
                {id:512,parentName:'비카카오게임_모바일비카카오게임',name:'보드/퍼즐',fullName:'보드/퍼즐'},
                {id:513,parentName:'비카카오게임_모바일비카카오게임',name:'스포츠',fullName:'스포츠'},
                {id:514,parentName:'비카카오게임_모바일비카카오게임',name:'시뮬레이션',fullName:'시뮬레이션'},
                {id:515,parentName:'비카카오게임_모바일비카카오게임',name:'액션/슈팅',fullName:'액션/슈팅'},
                {id:516,parentName:'비카카오게임_모바일비카카오게임',name:'전략',fullName:'전략'},
                {id:517,parentName:'비카카오게임_모바일비카카오게임',name:'기타',fullName:'기타'},
                {id:554,parentName:'기타',name:'연령제한',fullName:'연령제한'},
                {id:555,parentName:'소매(쇼핑몰)_식품',name:'주류고도수(17도이상)',fullName:'주류고도수(17도이상)'},
                {id:556,parentName:'금융_대출',name:'금리비교서비스',fullName:'금리비교서비스'},
                {id:558,parentName:'금융_가상화폐',name:'거래소',fullName:'거래소'},
                {id:559,parentName:'금융_가상화폐',name:'솔루션',fullName:'솔루션'},
                {id:560,parentName:'금융_가상화폐',name:'기타서비스',fullName:'기타서비스'},
            ]
        }
    */

};

