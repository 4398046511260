declare global{
    interface Array<T>{
        /**
         * 배열로부터 랜덤하게 값을 선택하여 반환합니다.
         */
        randomSelect():T;

        /**
         * undefined이 포함된 배열의 전체 합을 반환합니다.
         * undefined는 '0'으로 처리됩니다.
         * @param values 
         */
        sum():number;

        unique():T[];

        /** 두 배열이 같은 원소를 갖는지 판단합니다. 순서는 무시 */
        equal(arr:(string|number)[]):boolean;

        /** 배열을 문자열로 묶기 */
        stringJoin(delimeter?:string):string;
        
    }
}

// eslint-disable-next-line no-extend-native
Array.prototype.randomSelect = function(){
    const rnd = Math.floor(Math.random() * this.length);
    return this[rnd];
};

// eslint-disable-next-line no-extend-native
Array.prototype.sum = function():number{
    return this.reduce((prev:number, v:number|undefined)=>prev + (v || 0), 0);
}

// eslint-disable-next-line no-extend-native
Array.prototype.unique = function(filter?:(item:any, index:number)=>boolean):any[]{
    const def = (v:any,index:number)=>this.indexOf(v)===index;
    const func:(item:any, index:number)=>boolean = filter!==undefined ? filter : def;
    return this.filter((v,index)=>func(v,index));
}

// eslint-disable-next-line no-extend-native
Array.prototype.equal = function(arr:(string|number)[]):boolean{
    if(this.length !== arr?.length){ return false; }    //배열 개수가 일치하지 않음
    const tmp:(string|number)[] = arr.filter((v)=>this.find((v2)=>v2===v));
    return this.length === tmp.length;  //필터처리 후에도 같으면 동일 배열로 판단
}

/** 배열을 문자열로 묶기 */
// eslint-disable-next-line no-extend-native
Array.prototype.stringJoin = function(delimeter?:string):string{
    return this.filter((v)=>v).map((v)=>v.toString()).join(delimeter || ",");
}


export { };

