import HelperComponent from './HelperComponent';

/** NAVER NOSP 광고그룹 Helper */
export default class AdGroupHelper extends HelperComponent{
    static path="/ad-group"; //기본 Path
    static simple="/media/simple-ad-groups";
    static stat='/ad-groups/stat';   //실적데이터
    static performances='/explorer/ad-groups';   //실적데이터 - Explorer용
    static downloadPath='/api/naversa/ad-groups';

}
