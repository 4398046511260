import clsx from 'clsx';
import _ from 'lodash';
import { CSSProperties, useEffect, useState } from 'react';
import { KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers';
import Config from '../../../common/conf/Config';
import UserSelect, { AccountType } from '../../../common/helpers/UserSelect';
import AdminService from '../../../common/services/AdminService';
import { AccountGroupImageFileResType } from '../../../common/services/models/admin/AccountGroupTypes';
import {
    EstimateAccountClientType,
    EstimateAccountGroupType,
} from '../../../common/services/models/admin/EstimateTypes';
import { useUserContext } from '../../../contexts/UserContext';
import AdminCode from '../../../modules/code/AdminCode';
import Code from '../../../modules/code/Code';
import Ellipsis from '../../../modules/layer/Ellipsis';
import MediaTypeIcon from '../../modules/symbol/MediaTypeIcon';
import styles from './accountSelect.module.scss';

interface AccountSelectProps {
    onChange?: (
        accountGroup: EstimateAccountGroupType,
        accountClient: EstimateAccountClientType
    ) => void;
}

const AccountSelect = (props: AccountSelectProps) => {
    const userContext = useUserContext();
    const [useSelect, setUseSelect] = useState<AccountType | undefined>();
    const [useData, setUseData] = useState<EstimateAccountGroupType[]>([]);
    useEffect(() => {
        const info = UserSelect.account.get();
        setUseSelect(info);

        AdminService.getMyAccountGroups().then((v: EstimateAccountGroupType[]) => {
            setUseData(v);
            userContext.userSelect.accountState?.setList(v);
            //선택항목이 목록에 없는 경우, 선택정보 삭제
            if (
                info?.accountGroup?.magId &&
                info?.accountClient?.mediaType &&
                info?.accountClient?.clientId
            ) {
                userContext.userSelect.accountState?.select(
                    info?.accountGroup?.magId,
                    info?.accountClient?.mediaType,
                    info?.accountClient?.clientId,
                    v
                );
                const isExist = v.reduce((prev, v1): boolean => {
                    if (v1.magId !== info.accountGroup?.magId) {
                        return prev;
                    }
                    return (
                        prev ||
                        v1.clients?.some((v2) => v2.clientId === info.accountClient?.clientId) ||
                        false
                    );
                }, false);
                if (!isExist) {
                    UserSelect.account.clear();
                    userContext.userSelect.accountState?.select(0, '', '');
                }
            }
        });
        // eslint-disable-next-line
    }, []);

    // 외부에서 선택 광고주 변경하는 경우
    useEffect(() => {
        setUseSelect(userContext.userSelect.accountState?.account);
    }, [userContext.userSelect.accountState?.account]);

    const onChange = (mag: EstimateAccountGroupType, client: EstimateAccountClientType) => {
        // 선택항목 저장
        userContext.userSelect.accountState?.select(mag.magId, client.mediaType, client.clientId);

        if (!_.isEqual(useSelect, {mag, client})) {
            UserSelect.account.set(mag, client);
            UserSelect.deviceType.set(['P', 'M']); //광고주 변경하면 디바이스 선택정보 초기화
            setUseSelect({accountGroup: mag, accountClient: client});
            userContext.toastMessage.add({
                title: '광고주계정 선택',
                body: (
                    <div>
                        [
                        <MediaTypeIcon
                            code={client.mediaType}
                            label={client.name}
                            viewLabel={true}
                        />
                        ] 계정을 선택하셨습니다!
                    </div>
                ),
            });

            // 페이지 초기화 - 매체코드가 동일하면 현재페이지 유지 - Explorer 오작동 이슈 있음 현행 유지 시키세요
            // if(useSelect?.accountClient?.mediaType !== accountClient?.mediaType || window.location.search === "/" || window.location.search === ""){ //대시보드 잦은 재로딩 방지
            userContext.navigate('/dashboard');
            // }else{
            //     userContext.navigate(0);
            // }
        }
    };

    return (
        <>
            <span
                className='btn-icon p-0 align-content-center rounded'
                data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                data-kt-menu-placement='right'
            >
                {useSelect?.accountGroup && (
                    <div className='d-flex align-content-center text-white text-left ms-6 cursor-pointer'>
                        <AccountIcon
                            className='me-1'
                            style={{borderColor: '#878787', color: '#acacac', fontSize: '22pt'}}
                            value={useSelect.accountGroup}
                        />
                        <div className='ps-1' style={{lineHeight: '1.4'}}>
                            <Ellipsis className='fw-bold fs-6' layerStyle={{width: '165px'}}>
                                {useSelect.accountGroup?.magName}
                            </Ellipsis>
                            <div className='fs-7' style={{color: '#acacac'}}>
                                <MediaTypeIcon
                                    code={useSelect.accountClient?.mediaType}
                                    label={
                                        <Ellipsis layerStyle={{width: '150px'}}>
                                            {useSelect.accountClient?.name}
                                        </Ellipsis>
                                    }
                                    viewLabel={true}
                                />
                            </div>
                        </div>
                    </div>
                )}
                {!useSelect?.accountGroup && (
                    <div
                        className='rounded align-content-center fw-bold h-40px px-2 pt-1 cursor-pointer'
                        style={{backgroundColor: '#3F4254', color: '#E1E3EA'}}
                    >
                        <KTSVG
                            path='/media/icons/duotune/general/gen002.svg'
                            className='svg-icon-2 me-2 text-white ps-4'
                        />
                        자산을 선택하세요.
                        <i
                            className='bi bi-chevron-right'
                            style={{marginTop: '0.13em', float: 'right'}}
                        />
                    </div>
                )}
            </span>

            <div
                className='menu menu-sub menu-sub-dropdown menu-rounded'
                style={{width: 'max-content'}}
                data-kt-menu='true'
            >
                <div
                    style={{
                        overflowY: 'scroll',
                        maxHeight: 'calc(100vh - 50px)',
                        width: 'max-content',
                    }}
                >
                    <div className='p-0 m-0 py-4'>
                        {useData.map((v1, index) => (
                            <div key={v1.magId} className={styles.dropdownItem}>
                                {index > 0 && <hr />}
                                <div className={styles.header}>
                                    <AccountIcon value={v1} className='me-3' />
                                    <div>
                                        <div className='fw-bold me-4 text-nowrap'>{v1.magName}</div>
                                        <div className='fs-8'>
                                            <span
                                                className={clsx(
                                                    'me-1',
                                                    v1.magUseYn === Code.base.useYnExt.Y.value
                                                        ? 'text-success'
                                                        : 'text-danger'
                                                )}
                                            >
                                                ●
                                            </span>
                                            {Code.getLabel(Code.base.useYnExt, v1.magUseYn)}
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    {AdminCode.getOptions(AdminCode.data.Assets.mediaType).map(
                                        (v2, index2) => {
                                            const clients = v1.clients.filter((v3) =>
                                                [v2.value, v2.code].includes(
                                                    v3.mediaType.toLocaleLowerCase()
                                                )
                                            );
                                            if (clients.length === 0) {
                                                return;
                                            } //자산이 없는 경우Pass
                                            return (
                                                <div key={index2}>
                                                    <MediaTypeIcon
                                                        code={v2.value}
                                                        label={v2.label}
                                                        viewLabel={true}
                                                        className={styles.mediaType}
                                                    />
                                                    <div className='mb-3'>
                                                        {clients.map((v2) => {
                                                            const selected: boolean =
                                                                useSelect?.accountGroup?.magId ===
                                                                    v1.magId &&
                                                                useSelect?.accountClient
                                                                    ?.mediaType === v2.mediaType &&
                                                                useSelect?.accountClient
                                                                    ?.clientId === v2.clientId;
                                                            return (
                                                                <div
                                                                    key={v2.clientId}
                                                                    className={clsx(
                                                                        'ms-3 text-nowrap',
                                                                        styles.client,
                                                                        {
                                                                            [styles.selected]:
                                                                                selected,
                                                                        }
                                                                    )}
                                                                    onClick={() =>
                                                                        !selected &&
                                                                        onChange(v1, v2)
                                                                    }
                                                                >
                                                                    {v2.name}
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                            );
                                        }
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};

export default AccountSelect;

export const AccountIcon: React.FC<{
    value?: EstimateAccountGroupType;
    className?: string;
    style?: CSSProperties;
    size?: string;
}> = (props) => {
    const [profileImg, setProfileImg] = useState<AccountGroupImageFileResType | undefined>(
        undefined
    ); // 저장된 이미지정보
    // mag 아이콘정보 개발서버 매핑처리
    const isDevelMode: boolean = ['local', 'devel'].includes(Config.project.mode);
    let list: number | string | undefined = isDevelMode
        ? [
              [4, 14], // CJ온스타일
              [5, 0], // 머스트잇
              [7, 29], // ABC마트
              [8, 25], // 메일유업
              [9, 36], // 카약
              [14, 18], // 쿠쿠홈시스
              [15, 37], // 호텔스컴바인
              [16, 0], // 메일헬스 뉴트리션
              [17, 0], // 티빙
              [18, 0], // 하루틴
              [34, 54], // 요기요
              [67, 0], // 나이키
              [100, 0], // 안국건강
              [133, 0], // 한화생명
              [166, 0], // 세이브더칠드런
              [199, 0], // 올리브영
              [232, 0], // 아퀘스트
              [265, 0], // FLO
          ].find((v) => v[1] === props.value?.magId)?.[0]
        : props.value?.magId;

    //테스트 계정
    // if(
    //     (isDevelMode && [1,3,73].includes(props.value.magId) )
    //     || (!isDevelMode && [1,3,6,10,11,12,13].includes(props.value.magId))
    // ){
    //     list = 'test';
    // }
    useEffect(() => {
        if (props.value?.magId) {
            //세션스토리지에 저장된 이미지 호출
            const img = Storage.getImage(props.value.magId);
            if (img) {
                setProfileImg(img);
                //캐시된 이미지가 없는 경우,
            } else {
                AdminService.user
                    .getProfileImage(props.value.magId.toString())
                    .then((res) => {
                        const data: AccountGroupImageFileResType = res.data
                            .result as AccountGroupImageFileResType;
                        setProfileImg(data);
                        data && Storage.setImage(props.value?.magId || 0, data);
                    })
                    .catch((e) => {
                        console.log(AdminService.ErrorMessage(e));
                    });
            }
        }
    }, [props.value]);

    return (
        <div
            className={clsx('text-center rounded p-0', props.className)}
            style={{
                overflow: 'hidden',
                fontSize: '24pt',
                lineHeight: 0,
                color: 'var(--bs-text-gray-600)',
                // borderWidth: 'var(--bs-border-width)',
                // borderStyle: 'var(--bs-border-style)',
                // borderColor: 'var(--bs-gray-600)',
                width: props.size || '35px',
                height: props.size || '35px',
                ...props.style,
            }}
        >
            {profileImg?.profileImageFile && (
                <img
                    alt='Logo'
                    // src={profileImg?.profileImageFile || toAbsoluteUrl(`/images/account/${list}.png`)} -- 이미지 표시 시간차 발생으로 제거
                    src={profileImg?.profileImageFile}
                    width={props.size || '35px'}
                    height={props.size || '35px'}
                    className='app-sidebar-logo-default'
                    onLoad={(e) => {
                        e.currentTarget.style.display = '';
                    }}
                    onError={(e) => {
                        e.currentTarget.style.display = 'none';
                    }}
                />
            )}
            <div
                style={{
                    width: props.size || '35px',
                    height: props.size || '35px',
                }}
                className='app-sidebar-logo-default rounded bg-body align-content-center border border-info'
            >
                <img
                    alt='Logo'
                    src={toAbsoluteUrl(`/images/adplo_square.png`)}
                    width='70%'
                    height='70%'
                    className='app-sidebar-logo-default'
                />
            </div>
        </div>
    );
};

//임시
class Storage {
    private static _key = 'MAG_IMAGE';
    private static getKey = (id: number): string => {
        return `${this._key}_${id}`;
    };

    public static getImage = (id: number): AccountGroupImageFileResType | undefined | null => {
        const key = this.getKey(id);
        const data = sessionStorage.getItem(key);
        if (!data) {
            return null;
        }
        return JSON.parse(data) as AccountGroupImageFileResType | undefined | null;
    };

    public static setImage = (id: number, img: AccountGroupImageFileResType) => {
        const key = this.getKey(id);
        // console.log({key, img});
        sessionStorage.setItem(key, JSON.stringify({...img, createTime: new Date().getTime()}));
    };
}
