
import { addDays, addMonths, addYears, endOfDay, endOfMonth, endOfWeek, endOfYear, format, isSameDay, startOfDay, startOfMonth, startOfWeek, startOfYear } from "date-fns";
import { Range, createStaticRanges } from "react-date-range";

export const locale = "ko";

const startOfWeekC = (date: number | Date):Date => startOfWeek(date, {weekStartsOn:1});
const endOfWeekC = (date: number | Date):Date => endOfWeek(date, {weekStartsOn:1});

export const definedDates = {
    today:      { startDate: startOfDay(new Date()),                endDate: endOfDay(new Date()) },
    yesterday:  { startDate: startOfDay(addDays(new Date(), -1)),   endDate: endOfDay(addDays(new Date(), -1)) },
    sevenDay:   { startDate: startOfDay(addDays(new Date(), -7)),   endDate: endOfDay(addDays(new Date(), -1)) },
    fourTeen:   { startDate: startOfDay(addDays(new Date(), -14)),   endDate: endOfDay(addDays(new Date(), -1)) },
    thirtyDay:  { startDate: startOfDay(addDays(new Date(), -30)),  endDate: endOfDay(addDays(new Date(), -1)) },
    nintyDay:   { startDate: startOfDay(addDays(new Date(), -90)),  endDate: endOfDay(addDays(new Date(), -1)) },
    lastWeek:   { startDate: startOfWeekC(addDays(new Date(), -7)),  endDate: endOfWeekC(addDays(new Date(), -7)) },
    week:       { startDate: startOfWeekC(new Date()),               endDate: endOfDay(addDays(new Date(), -1)) },
    lastMonth:  { startDate: startOfMonth(addMonths(new Date(), -1)),endDate:endOfMonth(addMonths(new Date(), -1)) },
    month:      { startDate: startOfMonth(new Date()),              endDate: endOfDay(addDays(new Date(), -1)) },
    lastYear:   { startDate: startOfYear(addYears(new Date(), -1)), endDate: endOfYear(addYears(new Date(), -1)) },
    year:       { startDate: startOfYear(new Date()),               endDate: endOfYear(new Date()) },
    custom:     { startDate: startOfDay(addDays(new Date(), -1)),   endDate: endOfDay(addDays(new Date(), -1)) },
};

const staticDefinedRangeOptions = () => {
    const customDateObjects = [
        // {
        //     label: locale === "ko" ? "오늘" : "Today",
        //     isSelected(range: Range) {
        //         const definedRange = this.range();
        //         return (
        //             isSameDay((range.startDate as unknown as Date), definedRange.startDate) &&
        //             isSameDay((range.endDate as unknown as Date), definedRange.endDate)
        //         );
        //     },
        //     range: () => (definedDates.today)
        // },
        {
            label: locale === "ko" ? "어제" : "Yesterday",
            isSelected(range: Range) {
                const definedRange = this.range();
                return (
                    isSameDay((range.startDate as unknown as Date), definedRange.startDate) &&
                    isSameDay((range.endDate as unknown as Date), definedRange.endDate)
                );
            },
            range: () => (definedDates.yesterday)
        },
        {
            label: locale === "ko" ? "최근 7일" : "Last 7 Days",
            isSelected(range: Range) {
                const definedRange = this.range();
                return (
                    isSameDay((range.startDate as unknown as Date), definedRange.startDate) &&
                    isSameDay((range.endDate as unknown as Date), definedRange.endDate)
                );
            },
            range: () => (definedDates.sevenDay)
        },
        {
            label: locale === "ko" ? "최근 14일" : "Last 14 Days",
            isSelected(range: Range) {
                const definedRange = this.range();
                return (
                    isSameDay((range.startDate as unknown as Date), definedRange.startDate) &&
                    isSameDay((range.endDate as unknown as Date), definedRange.endDate)
                );
            },
            range: () => (definedDates.fourTeen)
        },
        {
            label: locale === "ko" ? "최근 30일" : "Last 30 Days",
            isSelected(range: Range) {
                const definedRange = this.range();
                return (
                    isSameDay((range.startDate as unknown as Date), definedRange.startDate) &&
                    isSameDay((range.endDate as unknown as Date), definedRange.endDate)
                );
            },
            range: () => (definedDates.thirtyDay)
        },
        {
            label: locale === "ko" ? "지난주" : "Last Week",
            isSelected(range: Range) {
                const definedRange = this.range();
                return (
                    isSameDay((range.startDate as unknown as Date), definedRange.startDate) &&
                    isSameDay((range.endDate as unknown as Date), definedRange.endDate)
                );
            },
            range: () => (definedDates.lastWeek)
        },
        {
            label: locale === "ko" ? "이번주" : "This Week",
            isSelected(range: Range) {
                const definedRange = this.range();
                return (
                    isSameDay((range.startDate as unknown as Date), definedRange.startDate) &&
                    isSameDay((range.endDate as unknown as Date), definedRange.endDate)
                );
            },
            range: () => (definedDates.week)
        },
        {
            label: locale === "ko" ? "지난달" : "Last Month",
            isSelected(range: Range) {
                const definedRange = this.range();
                return (
                    isSameDay((range.startDate as unknown as Date), definedRange.startDate) &&
                    isSameDay((range.endDate as unknown as Date), definedRange.endDate)
                );
            },
            range: () => (definedDates.lastMonth)
        },
        {
            label: locale === "ko" ? "이번달" : "This Month",
            isSelected(range: Range) {
                const definedRange = this.range();
                return (
                    isSameDay((range.startDate as unknown as Date), definedRange.startDate) &&
                    isSameDay((range.endDate as unknown as Date), definedRange.endDate)
                );
            },
            range: () => (definedDates.month)
        },
    ];
    return customDateObjects;
};

export const staticDefinedRanges = [
    // ...defaultStaticRanges,
    ...createStaticRanges(staticDefinedRangeOptions())
];

export const formatDateDisplay = (date?:Date, dateFormat?:string) => {
    if (!date) return '';
    return format(date, dateFormat || "yyyy/MM/dd");
}

