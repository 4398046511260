import clsx from 'clsx'
import AuthService from '../../../../app/common/services/AuthService'
import UserSymbol from '../../../../app/pages/modules/symbol/UserSymbol'
import NotificationIcon from '../../../../app/pages/notification/NotificationIcon'
import { KTSVG } from '../../../helpers'
import { HeaderUserMenu, ThemeModeSwitcher } from '../../../partials'
import { FamilySiteBtn } from '../../../partials/layout/family-site/FamilySiteBtn'
import { useLayout } from '../../core'
import { Header } from './Header'
import Timmer from './SessionTimmer'

const itemClass = 'ms-1 ms-lg-3'
// const btnClass =
//   'btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px w-md-40px h-md-40px'
const userAvatarClass = 'symbol-35px symbol-md-40px'
const btnIconClass = 'svg-icon-1'

const Navbar = () => {
  const {config} = useLayout();
  const info = AuthService.storage.get();
  return (
    <div className='app-navbar flex-shrink-0'>
      {/* <div className={clsx('app-navbar-item align-items-stretch', itemClass)}>
        <Search />
      </div>

      <div className={clsx('app-navbar-item', itemClass)}>
        <div id='kt_activities_toggle' className={btnClass}>
          <KTSVG path='/media/icons/duotune/general/gen032.svg' className={btnIconClass} />
        </div>
      </div> */}

      {/* <div className={clsx('app-navbar-item', itemClass)}>
        <div
          // data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          className={btnClass}
        >
          <KTSVG path='/media/icons/duotune/general/gen022.svg' className={btnIconClass} />
        </div>
        <HeaderNotificationsMenu />
      </div> */}

      {/* <div className={clsx('app-navbar-item', itemClass)}>
        <div className={clsx('position-relative', btnClass)} id='kt_drawer_chat_toggle'>
          <KTSVG path='/media/icons/duotune/communication/com012.svg' className={btnIconClass} />
          <span className='bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink' />
        </div>
      </div> */}

      
      <div
        className='app-header-menu app-header-mobile-drawer align-items-stretch'
        data-kt-drawer='true'
        data-kt-drawer-name='app-header-menu'
        data-kt-drawer-activate='{default: true, lg: false}'
        data-kt-drawer-overlay='true'
        // data-kt-drawer-width='225px'
        data-kt-drawer-direction='end'
        data-kt-drawer-toggle='#kt_app_header_menu_toggle'
        // data-kt-swapper='true'
        // data-kt-swapper-mode="{default: 'append', lg: 'prepend'}"
        data-kt-swapper-parent="{default: '#kt_app_body', lg: '#kt_app_header_wrapper'}"
      >
        <Header />
      </div>

      {/* <div className={clsx('app-navbar-item', itemClass)}>
        <MenuInner/>
      </div> */}

      <div className={clsx('app-navbar-item', itemClass)}>
        <FamilySiteBtn toggleBtnClass={clsx('btn-active-light-primary btn-custom')} />
      </div>

      <div className={clsx('app-navbar-item', itemClass)}>
        <ThemeModeSwitcher toggleBtnClass={clsx('btn-active-light-primary btn-custom')} />
      </div>

      <div className={clsx('app-navbar-item', itemClass)}>
        {/* <Button variant='light' className='position-relative p-0 m-0'>
          <i className="bi bi-bell fs-2" />
          <Badge className='position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger text-dark'>99+</Badge>
        </Button> */}
        <NotificationIcon toggleBtnClass={clsx('btn-active-light-primary btn-custom')} />
      </div>

      <div className={clsx('app-navbar-item', itemClass)}>
        <div
          className={clsx('cursor-pointer symbol', userAvatarClass)}
          // data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
          {/* <img src={toAbsoluteUrl('/media/avatars/300-1.jpg')} alt='' /> */}
          <UserSymbol name={info?.userInfo?.user_signature || "손님"} size='sm' type='circle' />
        </div>
        <HeaderUserMenu />
      </div>
      <div className={clsx('app-navbar-item', itemClass)}>
        <Timmer />
      </div>

      {config.app?.header?.default?.menu?.display && false && (
        <div className='app-navbar-item d-lg-none ms-2 me-n3' title='Show header menu'>
          <div
            className='btn btn-icon btn-active-color-primary w-35px h-35px'
            id='kt_app_header_menu_toggle'
          >
            <KTSVG path='/media/icons/duotune/text/txt001.svg' className={btnIconClass} />
          </div>
        </div>
      )}
    </div>
  )
}

export { Navbar }

