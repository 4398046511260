import clsx from 'clsx';
import React, { CSSProperties, HTMLAttributes } from 'react';
import IconAll from '../../../images/all.png';
import IconGoogle from '../../../images/googleads.png';
import IconKakao from '../../../images/kakao.png';
import IconMeta from '../../../images/meta.png';
import IconNaver from '../../../images/naver.png';
import AdminCode from '../../../modules/code/AdminCode';

export interface MediaTypeIconProps extends HTMLAttributes<HTMLImageElement>{
    type? : string;
    code? : string;
    viewLabel?: boolean;
    label? :string | React.ReactNode;
    iconStyle?:CSSProperties;
    nowrap?: boolean;
}

export const MediaTypeIcon = (props:MediaTypeIconProps)=>{
    const type = props.type || props.code || 'LMT0001';
    let src = undefined;
    let label:string='';

    const styles:CSSProperties = {
        width: '1.3em',
        height: '1.3em',
        padding: '0',
        borderRadius: '1em',
    }
    const mediaType = AdminCode.data.Assets.mediaType;
    switch(type){
        case 'all' : case 'LMT0000' : label='모든 매체'; src=IconAll; break;
        // NAVER
        case mediaType.naver.value     : case mediaType.naver.value.toLocaleUpperCase()     : case mediaType.naver.code     : label=mediaType.naver.label;     src=IconNaver; break; //naver SA
        case mediaType.naver_nosp.value: case mediaType.naver_nosp.value.toLocaleUpperCase(): case mediaType.naver_nosp.code: label=mediaType.naver_nosp.label;src=IconNaver; break; //naver NOSP
        case mediaType.naver_gfa.value : case mediaType.naver_gfa.value.toLocaleUpperCase() : case mediaType.naver_gfa.code : label=mediaType.naver_gfa.label; src=IconNaver; break; //naver GFA
        
        // KAKAO
        case mediaType.kakao.value       : case mediaType.kakao.value.toLocaleUpperCase()      : case mediaType.kakao.code       : label=mediaType.kakao.label;       src=IconKakao; break;  // kakao SA
        case mediaType.kakao_moment.value: case mediaType.kakao_moment.value.toLocaleUpperCase(): case mediaType.kakao_moment.code: label=mediaType.kakao_moment.label;src=IconKakao; break;  // kakao moment

        // GOOGLE ADS
        case mediaType.google.value: case mediaType.google.value.toLocaleUpperCase() : case mediaType.google.code: label=mediaType.google.label; src=IconGoogle; break;

        // META
        case mediaType.meta.value  : case mediaType.meta.value.toLocaleUpperCase()   : case mediaType.meta.code  : label=mediaType.meta.label;   src=IconMeta; break;
    }
    
    return (<div className={clsx({"text-nowrap":props.nowrap!==false}, props.className)} style={{display:'inline-block', ...props.style}}>
        {src 
            ? <img
                ref={(node)=>{
                    node?.style.setProperty('margin-top', '-0.1em', 'important');
                }}
                style={{...styles, ...props.iconStyle}}
                className='me-2' src={src} alt={label}
            />
            : <div
                ref={(node)=>{
                    node?.style.setProperty('margin-bottom', '-0.1em', 'important');
                }}
                className='align-items-center text-center m-0 p-0 me-2'
                style={{display:'inline-block', backgroundColor:'var(--bs-gray-700)', overflow:'hidden',...styles, ...props.iconStyle, }}
            >
                <div className='fw-bolder text-gray-100' style={{fontSize:'0.8em'}}>{props.code}</div>
                {props.label}
            </div>
        }
        {props.viewLabel && (props.label || label)}
    </div>);
}

/** 매체타이별 색상값 반환 */
export const getMediaTypeColor = (type?:string):string|undefined =>{
    const info = AdminCode.getOptions(AdminCode.data.Assets.mediaType).find((v)=>v.value === type?.toLocaleLowerCase() || v.code=== type);
    if(info?.data?.color){
        return info.data.color;
    }
    switch(type){
        case "NAVER" : case 'A01M1001' : return "#03C75A";
        case "KAKAO" : case 'A01M1002' : return "#FFCD01";
        case "GOOGLE" : case 'A01M1003' : return "#3C64FF";
    }
    return undefined;
}



export default MediaTypeIcon;
