
import { StatReportResKeys, StatReportResType } from '../../../common/services/models/google/StatReportType';
import { CompareSelectOption } from '../../../modules/code/Options';
import { statDefaultProps, StatFormatter } from '../../modules/StatFormatter';
import { FilterType, HeaderColumn } from '../../modules/table/TableType';

/**
 * Stat리포트 데이터 필요컬럼 확장
 */
export const StatDataExtends = (row:StatReportResType):StatReportResType => {
    return {
        ...row,
        ctr:                    StatFormatter.divide(row?.clicks,           row?.impressions,   100 )?.truncRound(2),
        vtr:                    StatFormatter.divide(row?.videoView,        row?.impressions,   100 )?.truncRound(2),
        cpm:                    StatFormatter.divide(row?.cost,             row?.impressions,   1000)?.truncRound(),
        cpc:                    StatFormatter.divide(row?.cost,             row?.clicks,            )?.truncRound(),
        cpv:                    StatFormatter.divide(row?.cost,             row?.videoView,         )?.truncRound(),

        videoViewRate25:        StatFormatter.divide(row?.videoView25,      row?.impressions,   100 )?.truncRound(2),
        videoViewRate50:        StatFormatter.divide(row?.videoView50,      row?.impressions,   100 )?.truncRound(2),
        videoViewRate75:        StatFormatter.divide(row?.videoView75,      row?.impressions,   100 )?.truncRound(2),
        videoViewRateEnd:       StatFormatter.divide(row?.videoViewEnd,     row?.impressions,   100 )?.truncRound(2),

        interactionRate:        StatFormatter.divide(row?.interactions,     row?.impressions,   100 )?.truncRound(2),
        conversionsRate:        StatFormatter.divide(row?.conversions,      row?.interactions,  100 )?.truncRound(2),
        inappActionRate:        StatFormatter.divide(row?.inappActions,     row?.interactions,  100 )?.truncRound(2),
        installRate:            StatFormatter.divide(row?.installs,         row?.interactions,  100 )?.truncRound(2),
        costPerConversion:      StatFormatter.divide(row?.cost,             row?.conversions        )?.truncRound(),
        costPerInappAction:     StatFormatter.divide(row?.cost,             row?.inappActions       )?.truncRound(),
        costPerInstall:         StatFormatter.divide(row?.cost,             row?.installs           )?.truncRound(),
        purchasePerClick:       StatFormatter.divide(row?.purchase,         row?.interactions,  100 )?.truncRound(2),
    }
}

export const OxfordColumns:HeaderColumn[] = [
    { ...statDefaultProps, accessor: StatReportResKeys.impressions,         header: '노출수',           unit:'회', },
    { ...statDefaultProps, accessor: StatReportResKeys.clicks,              header: '클릭수',           unit:'회', },
    { ...statDefaultProps, accessor: StatReportResKeys.cost,                header: '비용',             unit:'원', },
    { ...statDefaultProps, accessor: StatReportResKeys.videoView,           header: '조회수',           unit:'회', },
    { ...statDefaultProps, group:{key:"videoView", header:"재생수"}, accessor: StatReportResKeys.videoView25,         header: '25%', unit:'회', },
    { ...statDefaultProps, group:{key:"videoView", header:"재생수"}, accessor: StatReportResKeys.videoView50,         header: '50%', unit:'회', },
    { ...statDefaultProps, group:{key:"videoView", header:"재생수"}, accessor: StatReportResKeys.videoView75,         header: '75%', unit:'회', },
    { ...statDefaultProps, group:{key:"videoView", header:"재생수"}, accessor: StatReportResKeys.videoViewEnd,        header: '100%',unit:'회', },
    { ...statDefaultProps, accessor: StatReportResKeys.interactions,        header: '상호작용 수',      unit:'회', },
    { ...statDefaultProps, accessor: StatReportResKeys.uniqueUsers,         header: '순 사용자',        unit:'회', },

    { ...statDefaultProps, accessor: StatReportResKeys.ctr,                 header: '클릭률(CTR)',   unit:'%', formatter: StatFormatter.roundPer},
    { ...statDefaultProps, accessor: StatReportResKeys.vtr,                 header: '조회율(VTR)',   unit:'%', formatter: StatFormatter.roundPer},
    { ...statDefaultProps, group:{key:"videoViewRate", header:"재생 진행률"}, accessor: StatReportResKeys.videoViewRate25,     header: '25%', unit:'%', formatter: StatFormatter.roundPer},
    { ...statDefaultProps, group:{key:"videoViewRate", header:"재생 진행률"}, accessor: StatReportResKeys.videoViewRate50,     header: '50%', unit:'%', formatter: StatFormatter.roundPer},
    { ...statDefaultProps, group:{key:"videoViewRate", header:"재생 진행률"}, accessor: StatReportResKeys.videoViewRate75,     header: '75%', unit:'%', formatter: StatFormatter.roundPer},
    { ...statDefaultProps, group:{key:"videoViewRate", header:"재생 진행률"}, accessor: StatReportResKeys.videoViewRateEnd,    header: '100% ',unit:'%', formatter: StatFormatter.roundPer},

    { ...statDefaultProps, accessor: StatReportResKeys.interactionRate,     header: '상호작용 발생률',          unit:'%',formatter: StatFormatter.roundPer },
    { ...statDefaultProps, accessor: StatReportResKeys.cpm,                 header: '1,000회 노출당 비용(CPM)',      unit:'원', },
    { ...statDefaultProps, accessor: StatReportResKeys.cpc,                 header: '클릭당 비용(CPC)',         unit:'원', },
    { ...statDefaultProps, accessor: StatReportResKeys.cpv,                 header: '조회당 비용(CPV)',         unit:'원', },

    { ...statDefaultProps, accessor: StatReportResKeys.conversions,         header: '전환수',           unit:'회', formatter: StatFormatter.round},
    { ...statDefaultProps, accessor: StatReportResKeys.viewThroughConversions,header:'조회 연결 전환',   unit:'회', },
    { ...statDefaultProps, accessor: StatReportResKeys.inappActions,        header: '인앱 액션',        unit:'회', formatter: StatFormatter.round},
    { ...statDefaultProps, accessor: StatReportResKeys.installs,            header: '설치수',           unit:'회', formatter: StatFormatter.round},
    { ...statDefaultProps, accessor: StatReportResKeys.purchase,            header: '구매하기',         unit:'회', formatter: StatFormatter.round},
    { ...statDefaultProps, accessor: StatReportResKeys.completeRegistration,header: '가입(등록)',       unit:'회', formatter: StatFormatter.round},
    { ...statDefaultProps, accessor: StatReportResKeys.addToCart,           header: '장바구니에 추가',    unit:'회', formatter: StatFormatter.round},
    { ...statDefaultProps, accessor: StatReportResKeys.partnerOutboundClicks,header:'외부 클릭',        unit:'회', formatter: StatFormatter.round},
    { ...statDefaultProps, accessor: StatReportResKeys.lead,                header: '리드',             unit:'회', formatter: StatFormatter.round},
    { ...statDefaultProps, accessor: StatReportResKeys.beginCheckout,       header: '결제 시작',         unit:'회', formatter: StatFormatter.round},
    { ...statDefaultProps, accessor: StatReportResKeys.contact,             header: '연락',             unit:'회', formatter: StatFormatter.round},
    { ...statDefaultProps, accessor: StatReportResKeys.bookAppointment,     header: '예약',             unit:'회', formatter: StatFormatter.round},
    { ...statDefaultProps, accessor: StatReportResKeys.landingPageView,     header: '페이지 조회',       unit:'회', formatter: StatFormatter.round},
    { ...statDefaultProps, accessor: StatReportResKeys.conversionEngagement,header: '사용자 참여',       unit:'회', formatter: StatFormatter.round},

    { ...statDefaultProps, accessor: StatReportResKeys.leadPhonecall,       header: '전화 통화 리드',    unit:'회', formatter: StatFormatter.round},
    { ...statDefaultProps, accessor: StatReportResKeys.storeSales,          header: '매장 판매',        unit:'회', formatter: StatFormatter.round},
    { ...statDefaultProps, accessor: StatReportResKeys.storeVisit,          header: '매장 방문',        unit:'회', formatter: StatFormatter.round},
    { ...statDefaultProps, accessor: StatReportResKeys.getDirectiongs,      header: '경로 찾기',        unit:'회', formatter: StatFormatter.round},

    { ...statDefaultProps, accessor: StatReportResKeys.importedLead,        header: '가져온 리드',       unit:'회', formatter: StatFormatter.round},
    { ...statDefaultProps, accessor: StatReportResKeys.subscribePaid,       header: '구독',             unit:'회', formatter: StatFormatter.round},
    { ...statDefaultProps, accessor: StatReportResKeys.requestQuote,        header: '견적 요청',          unit:'회', formatter: StatFormatter.round},
    { ...statDefaultProps, accessor: StatReportResKeys.submitLeadForm,      header: '리드 양식 제출',       unit:'회', formatter: StatFormatter.round},
    { ...statDefaultProps, accessor: StatReportResKeys.actionDefault,       header: '알 수 없는 전환',      unit:'회', formatter: StatFormatter.round},
    { ...statDefaultProps, accessor: StatReportResKeys.conversionEtc,       header: '전환 카테고리 기타',    unit:'회', formatter: StatFormatter.round},
    { ...statDefaultProps, accessor: StatReportResKeys.conversionsRate,     header: '전환율(전체)',        unit:'%', formatter: StatFormatter.roundPer},
    { ...statDefaultProps, accessor: StatReportResKeys.inappActionRate,     header: '전환율(인앱 액션)',    unit:'%', formatter: StatFormatter.roundPer},
    { ...statDefaultProps, accessor: StatReportResKeys.installRate,         header: '전환율(설치)',        unit:'%', formatter: StatFormatter.roundPer },
    { ...statDefaultProps, accessor: StatReportResKeys.costPerConversion,   header: '전환당 비용',         unit:'원', },
    { ...statDefaultProps, accessor: StatReportResKeys.costPerInappAction,  header: '인앱 액션당 비용',     unit:'원', },
    { ...statDefaultProps, accessor: StatReportResKeys.costPerInstall,      header: '설치당 비용',         unit:'원', },
];

export const ExplorerOxfordColumns:HeaderColumn[] = [
    { ...statDefaultProps, accessor: StatReportResKeys.purchasePerClick,    header: '전환율(구매하기)',     unit:'%', formatter: StatFormatter.roundPer},
];

/** 네이버 로컬모드(Tree, Label)용 실적컬럼 */
export const LocalOxfordColumns:HeaderColumn[] = [
    ...OxfordColumns.map((v):HeaderColumn=>({...v, filter:{type:FilterType.number, options:CompareSelectOption }, useSort:true,}))
];

