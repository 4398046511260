import { GoogleServer } from '../../axios';
import Utils from '../../utils/Utils';
import HelperComponent from './HelperComponent';

/** 다운로드 Helper */
export default class DownloadHelper extends HelperComponent{
    static path="/media/downloads"; //기본 Path

    //표준 추가
    public static post<S=any>(body:S, params?:any, parentPath?:string){
        const path = `${parentPath || ''}${this.path}`;
        return Utils.apiRequestValidation({}) || GoogleServer.post<S>(path, body, {params:params, headers:{useAccount:true}});
    }
}

