import { format } from 'date-fns';
import { Button, Spinner } from 'react-bootstrap';
import Yap from '../../../modules/yap/Yap';
import UserSelect from '../../common/helpers/UserSelect';
import { UserContextModel } from '../../contexts/UserContext';
import { HiperLinkEllisis } from '../../modules/layer/HiperLink';
import { FilterOptionType } from './table/TableType';

export default class Formatter {
    
    public static BadgesFormatter(value:string, rowData?:Map<string, any>) {
        if(value && !value.isEmpty()) {
            return (<span className="badge badge-primary">{value}</span>);
        }
    }

    public static UrlHiperLinkFormatter(value:string, row?:any, index?:number, maxWidth?:string) {
        const url:string|undefined = value?.replaceAll("\"","");
        if(!url || url.isEmpty()) { return undefined; }
        try{
            Yap(url).url(); //URL 형식이 아니면 catch 처리
            return <HiperLinkEllisis target='_blank' href={url} style={{maxWidth: maxWidth || '230px'}} />
            // return (<a href={value} target='blank' className={styles.hiperLinkFormatter} title={decodeURIComponent(url)}>{url}</a>);
        }catch(e){
            return value;
        }
    }

    public static SelectOptionFormatter(value:string, selectOption:FilterOptionType[]):string {
        if (value !== undefined) {
            const checkVal = value === null? '': value.toString();
            const item = selectOption.find(v=>(v.value === value || v.value === checkVal));
            if(item !== undefined){ return item.label; }
        }
        return '';
    }

    public static SelectCheckedOptionFormatter(value:string[], selectOption:FilterOptionType[], emptyRes?:string):string {
        if (value && Array.isArray(value)) {
            let res:string = '';
            value.forEach((val, index)=> {
                const checkVal = val === null? '': val.toString();
                const item = selectOption.find(v=>(v.value === val || v.value === checkVal));
                if(item !== undefined){ res = res + item.label + (value.length > (index+1) ? ', ':''); }
            })
            return res.isEmpty()? emptyRes||'':res;
        }
        return '';
    }

    public static ChangeDateFormat(value:string, strFormat?:string) {
        const form = strFormat || 'yyyy-MM-dd HH:mm:ss';
        if(!value || value===""){ return ""; }
        const date = new Date(value);
        return format(date, form);
    }

    public static CallExplorer(value:string, row?:any, index?:number, userContext?:UserContextModel, level?:string, media?:string) {
        const onClick=()=>{
            UserSelect.explorer.set(level || '', value, media);
            if(userContext && userContext.userSelect.data){
                const data = UserSelect.storage.get();
                userContext.userSelect.set(data);
            }
        }
        return <button
            id='kt_engage_explorer_toggle'
            className='engage-explorer-toggle btn btn-light-info p-1 ps-2 pe-2 m-0 text-center'
            data-bs-toggle='tooltip'
            data-bs-placement='left'
            data-bs-dismiss='click'
            data-bs-trigger='hover'
            onClick={onClick}
        ><i className="bi bi-bar-chart-line-fill p-0 m-0" /></button>
    }

    public static LockState(value:boolean, row:any){
        if(value !== true){ return; }
        return (<Spinner animation="border" variant="primary" role='status' size='sm' />);
    }

    public static LockStateComponent(value:string, row:any, lockState?:string[]){
        if(!lockState?.includes(value)){ return; }
        return (<Spinner animation="border" variant="primary" role='status' size='sm' />);
    }

    public static DownloadS3BucketHistory(value:string, row?:any) {
        switch(row?.taskStatusCode) {
            case 'T01S1001' : 
            case 'T01S1002' : return ('');
            case 'T01S1003' : return (<Button onClick={() => this.handleDownload(value)}>다운로드</Button>);
            case 'T01S1004' : return (<span style={{color : 'red'}}>작업중 오류</span>);
        }
    }


    static handleDownload = (path:string) => {
        // ApiData.history.download(path).then((res) => {
        //     window.location.href = res.data;
        //     // window.URL.revokeObjectURL(res.data);
        // })
        // .catch((error) => {
        //     console.log("history error : ", error);
        // })
        // ;
    }
}