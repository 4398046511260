/* eslint-disable react-hooks/exhaustive-deps */
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { KTSVG, toAbsoluteUrl } from '../../../helpers'
import { useLayout } from '../../core'
// import {Header} from './Header'
import { Navbar } from './Navbar'
// import { ThemeModeComponent } from '../../../assets/ts/layout'
import Config from '../../../../app/common/conf/Config'
import UserSelect from '../../../../app/common/helpers/UserSelect'
import { useUserContext } from '../../../../app/contexts/UserContext'
import styles from './style.module.scss'

export function HeaderWrapper() {
  const {config, classes} = useLayout();
  const userContext = useUserContext();
  if (!config.app?.header?.display) {
    return null
  }
  // const systemMode = ThemeModeComponent.getMode() as 'light' | 'dark';
  // const fontColor = systemMode === "light" ? "#000" : "#fff";

  const goNalo = ()=>{
    const qry = UserSelect.storage.getQueryString();  //선택정보
    window.open('/popup/nalo?'+qry, '_blank'); 
  }

  return (
    <div id='kt_app_header' className='app-header'>
      <div
        id='kt_app_header_container'
        className={clsx(
          'app-container flex-lg-grow-1',
          classes.headerContainer.join(' '),
          config.app?.header?.default?.containerClass
        )}
      >
        {config.app.sidebar?.display && (
          <>
            <div
              className='d-flex align-items-center d-lg-none ms-n2 me-2'
              title='Show sidebar menu'
            >
              <div
                className='btn btn-icon btn-active-color-primary w-35px h-35px'
                id='kt_app_sidebar_mobile_toggle'
              >
                <KTSVG path='/media/icons/duotune/abstract/abs015.svg' className=' svg-icon-1' />
              </div>
              <div className='d-flex align-items-center flex-grow-1 flex-lg-grow-0'>
                <Link to='/dashboard' className='d-lg-none'> 
                  <img
                    alt='Logo'
                    src={toAbsoluteUrl('/images/adplo_square.png')}
                    className='h-30px h-lg-30px'
                  />
                </Link>
              </div>
            </div>
          </>
        )}

        {!config.app.sidebar?.display && (
          <div className='d-flex align-items-center flex-grow-1 flex-lg-grow-0 me-lg-15'>
            <Link to='/dashboard'>
              {config.layoutType !== 'dark-header' ? (
                <img
                  alt='Logo'
                  src={toAbsoluteUrl('/media/logos/default-dark.svg')}
                  className='h-20px h-lg-30px app-sidebar-logo-default'
                />
              ) : (
                <>
                  <img
                    alt='Logo'
                    src={toAbsoluteUrl('/media/logos/default.svg')}
                    className='h-20px h-lg-30px app-sidebar-logo-default theme-light-show'
                  />
                  <img
                    alt='Logo'
                    src={toAbsoluteUrl('/media/logos/default-dark.svg')}
                    className='h-20px h-lg-30px app-sidebar-logo-default theme-dark-show'
                  />
                </>
              )}
            </Link>
          </div>
        )}

        <div
          id='kt_app_header_wrapper'
          className='d-flex align-items-stretch justify-content-between flex-lg-grow-1'
        >
          <div style={{alignItems:'center', display:'flex', width:'100%'}} >
            {Config.app.NALO.enable && <div
              className='d-flex align-items-stretch justify-content-between flex-lg-grow-1'
            >
              {/* <a
                href='#'
                onClick={goNalo}
                className={clsx('btn btn-active-light-primary btn-custom text-success p-0', 'svg-icon-2', styles.nalo)}
              >NALO</a> */}
              <a
                href='#'
                onClick={goNalo}
                className={clsx('btn btn-active-light-primary btn-custom text-success p-0', 'svg-icon-2', styles.nalo)}
              >
                <img
                  alt='Nalo'
                  src={toAbsoluteUrl('/images/nalo_logo_light.png')}
                  className='h-20px h-lg-30px app-sidebar-logo-default theme-dark-show'
                />
                <img
                  alt='Nalo'
                  src={toAbsoluteUrl('/images/nalo_logo_dark.png')}
                  className='h-20px h-lg-30px app-sidebar-logo-default theme-light-show'
                />
              </a>
            </div>}
            
          </div>
          <Navbar />
        </div>
      </div>
    </div>
  )
}
