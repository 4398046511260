import { GfaServer } from '../../axios';
import Utils from '../../utils/Utils';
import { DatshBoardStatQryType } from '../models/DashboardTypes';


/** NAVER GFA 대시보드용 Helper */
export default class DashboardHelper{
    static path="/dashboard"; //기본 Path

    /** NAVER GFA 대시보드 통계정보 */
    public static getStat(params:DatshBoardStatQryType){
        return Utils.apiRequestValidation({useAccount:false}) || GfaServer.get(`${this.path}/stat`,{params});
    }

}

