

/** 리포트 결과 배열타입 */
export interface StatReportResultArrayType<T=StatReportResType, V=StatReportInfoType> {
    info: V;
    performance: T;
}

/**  리포트 결과 타입 */
export interface StatReportResultType<T=StatReportResType> {
    info?: StatReportInfoType;
    total?: T[];
    daily?: T[];
    performances?: T[];
}

/** Explorer 상세보기 타입 */
export interface StatReportInfoType{
    adId?: string;
    adName?: string;
    adGroupId?: string;
    adGroupName?: string;
    campaignId?: string;
    campaignName?: string;
    objective?: string;
    objectiveName?: string;
    advertisingChannelSubType?: string;
    advertisingChannelSubTypeName?: string;
    googleAdgroupType?: string;
    googleAdgroupTypeName: string;
}

/** 리포트 데이터 타입, key - entry ID or Date */
export interface StatReportResType{
    key: string;
    impressions: number;
    clicks: number;
    cost: number;
    videoView: number;
    videoView25: number;
    videoView50: number;
    videoView75: number;
    videoViewEnd: number;
    interactions: number;
    uniqueUsers: number;
    ctr: number;
    vtr: number;
    videoViewRate25: number;
    videoViewRate50: number;
    videoViewRate75: number;
    videoViewRateEnd: number;
    interactionRate: number;
    cpm: number;
    cpc: number;
    cpv: number;
    conversions: number;
    viewThroughConversions: number;
    inappActions: number;
    installs: number;
    purchase: number;
    completeRegistration: number;
    addToCart: number;
    partnerOutboundClicks: number;
    lead: number;
    beginCheckout: number;
    contact: number;
    bookAppointment: number;
    landingPageView: number;
    conversionEngagement: number;
    leadPhonecall: number;
    storeSales: number;
    storeVisit: number;
    getDirectiongs: number;
    importedLead: number;
    subscribePaid: number;
    requestQuote: number;
    submitLeadForm: number;
    actionDefault: number;
    conversionEtc: number;
    conversionsRate: number;
    inappActionRate: number;
    installRate: number;
    costPerConversion: number;
    costPerInappAction: number;
    costPerInstall: number;
    purchasePerClick: number;
}

/**
 * 리포트 컬럼 처리용 키
 */
export enum StatReportResKeys{
    key = 'key',
    impressions = 'impressions',
    clicks = 'clicks',
    cost = 'cost',
    videoView = 'videoView',
    videoView25 = 'videoView25',
    videoView50 = 'videoView50',
    videoView75 = 'videoView75',
    videoViewEnd = 'videoViewEnd',
    interactions = 'interactions',
    uniqueUsers = 'uniqueUsers',
    videoViewRate25 = 'videoViewRate25',
    videoViewRate50 = 'videoViewRate50',
    videoViewRate75 = 'videoViewRate75',
    videoViewRateEnd = 'videoViewRateEnd',
    interactionRate = 'interactionRate',
    conversions = 'conversions',
    viewThroughConversions = 'viewThroughConversions',
    inappActions = 'inappActions',
    installs = 'installs',
    purchase = 'purchase',
    completeRegistration = 'completeRegistration',
    addToCart = 'addToCart',
    partnerOutboundClicks = 'partnerOutboundClicks',
    lead = 'lead',
    beginCheckout = 'beginCheckout',
    contact = 'contact',
    bookAppointment = 'bookAppointment',
    landingPageView = 'landingPageView',
    conversionEngagement = 'conversionEngagement',
    leadPhonecall = 'leadPhonecall',
    storeSales = 'storeSales',
    storeVisit = 'storeVisit',
    getDirectiongs = 'getDirectiongs',
    importedLead = 'importedLead',
    subscribePaid = 'subscribePaid',
    requestQuote = 'requestQuote',
    submitLeadForm = 'submitLeadForm',
    actionDefault = 'actionDefault',
    conversionEtc = 'conversionEtc',
    conversionsRate = 'conversionsRate',
    inappActionRate = 'inappActionRate',
    installRate = 'installRate',
    costPerConversion = 'costPerConversion',
    costPerInappAction = 'costPerInappAction',
    costPerInstall = 'costPerInstall',
    ctr = 'ctr',
    vtr = 'vtr',
    cpm = 'cpm',
    cpc = 'cpc',
    cpv = 'cpv',
    purchasePerClick = 'purchasePerClick',
}



export interface ExplorerDetailReqType{
    startDate: string;
    endDate: string;
    dimension?: string;
    searchDimensionWord?: string;
}

/** 네이버 실적데이터 대량 아이디에 대한 리포트 호출 쿼리타입 */
export interface ExplorerPostReqType extends ExplorerDetailReqType{
    ids: string[];
}
export interface ExplorerReqType{
    from: number,
    size: number,
    searchWord: string,
    startDate: string,
    endDate: string
}
