/* eslint-disable react/jsx-no-target-blank */
import clsx from 'clsx';
import { CSSProperties, useEffect, useState } from 'react';
import Config from '../../../../../../app/common/conf/Config';
import { KakaoTreeResType } from '../../../../../../app/common/helpers/KakaoTreeStorage';
import { AdGroupSimpleResType } from '../../../../../../app/common/services/models/kakao/AdGroupTypes';
import { CampaignSimpleResType } from '../../../../../../app/common/services/models/kakao/CampaignTypes';
import { useUserContext } from '../../../../../../app/contexts/UserContext';
import KakaoCode from '../../../../../../app/modules/code/KakaoCode';
import Ellipsis from '../../../../../../app/modules/layer/Ellipsis';
import AdPloSearch from '../../../../../../app/pages/components/input/AdPloSearch';
import MoreButton from '../MoreButton';
import { SidebarTreeItem } from '../SidebarTreeItem';
import { SidebarTreeTitle } from '../SidebarTreeTitle';
import styles from '../style.module.scss';

const paging:number = 100;
const SidebarMenuMainKakaoTree = () => {
    const [search, setSearch] = useState<string>("");
    const [page, setPage] = useState<number>(0);
    const userContext =useUserContext();

    useEffect(()=>{
        if(Config.app.TreeMode.enable){
            userContext.tree.kakao.set([]); //잔상방지
            userContext.modalLoading.show();
            userContext.tree.kakao.load()
            .finally(()=>{ userContext.modalLoading.hide();});
        }
    // eslint-disable-next-line
    }, [userContext.userSelect.accountState?.account]);
    
    const basePath:string = '/pages/kakao/tree';
    const data = userContext.tree.kakao.data.filter((v)=>!search || v.data.campaignName.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) > -1);
    const viewData = data.slice(0, (page+1)*paging);
    return <SidebarTreeTitle
        to={`${basePath}/keywordad`}
        icon='/media/icons/duotune/general/gen022.svg'
        title='키워드 광고'
    >
        <div className='my-2'><AdPloSearch placeholder='캠페인 검색' onChange={(v)=>{ setSearch(v.target.value) } } theme='dark'/></div>
        <div className='fs-7 mt-2' style={{color:"#9D9DA6"}}>캠페인 : {data.length.addComma()}</div>
        <div className='tree_expanded'>
            {viewData.map((v:KakaoTreeResType)=>
                <SidebarMenuAdGroup key={v.data.campaignId} value={v.data} adgroup={v.children} to='/pages/kakao/tree/keywordad/campaign'/>
            )}
            {viewData.length < data.length && <MoreButton onClick={()=>setPage(page+1)}/>}
        </div>
    </SidebarTreeTitle>
}

export default SidebarMenuMainKakaoTree;


const SidebarMenuAdGroup:React.FC<{value:CampaignSimpleResType, adgroup:AdGroupSimpleResType[], to?:string}> = (props)=>{
    const [useData, setUseData] = useState<AdGroupSimpleResType[]>([]);
    useEffect(()=>{
        setUseData(props.adgroup);
    }, [props.adgroup])

    const border ='1px solid var(--bs-gray-600)';
    const to:string = `${props.to}/${props.value.campaignId}`;
    return <SidebarTreeTitle to={to}
        treeIcon={true}
        minimizeHidden={true}
        title={<>
            <IconUserLock userLock={props.value?.config === KakaoCode.base.onOff.OFF.value}/>
            <Ellipsis title={props.value.campaignName}>{props.value.campaignName}</Ellipsis>
        </>}
    >
        <div className={clsx(styles.tree_item)}>
            {useData.map((v,index)=><SidebarTreeItem
                key={v.adGroupId} 
                className='p-1'
                to={`${to}/adgroup/${v.adGroupId}`}
                title={<>
                    <div className={styles.tree_item_bar}></div>
                    <IconUserLock userLock={v.adGroupConfig === KakaoCode.base.onOff.OFF.value} style={{fontSize:'1.2em'}}/>
                    <Ellipsis title={v.adGroupName}>{v.adGroupName}</Ellipsis>
                </>}
            />)}
        </div>
    </SidebarTreeTitle>
}

const IconUserLock:React.FC<{userLock?:boolean, style?:CSSProperties}> = (props)=><span 
    className={clsx('me-1', props.userLock ? 'text-danger' : 'text-success')}
    style={{fontSize:'1.5em', lineHeight:'100%', ...props.style}}
>●</span>;
