import { lazy, useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { PopupLayout } from '../../../_metronic/layout/PopupLayout';
import { AuthService } from '../../common/services';
import { SuspensedView } from '../../routing/PrivateRoutes';

const SubRoutes = () => { 
    const NaverRoutes = lazy(() => import('./naver/Routes'));
    const KakaoRoutes = lazy(() => import('./kakao/Routes'));
    const GoogleRoutes = lazy(() => import('./google/Routes'));
    const GfaRoutes = lazy(() => import('./gfa/Routes'));
    const NospRoutes = lazy(() => import('./nosp/Routes'));
    const KakaoMomentRoutes = lazy(() => import('./kakaomoment/Routes'));
    const MetaRoutes = lazy(() => import('./meta/Routes'));
    const NaloRoutes = lazy(() => import('./nalo/Routes'));

    useEffect(()=>{
        const tokenCheck = ()=>{ 
            const data = AuthService.storage.get();
            if(!data){
                clearInterval(tm);
                alert("세션이 만료되었습니다. 다시 로그인해 주세요.");
                document.location.href="/";
            }
        };
        const tm = setInterval(tokenCheck, 1000);
        return ()=>{ clearInterval(tm); }
    },[]);

    return (
        <Routes>
            <Route element={<PopupLayout />}>
                <Route path='naver/*' element={<SuspensedView><NaverRoutes /></SuspensedView>} />
                <Route path='kakao/*' element={<SuspensedView><KakaoRoutes /></SuspensedView>} />
                <Route path='google/*' element={<SuspensedView><GoogleRoutes /></SuspensedView>} />
                <Route path='gfa/*' element={<SuspensedView><GfaRoutes /></SuspensedView>} />
                <Route path='nosp/*' element={<SuspensedView><NospRoutes /></SuspensedView>} />
                <Route path='kakaomoment/*' element={<SuspensedView><KakaoMomentRoutes /></SuspensedView>} />
                <Route path='meta/*' element={<SuspensedView><MetaRoutes /></SuspensedView>} />
                <Route path='nalo/*' element={<SuspensedView><NaloRoutes /></SuspensedView>} />
                
                {/* Page Not Found */}
                <Route path='*' element={<Navigate to='/error/404' />} />
            </Route>
        </Routes>
    )
}

export default SubRoutes;
