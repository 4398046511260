import clsx from "clsx";
import { forwardRef, useEffect, useState } from "react";
import AdPloInput from "./AdPloInput";
import styles from './style.module.scss';

const AdPloSearch = forwardRef<HTMLInputElement, React.InputHTMLAttributes<HTMLInputElement> & {theme?:"dark"}>((props,ref)=>{
    const [value, setValue] = useState<string>("");
    const onChange = (e:React.ChangeEvent<HTMLInputElement>)=>{
        setValue(e.currentTarget.value);
        props.onChange?.(e);
    }
    const onRemove = ()=>{
        setValue("");
        props.onChange?.({ // onChange 이벤트 호출
            // ...new Event('input'),
            currentTarget: {value: ""},
            target: {value: ""},
        } as React.ChangeEvent<HTMLInputElement>);
    }

    useEffect(()=>{ setValue(props.defaultValue?.toString() || ""); }, [props.value]);

    return <div className={clsx('d-inline align-content-center', styles.search)}>
        <i className={clsx("bi bi-search", styles.search_icon)}/>
        {value.length > 0 && <i className={clsx("bi bi-x-lg", styles.remove_icon)} onClick={onRemove}/>}
        <AdPloInput {...props} value={value} onChange={onChange} ref={ref} style={{...(props.theme === "dark" && {backgroundColor:"#2b2b40", borderColor:"#323248", color:"#ffffff"})}} />
    </div>
});

export default AdPloSearch;