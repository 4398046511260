import HelperComponent from './HelperComponent';

/** NAVER GFA 캠페인 Helper */
export default class CampaignHelper extends HelperComponent{
    static path='/campaign';         //기본 Path
    static simple='/media/simple-campaigns'
    static stat='/campaigns/stat';   //실적데이터
    static performances='/explorer/campaigns';   //실적데이터 - Explorer용
    static downloadPath='/api/naversa/campaign';
}

