
import { StatReportResKeys, StatReportResType } from '../../../common/services/models/gfa/StatReportType';
import { CompareSelectOption } from '../../../modules/code/Options';
import { statDefaultProps, StatFormatter } from '../../modules/StatFormatter';
import { FilterType, HeaderColumn } from '../../modules/table/TableType';

// type : select, mSelect, selectList, selectText, selectDate, text

/**
 * Stat리포트 데이터 필요컬럼 확장
 */
export const StatDataExtends = (row:StatReportResType):StatReportResType => {
    return {
        ...row,
        ctr: StatFormatter.divide(row?.clicks,      row?.impressions,   100 )?.truncRound(2),
        cvr: StatFormatter.divide(row?.cost,        row?.conversions        )?.truncRound(2),
        vtr: StatFormatter.divide(row?.videoView,   row?.impressions,   100 )?.truncRound(2),
        roas:StatFormatter.divide(row?.conversionsValue, row?.cost,     100 )?.truncRound(2),
        cpc: StatFormatter.divide(row?.cost,        row?.clicks             )?.truncRound(),
        cpm: StatFormatter.divide(row?.cost,        row?.impressions,   1000)?.truncRound(),
        cpv: StatFormatter.divide(row?.cost,        row?.videoView          )?.truncRound(),
    }
}

export const GfaOxfordColumns:HeaderColumn[] = [
    { ...statDefaultProps, accessor: StatReportResKeys.impressions,     header: '노출수',       unit:'회', },
    { ...statDefaultProps, accessor: StatReportResKeys.clicks,          header: '클릭수',       unit:'회', },
    { ...statDefaultProps, accessor: StatReportResKeys.videoView,       header: '총 재생',      unit:'회', },
    { ...statDefaultProps, accessor: StatReportResKeys.cost,            header: '총 비용',      unit:'원', },
    { ...statDefaultProps, accessor: StatReportResKeys.conversions,     header: '결과',         unit:'회', },

    { ...statDefaultProps, accessor: StatReportResKeys.cvr,             header: '결과당 비용',   unit:'원', },
    { ...statDefaultProps, accessor: StatReportResKeys.ctr,             header: '클릭률(CTR)',   unit:'%', formatter: StatFormatter.roundPer },
    { ...statDefaultProps, accessor: StatReportResKeys.vtr,             header: '재생율(VTR)',   unit:'%', formatter: StatFormatter.roundPer },
    { ...statDefaultProps, accessor: StatReportResKeys.cpc,             header: '클릭당 비용(CPC)',   unit:'원', },
    { ...statDefaultProps, accessor: StatReportResKeys.cpm,             header: '1,000회 노출당 비용(CPM)',   unit:'원', },
    { ...statDefaultProps, accessor: StatReportResKeys.cpv,             header: '조회당 비용(CPV)',   unit:'원', },
    
];


/** 네이버 로컬모드(Tree, Label)용 실적컬럼 */
export const LocalGfaOxfordColumns:HeaderColumn[] = [
    ...GfaOxfordColumns.map((v):HeaderColumn=>{
        return v.accessor===StatReportResKeys.cost
        ? {...v, filter:{type:FilterType.number, options:CompareSelectOption, formatter:StatFormatter.addVatComma,}, useSort:true}  //비용 부가세 처리
        : {...v, filter:{type:FilterType.number, options:CompareSelectOption }, useSort:true,}
    })
];

