import { useState } from "react";
import { OptionType } from "../../../modules/types/Types";
import GoogleCode from "../../modules/code/GoogleCode";
import { GoogleService } from "../services";
import { AdGroupSimpleResType } from "../services/models/google/AdGroupTypes";
import { CampaignSimpleResType } from "../services/models/google/CampaignTypes";
import { MediaResResultType, TreeResDataType } from "../services/models/ResponseTypes";

export type GoogleTreeResType = TreeResDataType<CampaignSimpleResType,AdGroupSimpleResType>;

export interface GoogleTreeStateType{
    set: (data:GoogleTreeResType[])=>void;
    load: ()=> Promise<GoogleTreeResType[]>;
    data: GoogleTreeResType[];
}

const GoogleTreeStorage = class {
    public state = ():GoogleTreeStateType=>{
        const [data, setData] = useState<GoogleTreeResType[]>([]);
        const load = async ()=>{ return this.load().then((data)=>{setData(data); return data;}); }
        const set = (data:GoogleTreeResType[])=>{ setData(data); }
        return {set, load, data};
    }

    /** 데이터 로딩 */
    private async load():Promise<GoogleTreeResType[]>{
        const campaign:CampaignSimpleResType[] = await GoogleService.campaign.getSimpleList<MediaResResultType<CampaignSimpleResType>>()
        .then(((res) => {
            const data:CampaignSimpleResType[] = res.data.result || [];
            const types:string[] = GoogleCode.getOptions(GoogleCode.data.type.campaignType)
                    .filter((v:OptionType)=>v.data?.filter && v.data.filter.includes('campaign'))   // 캠페인 미제공 필터
                    .map((v)=>v.value);

            return data.filter(v=>!types.includes(v.objective)) //미제공 캠페인 타입 제외
                .sort((a,b)=>a.campaignName < b.campaignName ? -1 : 1 );
        }));
    
        const adgroup:AdGroupSimpleResType[] = await GoogleService.adgroup.getSimpleList<MediaResResultType<AdGroupSimpleResType>>()
        .then(((res) => {
            const data:AdGroupSimpleResType[] = res.data.result || [];
            return data.sort((a,b)=>a.adsetName < b.adsetName ? -1 : 1 );
        }));

        const types:string[] = GoogleCode.getOptions(GoogleCode.data.type.campaignType)
                .filter((v:OptionType)=>v.data?.filter && v.data.filter.includes('adgroup'))   // 캠페인 미제공 필터
                .map((v)=>v.value);

        const data:GoogleTreeResType[] = campaign
            .map((v)=>({
                data:v,
                children: types.includes(v.objective) ? [] : adgroup.filter((v2)=>v.campaignId===v2.campaignId),    //광고그룹 미제공 캠페인유형인 경우, 빈값 반환
            }));
        return data;
    }
}

export default GoogleTreeStorage;