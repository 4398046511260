declare global{
    interface Number{
        /**
         * 숫자의 앞을 0으로 채운 문자열로 반환
         * @param len '0'으로 채울 전체 문자열 길이
         * @returns '0'으로 자리수까지 채워진 숫자를 반환합니다.
         */
        zeroFill(len:number):string;

        /**
         * 3자리수마다 콤마를 추가하여 반환합니다.
         */
        addComma():string;

        /**
         * 값의 범위를 체크합니다.
         * @param min 최소값
         * @param max 최대값
         * @param digits 자릿수 - 예)1000단위 입력시 : 3 
         */
        rangeValid(min:number, max:number, digits?:number):boolean;

        /**
         * 지정자리 수 기준으로 반올림합니다.
         * @param digit 자릿수 default=0
         */
        round(digit?:number):number;

        /** 해당 자리 수이하는 버림*/
        trunc(digit?:number):number;

        /** 자릿수 직전까지만 남기고 버린 뒤 반올림*/
        truncRound(digit?:number):number;

        /**
         * 해당 수를 제곱하여 반환합니다.
         * Math.pow 보다 정확합니다. Math.pow(10, -5) --> 0.000009999999999999999
         * @param square 제곱수
         */
        pow(square:number):number;

        formatBytes(decimals?:number):string;

        /** 최대 공약수 계산 (유클리드 호제법 사용) */
        gcd(unit:number):Number;

        /** 최소 공배수 계산 */
        lcm(unit:number):Number;
    }
}

// eslint-disable-next-line no-extend-native
Number.prototype.zeroFill = function(len:number){
    const val = this.toString();
    return "0".repeat( Math.max(len-val.length, 0)) + val;
};

// eslint-disable-next-line no-extend-native
Number.prototype.addComma = function(){
    const value = this.toString().split('.');
    //eslint-disable-next-line
    value[0] = value[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return value.join('.');
};

// eslint-disable-next-line no-extend-native
Number.prototype.rangeValid = function(min:number, max:number, digits?:number) {
    const pow = digits && digits > 0 ? Math.pow(10, digits) : 1;
    const num:number = Number(this);
    return (num >= min && num <= max) && (pow===undefined || (num % pow ===0) );
}

// eslint-disable-next-line no-extend-native
Number.prototype.round = function(digit = 0){
    return Math.round(Number(this) * Math.pow(10,digit)) / Math.pow(10,digit); 
}

// eslint-disable-next-line no-extend-native
Number.prototype.trunc = function(digit = 0){
    return Math.trunc(Number(this) * Math.pow(10,digit)) / Math.pow(10,digit); 
}

// eslint-disable-next-line no-extend-native
Number.prototype.truncRound = function(digit = 0){
    return this.trunc(digit+1).round(digit); 
}

// eslint-disable-next-line no-extend-native
Number.prototype.pow = function(square){
    if(!square){ return 1; }
    const k = Math.abs(square);
    const value = Math.pow(Number(this), k);
    return square<0 ? 1/value : 1 * value; 
}

// eslint-disable-next-line no-extend-native
Number.prototype.formatBytes = function(decimals = 2) {
    if (this === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const num:number = Number(this);
    const i = Math.floor(Math.log(num) / Math.log(k));
    return parseFloat((num / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}


// 최대 공약수 계산 (유클리드 호제법 사용)
// eslint-disable-next-line no-extend-native
Number.prototype.gcd = function(unit:number) {
    return gcd( (this).toString().toNumber(), unit);
}
  
// 최소 공배수 계산
// eslint-disable-next-line no-extend-native
Number.prototype.lcm = function(unit:number) {
    return lcm( (this).toString().toNumber(), unit);
}


// 최대 공약수 계산 (유클리드 호제법 사용)
function gcd(a:number, b:number):number {
    return b === 0 ? a : gcd(b, a % b);
}
  
// 최소 공배수 계산
function lcm(a:number, b:number):number {
    return (a * b) / gcd(a, b);
}

export { };

