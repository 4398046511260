import { StatReportResKeys, StatReportResType } from '../../../common/services/models/naver/StatReportType';
import { CompareSelectOption } from '../../../modules/code/Options';
import { SortKeys } from '../../modules/table/Table';
import { FilterType, HeaderColumn } from '../../modules/table/TableType';

// type : select, mSelect, selectList, selectText, selectDate, text

/**
 * Stat리포트 데이터 필요컬럼 확장
 */
export const StatDataExtends = (row:StatReportResType):StatReportResType => {
    const cost = addVat(row?.adCost);
    const purchaseCount     = [row?.dcPurchaseCount,        row?.icPurchaseCount].sum();
    const purchaseValue     = [row?.dcPurchaseValue,        row?.icPurchaseValue].sum();
    const subscriptionCount = [row?.dcSubscriptionCount,    row?.icSubscriptionCount].sum();
    const subscriptionValue = [row?.dcSubscriptionValue,    row?.icSubscriptionValue].sum();
    return {
        ...row,
        purchaseCount,
        purchaseValue,
        subscriptionCount,
        subscriptionValue,
        averageAdRank:      StatFormatter.divide(row?.adRank,       row?.impressionCount        )?.round(1),
        clickThroughRate:   StatFormatter.divide(row?.clickCount,   row?.impressionCount,   100 )?.round(1),
        costPerClick:       StatFormatter.divide(cost,              row?.clickCount             )?.round(0),
        conversionRate:     StatFormatter.divide(purchaseCount,     row?.clickCount,        100 )?.round(1),
        costPerAction:      StatFormatter.divide(cost,              purchaseCount               )?.round(0),
        costPerSubscription:StatFormatter.divide(cost,              subscriptionCount           )?.round(0),
        returnOnAdSpend:    StatFormatter.divide(subscriptionValue, cost,                   100 )?.round(0),

        cartCount:          [row?.dcCartCount,          row?.icCartCount].sum(),
        cartValue:          [row?.dcCartValue,          row?.icCartValue].sum(),
        advPurchaseCount:   [row?.dcAdvPurchaseCount,   row?.icAdvPurchaseCount].sum(),
        advPurchaseValue:   [row?.dcAdvPurchaseValue,   row?.icAdvPurchaseValue].sum(),
        othersCount:        [row?.dcOthersCount,        row?.icOthersCount].sum(),
        othersValue:        [row?.dcOthersValue,        row?.icOthersValue].sum(),
    }
}



export const addVat = (value?:number):number => {
    return  Math.round((value || 0) * 1.1);
}
// 이동 예정
export class StatFormatter{
    static comma    (value?:number):string{ return value?.round().addComma() || '0'; }
    static commaPer (value?:number):string{ if(value){ value *= 100; } return (value?.round() || 0).addComma()+'%'; }
    static round    (value?:number):string{ return (value?.round(1) || 0).addComma(); }
    static roundPer (value?:number):string{ if(value){ value *= 100; } return (value?.round(1) || 0).addComma()+'%'; }

    /**
     * 인자값 나눗셈을 합니다. - undefined 처리
     * @param value 값
     * @param by 나누기
     * @param times 값에 곱 - 퍼센트 처리 시 100을 입력합니다.
     * @returns 
     */
    static divide(value?:number, by?:number, times?:number):number{
        if(value===undefined || !by){ return 0; }
        if(times){ value *= times; }
        return value/by;
    }

    static purchaseCount(value?:number, row?:StatReportResType, index?:number):string{
        if(!row?.dcPurchaseCount && !row?.icPurchaseCount){ return '0'; }
        return StatFormatter.comma([row?.dcPurchaseCount, row?.icPurchaseCount].sum());
    }
    static purchaseValue(value?:number, row?:StatReportResType, index?:number):string{
        if(!row?.dcPurchaseValue && !row?.icPurchaseValue){ return '0'; }
        return StatFormatter.comma([row?.dcPurchaseValue, row?.icPurchaseValue].sum());
    }

    static subscriptionCount(value?:number, row?:StatReportResType, index?:number):string{
        if(!row?.dcSubscriptionCount && !row?.icSubscriptionCount){ return '0'; }
        return StatFormatter.comma([row?.dcSubscriptionCount, row?.icSubscriptionCount].sum());
    }
    
    static averageAdRank(value?:number, row?:StatReportResType, index?:number):string{
        return StatFormatter.round(StatFormatter.divide(row?.adRank, row?.impressionCount));
    }
    static clickThroughRate(value?:number, row?:StatReportResType, index?:number):string{
        return StatFormatter.roundPer( StatFormatter.divide(row?.clickCount, row?.impressionCount) );
    }
    static costPerClick(value?:number, row?:StatReportResType, index?:number):string{
        return StatFormatter.comma( StatFormatter.divide(addVat(row?.adCost), row?.clickCount) );
    }
    static conversionRate(value?:number, row?:StatReportResType, index?:number):string{
        return StatFormatter.roundPer( StatFormatter.divide([row?.dcPurchaseCount, row?.icPurchaseCount].sum(), row?.clickCount) );
    }
    static costPerAction(value?:number, row?:StatReportResType, index?:number):string{
        return StatFormatter.comma( StatFormatter.divide(addVat(row?.adCost), [row?.dcPurchaseCount, row?.icPurchaseCount].sum()) );
    }
    static costPerSubscription(value?:number, row?:StatReportResType, index?:number):string{
        return StatFormatter.comma( StatFormatter.divide(addVat(row?.adCost), [row?.dcSubscriptionCount, row?.icSubscriptionCount].sum()) );
    }
    static returnOnAdSpend(value?:number, row?:StatReportResType, index?:number):string{
        return StatFormatter.commaPer( StatFormatter.divide([row?.dcPurchaseValue, row?.icPurchaseValue].sum(), addVat(row?.adCost)) );
    }

    static addVatComma(value?:number, row?:StatReportResType, index?:number):string{
        return addVat(value)?.addComma();
    }
}

const headerStyle:React.CSSProperties = {};//{backgroundColor:'rgba( var(--bs-warning-rgb), 0.1)'};
const colStyle:React.CSSProperties ={minWidth:'100px', textAlign: 'right'};
export const defaultProps:HeaderColumn = {
    accessor:'',
    colGroup:'성과 Data',
    style:colStyle,
    headerStyle:headerStyle,
    useSort:false,
    formatter: StatFormatter.comma,
    defaultSortDirection:SortKeys.Desc, //기본 정렬 내림차순 
}

export const NaverOxfordColumns:HeaderColumn[] = [
    { ...defaultProps, accessor: StatReportResKeys.impressionCount,      header: '노출수',        unit:'회', },
    { ...defaultProps, accessor: StatReportResKeys.clickCount,           header: '클릭수',        unit:'회', },

    { ...defaultProps, accessor: StatReportResKeys.adCost,               header: '비용',        unit:'원', formatter: StatFormatter.addVatComma, },

    { ...defaultProps, group: {key:'purchaseCount', header:'구매전환수'},  accessor: StatReportResKeys.purchaseCount,        header: '합계', unit:'회', },
    { ...defaultProps, group: {key:'purchaseCount', header:'구매전환수'},  accessor: StatReportResKeys.dcPurchaseCount,      header: '직접', unit:'회', },
    { ...defaultProps, group: {key:'purchaseCount', header:'구매전환수'},  accessor: StatReportResKeys.icPurchaseCount,      header: '간접', unit:'회', },

    { ...defaultProps, group: {key:'purchaseValue', header:'구매전환매출'},  accessor: StatReportResKeys.purchaseValue,        header: '합계', unit:'원', formatter: StatFormatter.purchaseValue, },
    { ...defaultProps, group: {key:'purchaseValue', header:'구매전환매출'}, accessor: StatReportResKeys.dcPurchaseValue,      header: '직접',  unit:'원', },
    { ...defaultProps, group: {key:'purchaseValue', header:'구매전환매출'},  accessor: StatReportResKeys.icPurchaseValue,      header: '간접',  unit:'원', },

    { ...defaultProps, group: {key:'subscriptionCount', header:'회원가입수'}, accessor: StatReportResKeys.subscriptionCount,    header: '합계', unit:'회', formatter: StatFormatter.subscriptionCount, },
    { ...defaultProps, group: {key:'subscriptionCount', header:'회원가입수'}, accessor: StatReportResKeys.dcSubscriptionCount,  header: '직접', unit:'회', isHide:true, },
    { ...defaultProps, group: {key:'subscriptionCount', header:'회원가입수'}, accessor: StatReportResKeys.icSubscriptionCount,  header: '간접', unit:'회', isHide:true, },

    { ...defaultProps, group: {key:'subscriptionValue', header:'회원가입 전환가치'}, accessor: StatReportResKeys.subscriptionValue,    header: '합계', isHide:true, },
    { ...defaultProps, group: {key:'subscriptionValue', header:'회원가입 전환가치'}, accessor: StatReportResKeys.dcSubscriptionValue,  header: '직접', isHide:true, },
    { ...defaultProps, group: {key:'subscriptionValue', header:'회원가입 전환가치'}, accessor: StatReportResKeys.icSubscriptionValue,  header: '간접', isHide:true, },


    { ...defaultProps, group: {key:'cartCount', header:'장바구니담기전환수'},  accessor: StatReportResKeys.cartCount,            header: '합계', unit:'회', isHide:true, },
    { ...defaultProps, group: {key:'cartCount', header:'장바구니담기전환수'},  accessor: StatReportResKeys.dcCartCount,          header: '직접', unit:'회', isHide:true, },
    { ...defaultProps, group: {key:'cartCount', header:'장바구니담기전환수'},  accessor: StatReportResKeys.icCartCount,          header: '간접', unit:'회', isHide:true, },

    { ...defaultProps, group: {key:'cartValue', header:'장바구니담기 전환가치'},  accessor: StatReportResKeys.cartValue,            header: '합계', isHide:true, },
    { ...defaultProps, group: {key:'cartValue', header:'장바구니담기 전환가치'},  accessor: StatReportResKeys.dcCartValue,          header: '직접', isHide:true, },
    { ...defaultProps, group: {key:'cartValue', header:'장바구니담기 전환가치'},  accessor: StatReportResKeys.icCartValue,          header: '간접', isHide:true, },

    { ...defaultProps, group: {key:'advPurchaseCount', header:'신청예약전환수'},  accessor: StatReportResKeys.advPurchaseCount,     header: '합계', unit:'회', isHide:true, },
    { ...defaultProps, group: {key:'advPurchaseCount', header:'신청예약전환수'},  accessor: StatReportResKeys.dcAdvPurchaseCount,   header: '직접', unit:'회', isHide:true, },
    { ...defaultProps, group: {key:'advPurchaseCount', header:'신청예약전환수'},  accessor: StatReportResKeys.icAdvPurchaseCount,   header: '간접', unit:'회', isHide:true, },

    { ...defaultProps, group: {key:'advPurchaseValue', header:'신청예약 전환가치'},  accessor: StatReportResKeys.advPurchaseValue,     header: '합계', isHide:true, },
    { ...defaultProps, group: {key:'advPurchaseValue', header:'신청예약 전환가치'},  accessor: StatReportResKeys.dcAdvPurchaseValue,   header: '직접', isHide:true, },
    { ...defaultProps, group: {key:'advPurchaseValue', header:'신청예약 전환가치'},  accessor: StatReportResKeys.icAdvPurchaseValue,   header: '간접', isHide:true, },

    { ...defaultProps, group: {key:'othersCount', header:'기타전환수'},  accessor: StatReportResKeys.othersCount,               header: '합계', unit:'회', isHide:true, },
    { ...defaultProps, group: {key:'othersCount', header:'기타전환수'},  accessor: StatReportResKeys.dcOthersCount,             header: '직접', unit:'회', isHide:true, },
    { ...defaultProps, group: {key:'othersCount', header:'기타전환수'},  accessor: StatReportResKeys.icOthersCount,             header: '간접', unit:'회', isHide:true, },

    { ...defaultProps, group: {key:'othersValue', header:'기타 전환가치'},  accessor: StatReportResKeys.othersValue,          header: '합계', isHide:true, },
    { ...defaultProps, group: {key:'othersValue', header:'기타 전환가치'},  accessor: StatReportResKeys.dcOthersValue,        header: '직접', isHide:true, },
    { ...defaultProps, group: {key:'othersValue', header:'기타 전환가치'},  accessor: StatReportResKeys.icOthersValue,        header: '간접', isHide:true, },

    { ...defaultProps, accessor: StatReportResKeys.averageAdRank,        header: '평균노출순위',   unit:'위', formatter: StatFormatter.averageAdRank, },
    { ...defaultProps, accessor: StatReportResKeys.clickThroughRate,     header: '클릭률(CTR)',  unit:'%', formatter: StatFormatter.clickThroughRate, },
    { ...defaultProps, accessor: StatReportResKeys.costPerClick,         header: '클릭당 평균비용(CPC)', unit:'원', formatter: StatFormatter.costPerClick, style:{...defaultProps.style, minWidth:'13em', }},
    { ...defaultProps, accessor: StatReportResKeys.conversionRate,       header: '전환율(CVR)',   unit:'%', formatter: StatFormatter.conversionRate, },
    { ...defaultProps, accessor: StatReportResKeys.costPerAction,        header: '전환당 비용(CPA)', unit:'원', formatter: StatFormatter.costPerAction, style:{...defaultProps.style, minWidth:'11em', }},
    { ...defaultProps, accessor: StatReportResKeys.costPerSubscription,  header: '회원가입당 비용', unit:'원', formatter: StatFormatter.costPerSubscription, style:{...defaultProps.style, minWidth:'9em', } },
    { ...defaultProps, accessor: StatReportResKeys.returnOnAdSpend,      header: '광고수익률(ROAS)', unit:'%', formatter: StatFormatter.returnOnAdSpend, style:{...defaultProps.style, minWidth:'11em', }},
];

const localVatFormatter = (value: number, row: StatReportResType)=>StatFormatter.addVatComma(value,row) || 0;

/** 네이버 로컬모드(Tree, Label)용 실적컬럼 */
export const LocalNaverOxfordColumns:HeaderColumn[] = [
    ...NaverOxfordColumns.map((v):HeaderColumn=>{
        return v.accessor===StatReportResKeys.adCost
        ? {...v, filter:{type:FilterType.number, options:CompareSelectOption, formatter:localVatFormatter,}, useSort:true}  //비용 부가세 처리
        : {...v, filter:{type:FilterType.number, options:CompareSelectOption }, useSort:true,}
    })
];

/** 네이버 로컬모드(Tree, Label)용 실적컬럼 + 확장(동영상조회수) */
export const LocalNaverOxfordExtColumns:HeaderColumn[] = [
    ...LocalNaverOxfordColumns,
    {...defaultProps, accessor: StatReportResKeys.videoCount, header: '동영상조회수', unit:'회', formatter: StatFormatter.comma, 
    filter:{type:FilterType.number, options:CompareSelectOption }, useSort:true},
];
