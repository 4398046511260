import { StatReportResKeys, StatReportResType } from '../../../common/services/models/kakao/StatReportType';
import { CompareSelectOption } from '../../../modules/code/Options';
import { statDefaultProps } from '../../modules/StatFormatter';
import { FilterType, HeaderColumn } from '../../modules/table/TableType';

// type : select, mSelect, selectList, selectText, selectDate, text

const StatFormatter = {
    comma:(value?:number):string =>     { return (value?.round() || 0).addComma(); },
    commaPer:(value?:number):string =>  { return (value?.round() || 0).addComma()+'%'; },
    round:(value?:number):string =>     { return (value?.round(1) || 0).addComma(); },
    roundPer:(value?:number):string =>  { return (value?.round(1) || 0).addComma()+'%'; }, 
    
    /**
    * 인자값 나눗셈을 합니다. - undefined 처리
    * @param value 값
    * @param by 나누기
    * @param times 값에 곱 - 퍼센트 처리 시 100을 입력합니다.
    * @returns 
    */
    divide:(value?:number, by?:number, times?:number):number => {
        if(value===undefined || !by){ return 0; }
        if(times){ value *= times; }
        return value/by;
    }
}

/**
 * Stat리포트 데이터 필요컬럼 확장
 */
export const StatDataExtends = (row:StatReportResType):StatReportResType => {
    return {
        ...row,
        // purchaseCount: [row?.dcPurchaseCount, row?.icPurchaseCount].sum(),
        // purchaseValue: [row?.dcPurchaseValue, row?.icPurchaseValue].sum(),
        averageAdRank:      StatFormatter.divide(row?.rankImpression,   row?.impressionCount        ).round(1),
        clickThroughRate:   StatFormatter.divide(row?.clickCount,       row?.impressionCount,   100 ).round(1),
        costPerClick:       StatFormatter.divide(row?.adCost,           row?.clickCount             ).round(0),
        conversionRate:     StatFormatter.divide(row?.cvPurchase7D,     row?.clickCount,        100 ).round(1),
        costPerAction:      StatFormatter.divide(row?.adCost,           row?.cvPurchase7D           ).round(0),
        costPerSubscription:StatFormatter.divide(row?.adCost,           row?.cvRegistration7D       ).round(0),
        returnOnAdSpend:    StatFormatter.divide(row?.cvPurchasePrice7D,row?.adCost,            100 ).round(0),
    }
}

export const KakaoOxfordColumns:HeaderColumn[] = [
    { ...statDefaultProps, accessor: StatReportResKeys.impressionCount,  header: '노출수',         unit:'회', },
    { ...statDefaultProps, accessor: StatReportResKeys.clickCount,       header: '클릭수',         unit:'회', },
    { ...statDefaultProps, accessor: StatReportResKeys.adCost,           header: '비용',           unit:'원', },
    { ...statDefaultProps, accessor: StatReportResKeys.cvPurchase7D,     header: '구매전환수(7일)',  unit:'회', },
    { ...statDefaultProps, accessor: StatReportResKeys.cvPurchase1D,     header: '구매전환수(1일)',  unit:'회', },
    { ...statDefaultProps, accessor: StatReportResKeys.cvRegistration7D, header: '회원가입수(7일)',  unit:'회', },
    { ...statDefaultProps, accessor: StatReportResKeys.cvRegistration1D, header: '회원가입수(1일)',  unit:'회', },
    { ...statDefaultProps, accessor: StatReportResKeys.cvPurchasePrice7D,header: '구매전환매출(7일)', unit:'원', },
    { ...statDefaultProps, accessor: StatReportResKeys.cvPurchasePrice1D,header: '구매전환매출(1일)', unit:'원', },
    { ...statDefaultProps, accessor: StatReportResKeys.averageAdRank,    header: '평균노출순위',     unit:'위', formatter: StatFormatter.round, },
    { ...statDefaultProps, accessor: StatReportResKeys.clickThroughRate, header: '클릭률(CTR)',      unit:'%',formatter: StatFormatter.roundPer, },
    { ...statDefaultProps, accessor: StatReportResKeys.costPerClick,     header: '클릭당 평균비용(CPC)',unit:'원',  style:{minWidth:'150px', textAlign: 'right'}},
    { ...statDefaultProps, accessor: StatReportResKeys.conversionRate,   header: '전환율(CVR)',      unit:'%',formatter: StatFormatter.roundPer, },
    { ...statDefaultProps, accessor: StatReportResKeys.costPerAction,    header: '전환당 비용(CPA)', unit:'원',  },
    { ...statDefaultProps, accessor: StatReportResKeys.costPerSubscription,header: '회원가입당 비용',  unit:'원', },
    { ...statDefaultProps, accessor: StatReportResKeys.returnOnAdSpend,  header: '광고수익률(ROAS)',  unit:'%', formatter: StatFormatter.commaPer, },
    { ...statDefaultProps, accessor: StatReportResKeys.cvAppInstall7D,   header: '앱 설치(7일)',     unit:'회', },
    { ...statDefaultProps, accessor: StatReportResKeys.cvAppInstall1D,   header: '앱 설치(1일)',     unit:'회',},
    { ...statDefaultProps, accessor: StatReportResKeys.cvViewCart7D,     header: '장바구니 보기(7일)', unit:'회',},
    { ...statDefaultProps, accessor: StatReportResKeys.cvViewCart1D,     header: '장바구니 보기(1일)', unit:'회',},
    { ...statDefaultProps, accessor: StatReportResKeys.cvParticipation7D,header: '잠재고객(7일)',    unit:'회',},
    { ...statDefaultProps, accessor: StatReportResKeys.cvParticipation1D,header: '잠재고객(1일)',    unit:'회',},
    { ...statDefaultProps, accessor: StatReportResKeys.cvSignup7D,       header: '서비스 신청(7일)',  unit:'회',},
    { ...statDefaultProps, accessor: StatReportResKeys.cvSignup1D,       header: '서비스 신청(1일)',  unit:'회',},
];


export const LocalKakaoOxfordColumns:HeaderColumn[] = [
    ...KakaoOxfordColumns.map((v):HeaderColumn=>({
        ...v,
        filter:{type:FilterType.number, options:CompareSelectOption, },
        useSort:true,
    }))
];