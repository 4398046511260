import { StatReportResKeys, StatReportResType } from "./StatReportType";

/**
 * GFA Ad Response Type
 */
export interface AdResType extends Omit<StatReportResType, 'key'>{
    adId: string;
    adName: string;
    adGroupId: string;
    adGroupName: string;
    campaignId: string;
    campaignName: string;
    creativeType: string;
    creativeTypeName: string;
    creativeId: string;
    imageUrl: string;
    objective: string;
    objectiveName: string;
    pricingName: string;
    createdDatetime: string;
}

/**
 * GFA Ad Response 컬럼 키
 */
export enum AdResKeys{
    adId = "adId",
    adName = "adName",
    adGroupId = "adGroupId",
    adGroupName = "adGroupName",
    campaignId = "campaignId",
    campaignName = "campaignName",
    creativeType = "creativeType",
    creativeTypeName = "creativeTypeName",
    creativeId = "creativeId",
    imageUrl = "imageUrl",
    objective = "objective",
    objectiveName = "objectiveName",
    pricingName = "pricingName",
    createdDatetime = "createdDatetime",
}


export const AdKeys = {...AdResKeys, ...StatReportResKeys};