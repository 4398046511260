import clsx from 'clsx';
import { omit } from 'lodash';
import { CSSProperties } from 'react';
import styles from './ellisis.module.scss';

interface EllipsisProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>{
    layerClassName?:string;
    layerStyle?:CSSProperties;

}
const Ellipsis:React.FC<EllipsisProps> = (props)=>{
    return <div className={props.layerClassName} style={{display:'inline-flex', ...props.layerStyle}}>
        <div {...omit(props, ['layerClassName', 'layerStyle'])} className={clsx(styles.ellisis, props.className)}></div>
    </div>
}

export default Ellipsis;