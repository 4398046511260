
import { StatReportResKeys, StatReportResType } from '../../../common/services/models/meta/StatReportType';
import { CompareSelectOption } from '../../../modules/code/Options';
import { statDefaultProps, StatFormatter } from '../../modules/StatFormatter';
import { FilterType, HeaderColumn } from '../../modules/table/TableType';

// type : select, mSelect, selectList, selectText, selectDate, text

/**
 * Stat리포트 데이터 필요컬럼 확장
 */
export const StatDataExtends = (row:StatReportResType):StatReportResType => {
    return {
        ...row,
        ctr:                    StatFormatter.divide(row?.clicks,       row?.impressions,   100 )?.truncRound(2),
        cpc:                    StatFormatter.divide(row?.cost,         row?.clicks             )?.truncRound(2),
        cpm:                    StatFormatter.divide(row?.cost,         row?.impressions,   1000)?.truncRound(),
        cpr:                    StatFormatter.divide(row?.cost,         row?.reach,         1000)?.truncRound(),
        uniqueCtr:              StatFormatter.divide(row?.uniqueClicks, row?.reach,         100 )?.truncRound(2),
        costPerUniqueClick:     StatFormatter.divide(row?.cost,         row?.uniqueClicks       )?.truncRound(),
        costPerPageLike:        StatFormatter.divide(row?.cost,         row?.like               )?.truncRound(),
        costPerVideoView3s:     StatFormatter.divide(row?.cost,         row?.videoPlay3s        )?.truncRound(),
        costPerVideoView15s:    StatFormatter.divide(row?.cost,         row?.thruPlay           )?.truncRound(),
        costPerInstall:         StatFormatter.divide(row?.cost,         row?.installs           )?.truncRound(),

        //Explorer
        cvr:                    StatFormatter.divide(row?.purchase,     row?.clicks             )?.truncRound(3),
        vtr:                    StatFormatter.divide(row?.videoViewStart,row?.impressions,  100 )?.truncRound(3),
        roas:                   StatFormatter.divide(row?.purchaseValue,row?.cost,          100 )?.truncRound(),
        cpv:                    StatFormatter.divide(row?.cost,         row?.videoViewStart     )?.truncRound(),
    }
}

export const OxfordColumns:HeaderColumn[] = [
    { ...statDefaultProps, accessor: StatReportResKeys.clicks,                  header: '클릭(전체)',            unit:'회', },
    { ...statDefaultProps, accessor: StatReportResKeys.frequency,               header: '빈도',                 unit:'회', formatter: StatFormatter.round},
    { ...statDefaultProps, accessor: StatReportResKeys.impressions,             header: '노출',                 unit:'회', },
    { ...statDefaultProps, accessor: StatReportResKeys.reach,                   header: '도달',                 unit:'회', },
    { ...statDefaultProps, accessor: StatReportResKeys.cost,                    header: '지출 금액',             unit:'원', },

    { ...statDefaultProps, accessor: StatReportResKeys.ctr,                     header: 'CTR(전체)',          unit:'%', formatter: StatFormatter.roundPer,},
    { ...statDefaultProps, accessor: StatReportResKeys.cpc,                     header: 'CPC(전체)',            unit:'원', },
    { ...statDefaultProps, accessor: StatReportResKeys.cpm,                     header: 'CPM(1,000회 노출당 비용)',   unit:'원', },
    { ...statDefaultProps, accessor: StatReportResKeys.cpr,                     header: '계정 센터 계정 1,000개 도달당 비용',   unit:'원', },


    { ...statDefaultProps, accessor: StatReportResKeys.uniqueClicks,            header: '고유 클릭(전체)',      unit:'회', },
    { ...statDefaultProps, accessor: StatReportResKeys.linkClick,               header: '링크 클릭',            unit:'회', },
    { ...statDefaultProps, accessor: StatReportResKeys.postEngagement,          header: '게시물 참여',          unit:'회', },
    { ...statDefaultProps, accessor: StatReportResKeys.postComment,             header: '게시물 댓글',          unit:'회', },
    { ...statDefaultProps, accessor: StatReportResKeys.postSave,                header: '게시물 저장',          unit:'회', },
    { ...statDefaultProps, accessor: StatReportResKeys.postShare,               header: '게시물 공유',          unit:'회', },
    { ...statDefaultProps, accessor: StatReportResKeys.postReaction,            header: '게시물 공감',          unit:'회', },
    { ...statDefaultProps, accessor: StatReportResKeys.pageEngagement,          header: '페이지 참여',          unit:'회', },
    { ...statDefaultProps, accessor: StatReportResKeys.like,                    header: '팔로우 또는 좋아요',      unit:'회', },
    { ...statDefaultProps, accessor: StatReportResKeys.photoView,               header: '사진 조회',            unit:'회', },

    { ...statDefaultProps, accessor: StatReportResKeys.videoViewStart,          header: '동영상 재생', unit:'회', },
    { ...statDefaultProps, group:{key:"videoView", header:"동영상 재생"}, accessor: StatReportResKeys.videoView25,  header: '25%', unit:'회', },
    { ...statDefaultProps, group:{key:"videoView", header:"동영상 재생"}, accessor: StatReportResKeys.videoView50,  header: '50%', unit:'회', },
    { ...statDefaultProps, group:{key:"videoView", header:"동영상 재생"}, accessor: StatReportResKeys.videoView75,  header: '75%', unit:'회', },
    { ...statDefaultProps, group:{key:"videoView", header:"동영상 재생"}, accessor: StatReportResKeys.videoView95,  header: '95%', unit:'회', },
    { ...statDefaultProps, group:{key:"videoView", header:"동영상 재생"}, accessor: StatReportResKeys.videoViewEnd,  header: '100%', unit:'회', },
    { ...statDefaultProps, accessor: StatReportResKeys.videoPlay3s,             header: '3초 재생수', unit:'회', },
    { ...statDefaultProps, accessor: StatReportResKeys.thruPlay,                header: 'ThruPlay', unit:'회', },

    { ...statDefaultProps, accessor: StatReportResKeys.uniqueCtr,               header: '고유 CTR(전체)',            unit:'%', formatter: StatFormatter.roundPer,},
    { ...statDefaultProps, accessor: StatReportResKeys.costPerUniqueClick,      header: '고유 클릭당 비용(전체)',       unit:'원', },
    { ...statDefaultProps, accessor: StatReportResKeys.costPerPageLik,          header: '팔로우 또는 좋아요당 비용',     unit:'원', },
    { ...statDefaultProps, accessor: StatReportResKeys.costPerVideoView3s,      header: '동영상 3초 이상 재생당 비용',   unit:'원', },
    { ...statDefaultProps, accessor: StatReportResKeys.costPerVideoView15s,     header: 'ThruPlay당 비용',      unit:'원', },

    { ...statDefaultProps, accessor: StatReportResKeys.purchase,                    header: '구매',               unit:'회', },
    { ...statDefaultProps, accessor: StatReportResKeys.purchaseValue,               header: '구매 전환값',          unit:'원', },
    { ...statDefaultProps, accessor: StatReportResKeys.webPurchase,                 header: '웹사이트 구매',        unit:'회', },
    { ...statDefaultProps, accessor: StatReportResKeys.webPurchaseValue,            header: '웹사이트 구매 전환깂',   unit:'원', },
    { ...statDefaultProps, accessor: StatReportResKeys.appMobilePurchase,           header: '앱 내 구매',          unit:'회', },
    { ...statDefaultProps, accessor: StatReportResKeys.appMobilePurchaseValue,      header: '앱 내 구매 전환값',     unit:'원', },
    { ...statDefaultProps, accessor: StatReportResKeys.facebookPurchase,            header: 'Meta 구매',          unit:'회', },
    { ...statDefaultProps, accessor: StatReportResKeys.facebookPurchaseValue,       header: 'Meta 구매 전환값',    unit:'원', },
    { ...statDefaultProps, accessor: StatReportResKeys.completeRegistration,        header: '등록 완료',           unit:'회', },
    { ...statDefaultProps, accessor: StatReportResKeys.webCompleteRegistration,     header: '웹사이트 등록 완료',    unit:'회', },
    { ...statDefaultProps, accessor: StatReportResKeys.appMobileCompleteRegistration,header: '앱 내 등록 완료',     unit:'회', },
    { ...statDefaultProps, accessor: StatReportResKeys.installs,                    header: '앱 설치',             unit:'회', },
    { ...statDefaultProps, accessor: StatReportResKeys.installsPc,                  header: '데스크톱 앱 설치',      unit:'회', },
    { ...statDefaultProps, accessor: StatReportResKeys.installsMobile,              header: '모바일 앱 설치',        unit:'회', },
    { ...statDefaultProps, accessor: StatReportResKeys.addToCart,                   header: '장바구니에 담기',        unit:'회', },
    { ...statDefaultProps, accessor: StatReportResKeys.webAddToCart,                header: '웹사이트 장바구니에 담기', unit:'회', },
    { ...statDefaultProps, accessor: StatReportResKeys.appMobileAddToCart,          header: '앱 내 장바구니에 담기',   unit:'회', },
    { ...statDefaultProps, accessor: StatReportResKeys.activateApp,                 header: '앱 활성화',            unit:'회', },
    { ...statDefaultProps, accessor: StatReportResKeys.appMobileActivateApp,        header: '앱 내 세션',           unit:'회', },
    { ...statDefaultProps, accessor: StatReportResKeys.costPerInstall,              header: '앱 설치당 비용',        unit:'회', },
];


export const ExplorerOxfordColumns:HeaderColumn[] = [
    { ...statDefaultProps, accessor: StatReportResKeys.cvr,     header: 'CVR(구매 전환율)',        unit:'%', formatter: StatFormatter.roundPer,},
    { ...statDefaultProps, accessor: StatReportResKeys.vtr,     header: 'VTR(조회율)',            unit:'%', formatter: StatFormatter.roundPer,},
    { ...statDefaultProps, accessor: StatReportResKeys.roas,    header: 'ROAS',                 unit:'%', formatter: StatFormatter.commaPer,},
    { ...statDefaultProps, accessor: StatReportResKeys.cpv,     header: 'CPV(조회 당 비용)',       unit:'원', },
];


/** 네이버 로컬모드(Tree, Label)용 실적컬럼 */
export const LocalOxfordColumns:HeaderColumn[] = [
    ...OxfordColumns.map((v):HeaderColumn=>({...v, filter:{type:FilterType.number, options:CompareSelectOption }, useSort:true,}))
];

