import clsx from 'clsx';
import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import { DashBoardStatTableClientResType, DatshBoardStatTableType } from '../../../common/services/models/DashboardTypes';
import AdminCode from '../../../modules/code/AdminCode';
import { ButtonDropDown } from '../../modules/dropdown/ButtonDropDown';
import MediaTypeIcon from '../../modules/symbol/MediaTypeIcon';
import { CircleChartItemType } from './CircleChart';
import { SelectTypeKey } from './ReportCard';


interface MediaClientType extends CircleChartItemType{
    clients?:DashBoardStatTableClientResType[]; 
    code?:string;
    type: string;
}

export interface MediaTypeButtonProps{
    variant?: string;
    className?: string;
    style?: CSSProperties;
    children?: React.ReactNode;
    onChange?:(value:string, type?:string, mediaType?:string)=>void;
    data?: DatshBoardStatTableType;
    value:string;
}

const MediaTypeButton:React.FC<MediaTypeButtonProps> = (props) => {
    const body = useRef<HTMLDivElement>(null);
    const [options, setOptions] = useState<MediaClientOptiontype[]>([]);
    const [select, setSelect] = useState<MediaClientOptiontype|undefined>();

    const onSelect = (item:MediaClientOptiontype)=>{
        setSelect(item);
        body?.current?.click();
        props.onChange && props.onChange(item.id, item.type, item.mediaType);
    }

    useEffect(()=>{
        const tmp:MediaClientType[] = AdminCode.getOptions(AdminCode.data.Assets.mediaType)
        .map((v):MediaClientType=>({
            id: v.value,
            label: v.label,
            code: v.code,
            type: SelectTypeKey.media,
            value: 0, 
            color: v.data?.color,
            clients: props.data?.clients.filter((v1)=>v1.mediaType.toLocaleLowerCase()===v.value || v1.mediaType ===v.code) || [],
        }))
        .sort((a,b)=>a.label < b.label ? -1 : 1)
        .filter((v)=>(v.clients?.length || 0)>0);
        tmp.unshift({id:"all", label:"모든 매체", type:SelectTypeKey.none, code:"ALL", value:0, color:"var(--bs-gray-700)"});
        const items = getOptions(tmp);
        setOptions( items);
        setSelect(items.find((v)=>v.id===props.value) || items?.[0]);
    },[props.data, props.value]);

    return <ButtonDropDown
        style={props.style}
        title={props.children || <OptionItem value={select || options?.[0]} />}
        button={{ className: props.className, }}
        menu={{ className:'pe-2', }}
        useCaret={true}
    >
        {options.map((v, index)=><div key={index} className='d-block pt-1 ps-3 pe-3'>
            <div className='bg-hover-light p-2 px-5' onClick={()=>onSelect(v)}>
                <OptionItem value={v} iconFixed={true} />
            </div>
        </div>)}
    </ButtonDropDown>
}

export default MediaTypeButton;

const OptionItem:React.FC<{value?:MediaClientOptiontype, iconFixed?:boolean}> = (props)=><div 
    className='text-nowrap'
    style={{color:props.value?.color, display:'inline-block'}}
>
    <div className={clsx(props.iconFixed ? 'w-20px' : '')} style={{display:'inline-block'}}>{props.value?.icon}</div>
    {props.value?.label}
</div>;


interface MediaClientOptiontype{
    id: string;
    type: string;
    color?: string;
    data?: MediaClientType|DashBoardStatTableClientResType;
    icon?: React.ReactNode;
    label: React.ReactNode;
    mediaType?: string;
}
/** 선택형 목록으로 표시하기 위해 Option형으로 변환합니다. */
const getOptions = (data:MediaClientType[]):MediaClientOptiontype[] => {
    const results:MediaClientOptiontype[]= [];
    data.forEach((v1, index1)=>{
        results.push({
            id: v1.id,
            type: v1.type,
            data: v1,
            color: v1.color,
            icon: <MediaTypeIcon code={v1.id} />,
            label: <div className='align-items-center fw-bolder text-nowrap' style={{color:v1.color, display:'inline-block'}}>{v1.label}</div>,
        });
        
        v1.clients?.sort((a,b)=>(a.clientName || "") < (b.clientName || "") ? -1 : 1).forEach((v2,index2)=>{
            results.push({
                id: v2.clientId.toString(),
                type: SelectTypeKey.client,
                mediaType: v2.mediaType,
                data: v2,
                label: v2.clientName,
            });
        });
    });
    return results;
}